import React, { useEffect, useState, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  TextField,
  Button,
  Typography,
  Grid,
  Chip,
  Alert,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Checkbox,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  TablePagination,
  Slider
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import axios from 'axios';
import { SeguimientoContext } from '../SeguimientoContext';

/**
 * Calcule la couleur de texte adaptée au fond `hexcolor`.
 */
const getContrastColor = (hexcolor) => {
  if (!hexcolor) return '#000000';
  const r = parseInt(hexcolor.slice(1, 3), 16);
  const g = parseInt(hexcolor.slice(3, 5), 16);
  const b = parseInt(hexcolor.slice(5, 7), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

// Mappage des positions
const positionMappings = {
  Base: ['Base', 'Point Guard'],
  Escolta: ['Escolta', 'Shooting Guard', 'Guard'],
  Alerio: ['Alerio', 'Small Forward', 'Swingman'],
  'Ala-Pivot': ['Ala-Pivot', 'Ala-Pívot', 'Power Forward', 'Power Forward / Center'],
  Pivot: ['Pivot', 'Center'],
};

/**
 * Ligne de joueur dans le tableau de gauche (disponibles).
 * On peut glisser-déposer (drag) et cocher/décocher pour ajouter/retirer du suivi.
 */
const DraggablePlayerRow = ({
  player,
  handlePlayerClick,
  seguimientoPlayers,
  setSeguimientoPlayers,
  availablePlayers,
  setAvailablePlayers,
  mediaUrl,
}) => {
  // Configuration pour le drag
  const [{ isDragging }, dragRef] = useDrag({
    type: 'player',
    item: player,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // Vérifie si le joueur est déjà dans le suivi
  const isInSeguimiento = seguimientoPlayers.some((p) => p.id === player.id);

  /**
   * Ajoute le joueur au suivi (à droite).
   */
  const movePlayerToSeguimiento = (player) => {
    if (!isInSeguimiento) {
      const updatedSeguimiento = [...seguimientoPlayers, player].sort((a, b) =>
        a.nom.localeCompare(b.nom)
      );
      setSeguimientoPlayers(updatedSeguimiento);
      setAvailablePlayers((prev) => prev.filter((p) => p.id !== player.id));
    }
  };

  /**
   * Retire le joueur du suivi et le remet dans la liste de gauche.
   */
  const removePlayerFromSeguimiento = (player) => {
    const updatedSeguimiento = seguimientoPlayers.filter((p) => p.id !== player.id);
    setSeguimientoPlayers(updatedSeguimiento);
    setAvailablePlayers((prev) => [...prev, player].sort((a, b) => a.nom.localeCompare(b.nom)));
  };

  /**
   * Gère le (dé)cochage de la case.
   */
  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      // Si on coche => on ajoute directement au suivi
      movePlayerToSeguimiento(player);
    } else {
      // Si on décoche => on le retire du suivi
      removePlayerFromSeguimiento(player);
    }
  };

  return (
    <TableRow
      ref={dragRef}
      key={player.id}
      hover
      sx={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}
    >
      <TableCell padding="checkbox">
        <Checkbox checked={isInSeguimiento} onChange={handleCheckboxChange} />
      </TableCell>
      <TableCell>
        <Avatar src={`${mediaUrl}/photos/${player.id}.png`} alt={player.nom} />
      </TableCell>
      <TableCell>
        <Box
          component="a"
          onClick={(e) => {
            e.preventDefault();
            handlePlayerClick(player);
          }}
          sx={{
            color: 'black',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
              cursor: 'pointer',
            },
          }}
        >
          {player.nom}
        </Box>
      </TableCell>
      <TableCell>{player.poste}</TableCell>
      <TableCell>{player.team || '-'}</TableCell>
      <TableCell>{player.league_1 || '-'}</TableCell>
    </TableRow>
  );
};

/**
 * Composant principal pour la modification d'un Seguimiento.
 * On filtre les joueurs par type (Pro/Cantera) en se basant sur
 * la propriété `type` renvoyée par l'API dans `/api/seguimiento/:id`.
 */
const SeguimientoModif = () => {
  // On suppose que le contexte peut stocker des tags (ou autre).
  const { tags = [] } = useContext(SeguimientoContext) || {};

  const { id } = useParams();
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const section = process.env.REACT_APP_SECTION;

  // États divers
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [seguimientoName, setSeguimientoName] = useState('');
  const [seguimientoType, setSeguimientoType] = useState(''); // "pro" ou "cantera"

  const [selectedTags, setSelectedTags] = useState([]);
  const [seguimientoPlayers, setSeguimientoPlayers] = useState([]); // Joueurs à droite
  const [availablePlayers, setAvailablePlayers] = useState([]);     // Joueurs à gauche
  const [allPlayers, setAllPlayers] = useState([]);

  // Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);

  // Filtres
  const [nameFilter, setNameFilter] = useState('');
  const [teamFilter, setTeamFilter] = useState('');
  const [positionFilter, setPositionFilter] = useState([]);
  const [heightFilter, setHeightFilter] = useState([130, 230]);
  const [ageFilter, setAgeFilter] = useState([12, 45]);
  const [leagueFilter, setLeagueFilter] = useState([]);
  const [uniqueLeagues, setUniqueLeagues] = useState([]);

  /**
   * Calcule l'âge à partir de l'année de naissance.
   */
  const calculateAge = (anneeNaissance) => {
    const currentYear = new Date().getFullYear();
    return currentYear - anneeNaissance;
  };

  /**
   * Charge le seguimiento depuis le backend, récupère son type,
   * charge les joueurs, puis filtre par type.
   */
  useEffect(() => {
    const fetchSeguimiento = async () => {
      try {
        setLoading(true);

        // 1) Récupération du seguimiento
        const response = await axios.get(`${backendUrl}/api/seguimiento/${id}`, {
          params: { section },
        });
        const seguimiento = response.data;

        setSeguimientoName(seguimiento.libelle || '');
        setSelectedTags(seguimiento.tags || []);

        // On récupère le type => "Pro" / "Cantera" ou minuscules
        const segType = seguimiento.type ? seguimiento.type.toLowerCase() : '';
        setSeguimientoType(segType);

        // Joueurs du seguimiento => triés par ordre alphabétique
        const sortedPlayers = seguimiento.players
          ? [...seguimiento.players].sort((a, b) => a.nom.localeCompare(b.nom))
          : [];
        setSeguimientoPlayers(sortedPlayers);

        // 2) Récupération de tous les joueurs
        const playersResponse = await axios.get(`${backendUrl}/api/players`, {
          params: { section },
        });
        const allPlayersFetched = playersResponse.data.players || [];

        // 3) Filtrer les joueurs par type (player.type == "pro" ou "cantera")
        const filteredByType = allPlayersFetched.filter((p) => {
          if (!p.type) return false;
          return p.type.toLowerCase() === segType;
        });

        setAllPlayers(filteredByType);

        setError(null);
      } catch (err) {
        console.error('Error al recuperar el Seguimiento:', err);
        setError('No se puede cargar el Seguimiento.');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchSeguimiento();
    }
  }, [id, backendUrl, section]);

  /**
   * Met à jour la liste des joueurs disponibles et la liste des ligues uniques
   * à chaque fois que `seguimientoPlayers` ou `allPlayers` change.
   */
  useEffect(() => {
    const seguimientoPlayerIds = seguimientoPlayers.map((p) => p.id);
    const available = allPlayers.filter((p) => !seguimientoPlayerIds.includes(p.id));

    const leagues = Array.from(
      new Set(
        available
          .map((player) => player.league_1)
          .filter((league) => league && league.trim() !== '' && league.trim() !== 'null')
      )
    ).sort();

    setUniqueLeagues(leagues);
    setAvailablePlayers(available);
  }, [seguimientoPlayers, allPlayers]);

  /**
   * Filtre les joueurs disponibles en fonction des filtres.
   */
  const filteredAvailablePlayers = availablePlayers.filter((player) => {
    const playerAge = calculateAge(player.annee_naissance);

    const matchesName = player.nom.toLowerCase().includes(nameFilter.toLowerCase());
    const matchesTeam =
      !teamFilter ||
      (player.team && player.team.toLowerCase().includes(teamFilter.toLowerCase()));

    // Gestion de la position
    const matchesPosition =
      positionFilter.length === 0 ||
      positionFilter.some((pos) =>
        positionMappings[pos].some(
          (mappedPos) => player.poste?.toLowerCase() === mappedPos.toLowerCase()
        )
      );

    // Gestion de la ligue
    const matchesLeague =
      leagueFilter.length === 0 || leagueFilter.includes(player.league_1);

    // Gestion de la taille
    const matchesHeight = player.taille >= heightFilter[0] && player.taille <= heightFilter[1];
    // Gestion de l'âge
    const matchesAge = playerAge >= ageFilter[0] && playerAge <= ageFilter[1];

    return (
      matchesName &&
      matchesTeam &&
      matchesPosition &&
      matchesLeague &&
      matchesHeight &&
      matchesAge
    );
  });

  /**
   * Sauvegarde le suivi (PUT).
   */
  const handleSave = async () => {
    if (!seguimientoName.trim()) {
      setError('El nombre del seguimiento no puede estar vacío');
      return;
    }

    try {
      // On envoie l'ID des joueurs et des tags
      await axios.put(`${backendUrl}/api/seguimiento/${id}`, {
        section,
        libelle: seguimientoName,
        players: seguimientoPlayers.map((p) => p.id),
        tags: selectedTags.map((tag) => tag.id),
        // Si nécessaire, on peut ré-enregistrer le type
        type: seguimientoType === 'pro' ? 'Pro' : 'Cantera',
      });

      // Retour à la page précédente
      navigate(-1);
    } catch (err) {
      console.error('Error al actualizar el Seguimiento:', err);
      setError(err.response?.data?.message || 'Error al actualizar el Seguimiento.');
    }
  };

  /**
   * Clique sur le nom d'un joueur => navigue vers sa fiche.
   */
  const handlePlayerClick = (player) => {
    navigate(`/players/${player.id}`);
  };

  /**
   * Retire un joueur du suivi, côté dropzone (colonne de droite).
   */
  const removePlayerFromSeguimiento = (player) => {
    const updated = seguimientoPlayers.filter((p) => p.id !== player.id);
    setSeguimientoPlayers(updated);
    setAvailablePlayers((prev) => [...prev, player].sort((a, b) => a.nom.localeCompare(b.nom)));
  };

  /**
   * Ajoute un joueur dans le suivi si pas déjà présent (lors d'un drop).
   */
  const movePlayerToSeguimiento = (player) => {
    if (!seguimientoPlayers.some((p) => p.id === player.id)) {
      setSeguimientoPlayers((prev) => [...prev, player].sort((a, b) => a.nom.localeCompare(b.nom)));
      setAvailablePlayers((prev) => prev.filter((p) => p.id !== player.id));
    }
  };

  /**
   * Composant pour afficher un joueur déjà dans le suivi (colonne de droite).
   */
  const SeguimientoPlayer = ({ player }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 0.5,
          mb: 0.5,
          backgroundColor: 'background.paper',
          borderRadius: 1,
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        }}
      >
        <Avatar
          src={`${mediaUrl}/photos/${player.id}.png`}
          alt={player.nom}
          sx={{ mr: 2 }}
        />
        <Typography sx={{ flexGrow: 1 }}>{player.nom}</Typography>
        <IconButton onClick={() => removePlayerFromSeguimiento(player)} color="error">
          <DeleteIcon />
        </IconButton>
      </Box>
    );
  };

  /**
   * Zone de dépôt (drag and drop) pour la liste de suivi (colonne de droite).
   */
  const SeguimientoDropZone = () => {
    const [{ isOver }, dropRef] = useDrop({
      accept: 'player',
      drop: (player) => movePlayerToSeguimiento(player),
      collect: (monitor) => ({ isOver: monitor.isOver() }),
    });

    return (
      <Paper
        ref={dropRef}
        sx={{
          p: 2,
          minHeight: '500px',
          backgroundColor: isOver ? 'rgba(255, 152, 0, 0.1)' : '#f5f5f5',
        }}
      >
        <Typography variant="h6" gutterBottom>
          Seguimiento
        </Typography>
        {seguimientoPlayers.map((player) => (
          <SeguimientoPlayer key={player.id} player={player} />
        ))}
        {seguimientoPlayers.length === 0 && (
          <Typography
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              mt: 2,
            }}
          >
            Arrastra jugador@s aquí
          </Typography>
        )}
      </Paper>
    );
  };

  // Loading
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  // Erreur
  if (error) {
    return <Alert severity="error" sx={{ m: 2 }}>{error}</Alert>;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Box sx={{ p: 3 }}>
        {/* Filtros */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Filtros
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Filtrar por nombre"
                variant="outlined"
                value={nameFilter}
                onChange={(e) => setNameFilter(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Filtrar por equipo"
                variant="outlined"
                value={teamFilter}
                onChange={(e) => setTeamFilter(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="position-filter-label">Filtrar por posición</InputLabel>
                <Select
                  labelId="position-filter-label"
                  multiple
                  value={positionFilter}
                  onChange={(e) => setPositionFilter(e.target.value)}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {Object.entries(positionMappings).map(([position, equivalents]) => (
                    <MenuItem key={position} value={position}>
                      <Checkbox checked={positionFilter.includes(position)} />
                      <ListItemText
                        primary={position}
                        secondary={equivalents.slice(1).join(', ')}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="league-filter-label">Filtrar por liga</InputLabel>
                <Select
                  labelId="league-filter-label"
                  multiple
                  value={leagueFilter}
                  onChange={(e) => setLeagueFilter(e.target.value)}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {uniqueLeagues.map((league) => (
                    <MenuItem key={league} value={league}>
                      <Checkbox checked={leagueFilter.includes(league)} />
                      <ListItemText primary={league} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={4} sx={{ mt: 3 }}>
            <Grid item xs={12} sm={6}>
              <Box sx={{ px: 3 }}>
                <Typography gutterBottom sx={{ textAlign: 'center', mb: 2 }}>
                  Filtrar por altura (130 a 230 cm)
                </Typography>
                <Slider
                  value={heightFilter}
                  onChange={(e, newValue) => setHeightFilter(newValue)}
                  valueLabelDisplay="auto"
                  min={130}
                  max={230}
                  sx={{ color: '#ff9800' }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ px: 3 }}>
                <Typography gutterBottom sx={{ textAlign: 'center', mb: 2 }}>
                  Filtrar por edad (12 a 45 años)
                </Typography>
                <Slider
                  value={ageFilter}
                  onChange={(e, newValue) => setAgeFilter(newValue)}
                  valueLabelDisplay="auto"
                  min={12}
                  max={45}
                  sx={{ color: '#ff9800' }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Configuración del Seguimiento */}
        <Box sx={{ mb: 6, mt: 6 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                label="Nombre del Seguimiento"
                value={seguimientoName}
                onChange={(e) => setSeguimientoName(e.target.value)}
                error={!seguimientoName.trim()}
                helperText={!seguimientoName.trim() ? 'El nombre es obligatorio' : ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Tags</InputLabel>
                <Select
                  multiple
                  value={selectedTags}
                  onChange={(e) => setSelectedTags(e.target.value)}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((tag) => (
                        <Chip
                          key={tag.id}
                          label={tag.libelle}
                          style={{
                            backgroundColor: tag.code_couleur,
                            color: getContrastColor(tag.code_couleur),
                          }}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {tags.map((tag) => (
                    <MenuItem key={tag.id} value={tag}>
                      <Checkbox checked={selectedTags.some((t) => t.id === tag.id)} />
                      <ListItemText primary={tag.libelle} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
            <Button variant="outlined" onClick={() => navigate(-1)}>
              CANCELAR
            </Button>
            <Button
              variant="contained"
              onClick={handleSave}
              sx={{
                backgroundColor: '#1976d2',
                '&:hover': {
                  backgroundColor: '#1565c0',
                },
              }}
            >
              GUARDAR CAMBIOS
            </Button>
          </Box>
        </Box>

        {/* Tableau de gauche (joueurs disponibles) + Dropzone (droite) */}
        <Grid container spacing={3}>
          {/* Tableau des joueurs disponibles */}
          <Grid item xs={12} md={8}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* Case Select All désactivée */}
                    <TableCell padding="checkbox">
                      <Checkbox disabled />
                    </TableCell>
                    <TableCell>Avatar</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Posición</TableCell>
                    <TableCell>Equipo</TableCell>
                    <TableCell>Liga</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredAvailablePlayers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((player) => (
                      <DraggablePlayerRow
                        key={player.id}
                        player={player}
                        handlePlayerClick={handlePlayerClick}
                        seguimientoPlayers={seguimientoPlayers}
                        setSeguimientoPlayers={setSeguimientoPlayers}
                        availablePlayers={availablePlayers}
                        setAvailablePlayers={setAvailablePlayers}
                        mediaUrl={mediaUrl}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={filteredAvailablePlayers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
              rowsPerPageOptions={[10]}
            />
          </Grid>

          {/* Dropzone (joueurs déjà en suivi) */}
          <Grid item xs={12} md={4}>
            <SeguimientoDropZone />
          </Grid>
        </Grid>
      </Box>
    </DndProvider>
  );
};

export default SeguimientoModif;
