import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Paper,
  Typography,
  Alert,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import axios from 'axios';

const Importar = () => {
  const [formData, setFormData] = useState({
    login: 'girarderic62@gmail.com',
    password: '51Basket',
    details: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [playersData, setPlayersData] = useState([]);

  // Pour la fenêtre de dialogue (quand résultats multiples)
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedPlayerOptions, setSelectedPlayerOptions] = useState([]);
  const [currentPlayerName, setCurrentPlayerName] = useState('');

  // Logs SSE
  const [logs, setLogs] = useState([]);

  // Variables d'env
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // ==============================
  // Import Eurobasket (REST)
  // ==============================
  const handleEurobasketImport = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);
    setPlayersData([]);

    try {
      const response = await axios.post(`${backendUrl}/api/importar`, {
        ...formData,
        section,
        source: 'eurobasket'
      });

      setSuccess(true);
      setPlayersData(response.data.players);
    } catch (err) {
      setError(err.response?.data?.message || 'Erreur lors de l\'importation Eurobasket');
    } finally {
      setLoading(false);
    }
  };

  // ==============================
  // Import Proballers (SSE)
  // ==============================
  const handleProballersImport = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);
    setPlayersData([]);
    setLogs([]);

    // On construit l'URL SSE (GET)
    const url = `${backendUrl}/api/importar/proballers?details=${encodeURIComponent(formData.details)}&section=${encodeURIComponent(section)}`;

    // Création de l'EventSource
    const eventSource = new EventSource(url);

    eventSource.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);

        if (data.type === 'log') {
          // Un log SSE
          setLogs(prevLogs => [...prevLogs, data.message]);
        } else if (data.type === 'done') {
          // Fin du process
          setPlayersData(data.players);
          setLoading(false);
          setSuccess(true);
          eventSource.close();
        } else if (data.type === 'error') {
          // Erreur
          setError(data.message);
          setLoading(false);
          eventSource.close();
        }
      } catch (parseError) {
        console.error('Erreur de parsing SSE:', parseError);
      }
    };

    eventSource.onerror = (err) => {
      console.error('EventSource erreur:', err);
      setError('Erreur de connexion SSE');
      setLoading(false);
      eventSource.close();
    };
  };

  // ==============================
  // Gestion des "résultats multiples"
  // ==============================
  const handleViewOptions = (playerNom, options) => {
    setCurrentPlayerName(playerNom);
    setSelectedPlayerOptions(options);
    setOpenDialog(true);
  };

  const handleOptionClick = async (option) => {
    // Cas Eurobasket: on a un champ "link", "pos", etc.
    // Cas Proballers: on a un champ "href", "text", etc.
    // Selon votre logique, il faut appeler la route d'import unique avec l'URL.
    // Par exemple, si c'est Eurobasket:
    // => POST sur /api/importar/player { url: option.link, login, password, section }
    // Si c'est Proballers:
    // => POST sur /api/importar/proballers/player { url: "https://www.proballers.com" + option.href, section }

    try {
      setLoading(true);
      setError(null);
      setSuccess(false);

      let finalUrl;
      let isEurobasket = false;

      if (option.link) {
        // Suppose qu'on est sur Eurobasket
        finalUrl = option.link;
        isEurobasket = true;
      } else if (option.href) {
        // Suppose Proballers
        finalUrl = 'https://www.proballers.com' + option.href;
      } else {
        throw new Error('Option invalide: aucun lien ni href.');
      }

      if (isEurobasket) {
        // Eurobasket: route /player
        const resp = await axios.post(`${backendUrl}/api/importar/player`, {
          url: finalUrl,
          login: formData.login,
          password: formData.password,
          section
        });
        if (resp.data.success) {
          setSuccess(true);
        }
      } else {
        // Proballers: route /proballers/player
        const resp = await axios.post(`${backendUrl}/api/importar/proballers/player`, {
          url: finalUrl,
          section
        });
        if (resp.data.success) {
          setSuccess(true);
        }
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Erreur lors de l\'importation du joueur sélectionné');
    } finally {
      setLoading(false);
      setOpenDialog(false);
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  // ==============================
  // Rendu du composant
  // ==============================
  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', mt: 4 }}>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h5" component="h1" gutterBottom>
          Importación de Datos
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ mb: 2 }}>
            Importación realizada con éxito
          </Alert>
        )}

        {/* Affichage des logs SSE en direct (Proballers) */}
        {logs.length > 0 && (
          <Paper sx={{ p: 2, mb: 2, maxHeight: 200, overflowY: 'auto' }}>
            {logs.map((line, i) => (
              <Typography variant="body2" key={i}>
                {line}
              </Typography>
            ))}
          </Paper>
        )}

        <form>
          <TextField
            fullWidth
            label="Login"
            name="login"
            value={formData.login}
            onChange={handleChange}
            margin="normal"
            required
            variant="outlined"
          />

          <TextField
            fullWidth
            label="Contraseña"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            margin="normal"
            required
            variant="outlined"
          />

          <TextField
            fullWidth
            label="Detalles"
            name="details"
            value={formData.details}
            onChange={handleChange}
            margin="normal"
            required
            multiline
            rows={6}
            variant="outlined"
          />

          <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
            <Button
              onClick={handleEurobasketImport}
              variant="contained"
              sx={{
                flex: 1,
                backgroundColor: '#F25F29',
                '&:hover': {
                  backgroundColor: '#d44d1a',
                }
              }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Importar Eurobasket'
              )}
            </Button>

            <Button
              onClick={handleProballersImport}
              variant="contained"
              sx={{
                flex: 1,
                backgroundColor: '#4CAF50',
                '&:hover': {
                  backgroundColor: '#388E3C',
                }
              }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Importar Proballers'
              )}
            </Button>
          </Box>
        </form>

        {/* Affichage des résultats après l'import */}
        {playersData.length > 0 && (
          <Box mt={4}>
            <Typography variant="h6">Resultados:</Typography>
            {playersData.map((player, index) => (
              <Box key={index} mt={2}>
                {player.multiple ? (
                  <>
                    <Typography variant="subtitle1">
                      {`Múltiples jugador@s encontrados para ${player.nom}:`}
                    </Typography>
                    <Button
                      variant="outlined"
                      onClick={() => handleViewOptions(player.nom, player.options)}
                    >
                      Ver opciones
                    </Button>
                  </>
                ) : player.found === false ? (
                  <Typography variant="subtitle1">
                    {`No se encontró jugador para ${player.nom}`}
                  </Typography>
                ) : player.alreadyInDB ? (
                  <Typography variant="subtitle1" color="warning.main">
                    {`Jugador ${player.nom} ya existe en la base de datos`}
                  </Typography>
                ) : (
                  <Typography variant="subtitle1" color="success.main">
                    {`Jugador ${player.nom} importado con éxito`}
                  </Typography>
                )}
              </Box>
            ))}
          </Box>
        )}

        {/* Dialog pour afficher les options (plusieurs joueurs) */}
        <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="md" fullWidth>
          <DialogTitle>{`Opciones para ${currentPlayerName}`}</DialogTitle>
          <DialogContent>
            <List>
              {selectedPlayerOptions.map((option, idx) => (
                <ListItem key={idx} disablePadding>
                  <ListItemButton onClick={() => handleOptionClick(option)}>
                    {/* Exemple d'affichage: si c'est Eurobasket on a option.name, si c'est Proballers on a option.text */}
                    {/* Pour Eurobasket : name / pos / team / born / nat */}
                    {/* Pour Proballers : text / href */}
                    <ListItemText
                      primary={option.name || option.text}
                      secondary={option.link || option.href}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cerrar</Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Box>
  );
};

export default Importar;
