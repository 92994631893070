import React, { useState, useEffect } from 'react';
import { 
  Typography, Box, Button, Avatar, Grid,
  Paper, Dialog, DialogActions, 
  DialogContent, DialogContentText, DialogTitle,
  IconButton
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

const EditableFichaTecnification = ({ playerId, refresh }) => {
  const [fichas, setFichas] = useState([]);
  const [editingField, setEditingField] = useState(null);
  const [editingFichaId, setEditingFichaId] = useState(null);
  const [editValue, setEditValue] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [fichaToDelete, setFichaToDelete] = useState(null);
  const [showOldFichas, setShowOldFichas] = useState(false);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const section = process.env.REACT_APP_SECTION;

  const editorModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['link'], 
      ['clean']
    ],
  };

  const editorFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'link'
  ];

  // Exemple de logique pour séparer la saison en cours et la précédente.
  // Ajustez selon vos dates de saison.
  const isCurrentSeason = (date) => {
    const today = new Date();
    const fichaDate = new Date(date);
    
    // Hypothèse : la saison va du 1er août (month = 7) au 31 juillet (month = 6)
    const currentYear = today.getMonth() >= 7 ? today.getFullYear() : today.getFullYear() - 1;
    const seasonStart = new Date(currentYear, 7, 1);   // 1er août
    const seasonEnd = new Date(currentYear + 1, 6, 31); // 31 juillet (année suivante)
    
    return (fichaDate >= seasonStart && fichaDate <= seasonEnd);
  };

  const fetchFichas = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/tecnification/fiches/${playerId}`, {
        params: { section }
      });
      setFichas(response.data);
    } catch (error) {
      console.error("Error al cargar las fichas:", error);
    }
  };

  useEffect(() => {
    if (playerId) {
      fetchFichas();
    }
  }, [playerId, refresh]);

  const handleDoubleClick = (fichaId, field, value) => {
    setEditingFichaId(fichaId);
    setEditingField(field);
    setEditValue(value || '');
  };

  const handleSave = async () => {
    if (!editingFichaId || !editingField) return;
    try {
      await axios.put(`${backendUrl}/api/tecnification/fiches/${editingFichaId}`, {
        [editingField]: editValue,
        section
      });
      fetchFichas();
      setEditingField(null);
      setEditingFichaId(null);
      setEditValue('');
    } catch (error) {
      console.error("Error al actualizar la ficha:", error);
    }
  };

  const handleCancel = () => {
    setEditingField(null);
    setEditingFichaId(null);
    setEditValue('');
  };

  const handleDeleteClick = (ficha) => {
    setFichaToDelete(ficha);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    if (!fichaToDelete) return;
    try {
      await axios.delete(`${backendUrl}/api/tecnification/fiches/${fichaToDelete.id}`, {
        params: { section }
      });
      fetchFichas();
      setOpenDeleteDialog(false);
      setFichaToDelete(null);
    } catch (error) {
      console.error("Error al eliminar la ficha:", error);
    }
  };

  // Champs EXACTEMENT tels qu'ils apparaissent dans la réponse JSON
  // (objectifs, implementar, observations, routinas, numero_sesiones)
  const fields = [
    'objectifs',
    'implementar',
    'observations',
    'routinas',
    'numero_sesiones'
  ];

  // Labels à afficher (en espagnol, par exemple)
  const fieldNames = {
    objectifs: 'Objetivos',
    implementar: 'Implementar',
    observations: 'Observaciones',
    routinas: 'Rutinas',
    numero_sesiones: 'Número de Sesiones'
  };

  // Séparer les fiches en deux catégories : saison en cours et anciennes
  const currentSeasonFichas = fichas.filter(ficha => isCurrentSeason(ficha.date_fiche));
  const oldFichas = fichas.filter(ficha => !isCurrentSeason(ficha.date_fiche));

  if (fichas.length === 0) {
    return (
      <Typography color="textSecondary">
        No hay fichas registradas
      </Typography>
    );
  }
  
  return (
    <Box sx={{ mt: 2 }}>
      {/* FICHAS DE LA TEMPORADA ACTUAL */}
      {currentSeasonFichas.map((ficha) => (
        <Paper 
          key={ficha.id} 
          elevation={2} 
          sx={{ 
            p: 3, 
            mb: 4,
            backgroundColor: '#f5f5f5'
          }}
        >
          {/* En-tête de la fiche (nom, date, bouton supprimer) */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 3
          }}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 2
            }}>
              <Avatar 
                src={`${mediaUrl}/users/${ficha.user_id}.png`}
                alt={ficha.user_name}
              />
              <Box>
                <Typography>
                  {ficha.user_name}
                </Typography>
                <Typography color="text.secondary">
                  {new Date(ficha.date_fiche).toLocaleDateString()}
                </Typography>
              </Box>
            </Box>
            <IconButton
              color="error"
              onClick={() => handleDeleteClick(ficha)}
              size="small"
              sx={{
                '&:hover': {
                  bgcolor: 'error.lighter'
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>

          <Grid container spacing={3}>
            {fields.map((field) => (
              <Grid item xs={12} md={4} key={`${ficha.id}-${field}`}>
                <Box sx={{ height: '100%' }}>
                  <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                    {fieldNames[field]}
                  </Typography>

                  {editingField === field && editingFichaId === ficha.id ? (
                    /* Mode édition */
                    <Box>
                      <Box sx={{ 
                        border: '1px solid',
                        borderColor: 'divider',
                        borderRadius: 1,
                        overflow: 'hidden',
                        '& .ql-toolbar': {
                          borderTop: 'none',
                          borderLeft: 'none',
                          borderRight: 'none',
                          borderBottom: '1px solid',
                          borderColor: 'divider'
                        },
                        '& .ql-container': {
                          border: 'none',
                          minHeight: '200px'
                        },
                        '& .ql-editor': {
                          minHeight: '200px'
                        }
                      }}>
                        {/* Si on édite le champ 'numero_sesiones', on utilise un input simple */}
                        {field === 'numero_sesiones' ? (
                          <input
                            type="text"
                            value={editValue}
                            onChange={(e) => setEditValue(e.target.value)}
                            style={{
                              width: '100%',
                              border: 'none',
                              outline: 'none',
                              padding: '8px'
                            }}
                          />
                        ) : (
                          <ReactQuill
                            value={editValue}
                            onChange={setEditValue}
                            modules={editorModules}
                            formats={editorFormats}
                          />
                        )}
                      </Box>

                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'flex-end', 
                        gap: 1,
                        mt: 2 
                      }}>
                        <Button
                          variant="outlined"
                          startIcon={<CancelIcon />}
                          onClick={handleCancel}
                          size="small"
                        >
                          Cancelar
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<SaveIcon />}
                          onClick={handleSave}
                          size="small"
                        >
                          Guardar
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    /* Mode affichage */
                    <Box 
                      onDoubleClick={() => handleDoubleClick(ficha.id, field, ficha[field])}
                      sx={{ 
                        minHeight: '150px',
                        p: 2,
                        backgroundColor: 'white',
                        borderRadius: 1,
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: '#fafafa',
                          boxShadow: 1
                        }
                      }}
                    >
                      {/* Affichage en mode HTML pour champs longs, 
                          ou simple texte pour numero_sesiones */}
                      {field === 'numero_sesiones' ? (
                        <Typography>
                          {ficha[field]}
                        </Typography>
                      ) : (
                        <div dangerouslySetInnerHTML={{ __html: ficha[field] || '' }} />
                      )}
                    </Box>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Paper>
      ))}

      {/* AFFICHER / MASQUER LES FICHAS ANCIENNES */}
      {oldFichas.length > 0 && (
        <Button
          variant="outlined"
          onClick={() => setShowOldFichas(!showOldFichas)}
        >
          {showOldFichas ? "Ocultar fichas antiguas" : "Ver fichas antiguas"}
        </Button>
      )}

      {/* FICHAS ANTERIORES */}
      {showOldFichas && oldFichas.length > 0 && (
        <>
          <Box sx={{ 
            mt: 4, 
            mb: 2,
            p: 2,
            backgroundColor: 'grey.100',
            borderRadius: 1
          }}>
            <Typography variant="h6" color="text.secondary">
              Fichas de temporadas anteriores
            </Typography>
          </Box>
          
          {oldFichas.map((ficha) => (
            <Paper 
              key={ficha.id} 
              elevation={2} 
              sx={{ 
                p: 3, 
                mb: 4,
                backgroundColor: '#f5f5f5'
              }}
            >
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 3
              }}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  gap: 2
                }}>
                  <Avatar 
                    src={`${mediaUrl}/users/${ficha.user_id}.png`}
                    alt={ficha.user_name}
                  />
                  <Box>
                    <Typography>
                      {ficha.user_name}
                    </Typography>
                    <Typography color="text.secondary">
                      {new Date(ficha.date_fiche).toLocaleDateString()}
                    </Typography>
                  </Box>
                </Box>
                <IconButton
                  color="error"
                  onClick={() => handleDeleteClick(ficha)}
                  size="small"
                  sx={{
                    '&:hover': {
                      bgcolor: 'error.lighter'
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>

              <Grid container spacing={3}>
                {fields.map((field) => (
                  <Grid item xs={12} md={4} key={`${ficha.id}-${field}`}>
                    <Box sx={{ height: '100%' }}>
                      <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                        {fieldNames[field]}
                      </Typography>

                      {editingField === field && editingFichaId === ficha.id ? (
                        /* Mode édition */
                        <Box>
                          <Box sx={{ 
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: 1,
                            overflow: 'hidden',
                            '& .ql-toolbar': {
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderRight: 'none',
                              borderBottom: '1px solid',
                              borderColor: 'divider'
                            },
                            '& .ql-container': {
                              border: 'none',
                              minHeight: '200px'
                            },
                            '& .ql-editor': {
                              minHeight: '200px'
                            }
                          }}>
                            {field === 'numero_sesiones' ? (
                              <input
                                type="text"
                                value={editValue}
                                onChange={(e) => setEditValue(e.target.value)}
                                style={{
                                  width: '100%',
                                  border: 'none',
                                  outline: 'none',
                                  padding: '8px'
                                }}
                              />
                            ) : (
                              <ReactQuill
                                value={editValue}
                                onChange={setEditValue}
                                modules={editorModules}
                                formats={editorFormats}
                              />
                            )}
                          </Box>

                          <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'flex-end', 
                            gap: 1,
                            mt: 2 
                          }}>
                            <Button
                              variant="outlined"
                              startIcon={<CancelIcon />}
                              onClick={handleCancel}
                              size="small"
                            >
                              Cancelar
                            </Button>
                            <Button
                              variant="contained"
                              startIcon={<SaveIcon />}
                              onClick={handleSave}
                              size="small"
                            >
                              Guardar
                            </Button>
                          </Box>
                        </Box>
                      ) : (
                        /* Mode affichage */
                        <Box 
                          onDoubleClick={() => handleDoubleClick(ficha.id, field, ficha[field])}
                          sx={{ 
                            minHeight: '150px',
                            p: 2,
                            backgroundColor: 'white',
                            borderRadius: 1,
                            cursor: 'pointer',
                            '&:hover': {
                              backgroundColor: '#fafafa',
                              boxShadow: 1
                            }
                          }}
                        >
                          {field === 'numero_sesiones' ? (
                            <Typography>
                              {ficha[field]}
                            </Typography>
                          ) : (
                            <div dangerouslySetInnerHTML={{ __html: ficha[field] || '' }} />
                          )}
                        </Box>
                      )}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          ))}
        </>
      )}

      {/* Dialog de confirmation de suppression */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
          setFichaToDelete(null);
        }}
      >
        <DialogTitle>Confirmar eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Está seguro que desea eliminar esta ficha de tecnificación?
            Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              setOpenDeleteDialog(false);
              setFichaToDelete(null);
            }}
          >
            Cancelar
          </Button>
          <Button 
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
            autoFocus
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EditableFichaTecnification;
