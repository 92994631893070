import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Avatar,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Autocomplete,
  TextField,
  CircularProgress,
  Alert,
  SwipeableDrawer,
  Divider,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Fab,
  Tooltip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  MenuItem,
  FormControl,
  Select,
  InputLabel
} from '@mui/material';
import {
  ExpandMore,
  FilterList,
  Edit,
  Delete as DeleteIcon,
  Launch,
  Close
} from '@mui/icons-material';
import axios from 'axios';
import { useBigBoardTable } from '../BigBoardTableContext';
import ListboxComponent from '../components/ListboxComponent ';

// ====================================
// =========== UTILS ==================
// ====================================

/** Retourne les initiales d'un nom + prénom */
const getInitials = (nom, prenom) => {
  const firstInitial = nom ? nom[0] : '';
  const secondInitial = prenom ? prenom[0] : '';
  return (firstInitial + secondInitial).toUpperCase();
};

/** Génère une couleur de fond pour un avatar en fonction d'une chaîne de caractères */
const getAvatarColor = (name) => {
  if (!name) return '#757575';
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = Math.abs(hash % 360);
  return `hsl(${hue}, 70%, 50%)`;
};

/** Retourne la couleur de texte (noir ou blanc) la plus contrastée selon la couleur de fond */
const getContrastColor = (hexcolor) => {
  if (!hexcolor) return '#000000';
  const r = parseInt(hexcolor.slice(1, 3), 16);
  const g = parseInt(hexcolor.slice(3, 5), 16);
  const b = parseInt(hexcolor.slice(5, 7), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

/** Joint les chaînes non vides par un tiret */
const joinWithDash = (...items) => items.filter(Boolean).join(' - ');

// ====================================
// =========== AVATAR COMPONENT =======
// ====================================
const UserAvatar = ({ user, mediaUrl, size = 24 }) => (
  <Tooltip
    arrow
    componentsProps={{
      tooltip: {
        sx: {
          bgcolor: 'white',
          '& .MuiTooltip-arrow': { color: 'white' },
          boxShadow: '0px 2px 8px rgba(0,0,0,0.15)'
        }
      }
    }}
    title={
      <Box sx={{ p: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
        <Avatar
          src={`${mediaUrl}/users/${user.id}.png`}
          sx={{ width: 40, height: 40 }}
        />
        <Box>
          <Typography sx={{ fontWeight: 'bold', color: 'black' }}>
            {user.prenom} {user.nom}
          </Typography>
        </Box>
      </Box>
    }
  >
    <Avatar
      sx={{
        width: size,
        height: size,
        fontSize: `${size * 0.5}px`,
        bgcolor: getAvatarColor(user.nom + user.prenom)
      }}
    >
      {getInitials(user.prenom, user.nom)}
    </Avatar>
  </Tooltip>
);

// ====================================
// =========== PLAYER CARD ============
// ====================================
const PlayerCard = ({
  player,
  onPlayerClick,
  onPlayerUrlClick,
  mediaUrl,
  disablePlayerLink // si true, on ne met pas de lien
}) => {
  // Ligne "Poste - année_naissance - taille"
  const line2 = joinWithDash(
    player.poste,
    player.annee_naissance,
    player.taille ? `${player.taille}cm` : null
  );

  // Ligne "Nationalité - passport - équipe - ligues"
  const line3 = joinWithDash(
    player.nationalite,
    player.passport,
    player.team,
    [player.league_1, player.league_2, player.competicion_europa].filter(Boolean).join(' - ')
  );

  return (
    <ListItem
      button
      // si disablePlayerLink, on n'utilise pas onClick
      onClick={!disablePlayerLink ? () => onPlayerClick(player) : undefined}
      sx={{
        mb: 1,
        borderRadius: 1,
        border: '1px solid',
        borderColor: 'divider',
        bgcolor: 'background.paper',
        '&:hover': {
          bgcolor: disablePlayerLink ? 'background.paper' : 'action.hover'
        },
        py: 2,
        cursor: disablePlayerLink ? 'default' : 'pointer'
      }}
    >
      <ListItemAvatar>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {/* Classement */}
          <Typography color="textSecondary" sx={{ minWidth: '25px' }}>
            {player.classement}.
          </Typography>
          {/* Avatar du joueur */}
          <Avatar
            src={`${mediaUrl}/photos/${player.id}.png`}
            alt={player.nom}
            sx={{ width: 48, height: 48 }}
          />
        </Box>
      </ListItemAvatar>

      <Grid container spacing={2} sx={{ ml: 1 }}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            {/* Nom du joueur */}
            <Typography
              variant="subtitle1"
              color="text.primary"
              component="span"
              sx={{
                fontSize: '1.1rem',
                ...(disablePlayerLink
                  ? {}
                  : {
                      cursor: 'pointer',
                      '&:hover': {
                        color: 'primary.main',
                        textDecoration: 'underline'
                      }
                    })
              }}
              // si disable, on ne fait rien au click
              onClick={(e) => {
                e.stopPropagation();
                if (!disablePlayerLink) onPlayerClick(player);
              }}
            >
              {player.nom}
            </Typography>

            {/* Ligne 2 : "Poste - Année - Taille" */}
            {line2 && (
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ fontSize: '1rem' }}
              >
                {line2}
              </Typography>
            )}

            {/* Ligne 3 : "Nationalité - Passport - Équipe - Ligas" */}
            {line3 && (
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ fontSize: '1rem' }}
              >
                {line3}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>

      {/* Lien externe si player.url existe et si non disable */}
      {player.url && !disablePlayerLink && (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onPlayerUrlClick(player.url, e);
            }}
          >
            <Launch fontSize="small" />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

// ====================================
// =========== FILTER DRAWER ==========
// ====================================
const FilterDrawer = ({
  open,
  onClose,
  players,
  users,
  selectedPlayer,
  selectedUser,
  onPlayerChange,
  onUserChange,
  mediaUrl,
  tags,
  selectedTags,
  onTagsChange,
  userRole,
  bigBoardTypeFilter,
  setBigBoardTypeFilter
}) => {
  const isSuperAdmin = userRole === 'super admin';

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      sx={{
        '& .MuiDrawer-paper': {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          maxHeight: '80vh',
          pb: 2
        }
      }}
    >
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">Filtros</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>

        <Divider sx={{ mb: 2 }} />

        <Grid container spacing={2}>
          {/* Filtre Joueur */}
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              options={players.players || []}
              getOptionLabel={(player) => player?.nom || ''}
              value={selectedPlayer}
              onChange={onPlayerChange}
              renderInput={(params) => (
                <TextField {...params} label="Filtrar por Jugador" />
              )}
              renderOption={(props, player) => (
                <Box
                  component="li"
                  {...props}
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  <Avatar
                    src={`${mediaUrl}/photos/${player.id}.png`}
                    alt={player.nom}
                    sx={{ width: 32, height: 32 }}
                  />
                  <Typography noWrap>{player.nom}</Typography>
                </Box>
              )}
              ListboxComponent={ListboxComponent}
              clearOnEscape
            />
          </Grid>

          {/* Filtre Utilisateur */}
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              options={users}
              getOptionLabel={(user) =>
                user ? `${user.prenom} ${user.nom}` : ''
              }
              value={selectedUser}
              onChange={onUserChange}
              renderInput={(params) => (
                <TextField {...params} label="Filtrar por Usuario" />
              )}
              renderOption={(props, user) => (
                <Box
                  component="li"
                  {...props}
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  <UserAvatar user={user} mediaUrl={mediaUrl} size={32} />
                  <Typography noWrap>{`${user.prenom} ${user.nom}`}</Typography>
                </Box>
              )}
              clearOnEscape
            />
          </Grid>

          {/* Filtre Tags */}
          <Grid item xs={12}>
            <Autocomplete
              multiple
              fullWidth
              options={tags}
              getOptionLabel={(tag) => tag.libelle}
              value={selectedTags}
              onChange={onTagsChange}
              renderInput={(params) => (
                <TextField {...params} label="Filtrar por Tags" />
              )}
              renderOption={(props, tag) => (
                <Box
                  component="li"
                  {...props}
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  <Chip
                    size="small"
                    label={tag.libelle}
                    style={{
                      backgroundColor: tag.code_couleur,
                      color: getContrastColor(tag.code_couleur)
                    }}
                  />
                </Box>
              )}
              renderTags={(value, getTagProps) =>
                value.map((tag, index) => (
                  <Chip
                    key={tag.id}
                    size="small"
                    label={tag.libelle}
                    {...getTagProps({ index })}
                    style={{
                      backgroundColor: tag.code_couleur,
                      color: getContrastColor(tag.code_couleur)
                    }}
                  />
                ))
              }
              clearOnEscape
            />
          </Grid>

          {/* Si superadmin => filtre sur type (Pro / Cantera / Tous) */}
          {isSuperAdmin && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="type-filter-label">Filtrar por tipo</InputLabel>
                <Select
                  labelId="type-filter-label"
                  value={bigBoardTypeFilter}
                  label="Filtrar por tipo"
                  onChange={(e) => setBigBoardTypeFilter(e.target.value)}
                >
                  <MenuItem value="all">Todos</MenuItem>
                  <MenuItem value="pro">Pro</MenuItem>
                  <MenuItem value="cantera">Cantera</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Box>
    </SwipeableDrawer>
  );
};

// ====================================
// =========== BOARD CONTENT ==========
// ====================================
const BoardContent = ({
  board,
  onEdit,
  onDelete,
  onPlayerClick,
  onPlayerUrlClick,
  mediaUrl,
  disablePlayerLink
}) => (
  <Paper sx={{ p: 2, mb: 2 }}>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: 2
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <UserAvatar user={board.user} mediaUrl={mediaUrl} />
        <Typography variant="h5" sx={{ fontWeight: 500 }}>
          {board.libelle} 
        </Typography>
      </Box>

      <Box>
        <IconButton
          size="small"
          onClick={() => onEdit(board.id)}
          color="primary"
          sx={{ mr: 1 }}
        >
          <Edit />
        </IconButton>
        <IconButton size="small" onClick={() => onDelete(board)} color="error">
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>

    <List disablePadding>
      {board.players
        .sort((a, b) => a.classement - b.classement)
        .map((player) => (
          <PlayerCard
            key={player.id}
            player={player}
            onPlayerClick={onPlayerClick}
            onPlayerUrlClick={onPlayerUrlClick}
            mediaUrl={mediaUrl}
            disablePlayerLink={disablePlayerLink}
          />
        ))}
    </List>
  </Paper>
);

// ====================================
// =========== TAG ACCORDION ==========
// ====================================
const TagAccordion = ({
  tag,
  boards,
  onEdit,
  onDelete,
  onPlayerClick,
  onPlayerUrlClick,
  mediaUrl,
  expandedTags,
  setExpandedTags,
  disablePlayerLink
}) => {
  // Vérifie si l'accordéon de ce tag est ouvert
  const isExpanded = expandedTags.has(tag.id);

  const handleChange = () => {
    const newSet = new Set(expandedTags);
    if (newSet.has(tag.id)) {
      newSet.delete(tag.id);
    } else {
      newSet.add(tag.id);
    }
    setExpandedTags(newSet);
  };

  // Regrouper les boards par libelle (pour les afficher côte à côte sur desktop)
  const boardsByLibelle = useMemo(() => {
    const map = {};
    boards.forEach((board) => {
      if (!map[board.libelle]) {
        map[board.libelle] = [];
      }
      map[board.libelle].push(board);
    });
    return map;
  }, [boards]);

  return (
    <Accordion expanded={isExpanded} onChange={handleChange} sx={{ mb: 1 }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          bgcolor: 'background.default',
          borderBottom: '1px solid',
          borderColor: 'divider'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Chip
            label={tag.libelle}
            style={{
              backgroundColor: tag.code_couleur,
              color: getContrastColor(tag.code_couleur),
              fontSize: '1.25rem',
              height: '48px',
              borderRadius: '8px'
            }}
          />
        </Box>
      </AccordionSummary>

      <AccordionDetails sx={{ p: 2 }}>
        {Object.entries(boardsByLibelle).map(([libelle, boardsGroup]) => (
          <Box key={libelle} sx={{ mb: 4 }}>
            {/* Si on veut afficher le libellé du groupe au-dessus (en plus) */}
            <Typography variant="h6" sx={{ mb: 2 }}>
              {libelle}
            </Typography>
            <Grid container spacing={2}>
              {boardsGroup.map((board) => {
                // Ajuster la largeur en fonction du nombre de boards
                const nbBoards = boardsGroup.length;
                let mdSize;
                if (nbBoards === 1) mdSize = 12;
                else if (nbBoards === 2) mdSize = 6;
                else mdSize = 4; // 3 ou plus

                return (
                  <Grid item xs={12} md={mdSize} key={board.id}>
                    <BoardContent
                      board={board}
                      onEdit={onEdit}
                      onDelete={onDelete}
                      onPlayerClick={onPlayerClick}
                      onPlayerUrlClick={onPlayerUrlClick}
                      mediaUrl={mediaUrl}
                      disablePlayerLink={disablePlayerLink}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

// ====================================
// =========== BIG BOARD TABLE ========
// ====================================
const BigBoardTable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const section = process.env.REACT_APP_SECTION;

  // On récupère les infos d'utilisateur dans le localStorage
  const userData = JSON.parse(localStorage.getItem('user'));
  const currentUserId = userData?.id || null;
  const userRole = (userData?.role || '').toLowerCase();

  // On définit un filtre de type pour superadmin
  const [bigBoardTypeFilter, setBigBoardTypeFilter] = useState('all');

  // État local
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [boardToDelete, setBoardToDelete] = useState(null);
  const [players, setPlayers] = useState([]);
  const [users, setUsers] = useState([]);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  // État local pour gérer l'expansion des accordéons
  const [expandedTags, setExpandedTags] = useState(new Set());
  // État local pour la sélection de plusieurs tags
  const [selectedTags, setSelectedTags] = useState([]);

  // On récupère le contexte
  const {
    data,
    setData,
    loading,
    setLoading,
    error,
    setError,
    selectedPlayer,
    setSelectedPlayer,
    selectedUser,
    setSelectedUser,
    tags,
    setTags
  } = useBigBoardTable();

  // État pour stocker les query params
  const [pendingPlayerId, setPendingPlayerId] = useState(null);
  const [pendingUserId, setPendingUserId] = useState(null);
  const [pendingTagIds, setPendingTagIds] = useState([]);

  // =====================================================
  // =============== APPEL API ============================
  // =====================================================
  const refreshData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${backendUrl}/api/bigboard`, {
        params: { section }
      });
      setData(response.data);
      setError(null);
    } catch (error) {
      console.error('Error al cargar los Big Boards:', error);
      setError('No se pueden cargar los Big Boards.');
    } finally {
      setLoading(false);
    }
  };

  // =====================================================
  // 1) Au montage : on lit les query params (playerId, userId, tagIds, expanded)
  // =====================================================
  useEffect(() => {
    const playerId = searchParams.get('playerId');
    const userId = searchParams.get('userId');
    const tagIds = searchParams.get('tagIds');
    const expandedParam = searchParams.get('expanded');

    if (playerId) setPendingPlayerId(parseInt(playerId, 10));
    if (userId) setPendingUserId(parseInt(userId, 10));

    if (tagIds) {
      const tagsToRestore = tagIds.split(',').map((id) => parseInt(id, 10));
      setPendingTagIds(tagsToRestore);
    }

    if (expandedParam) {
      const ids = expandedParam
        .split(',')
        .filter((id) => id.trim() !== '')
        .map((id) => parseInt(id, 10));
      setExpandedTags(new Set(ids));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // =====================================================
  // 2) Charge data initiale une seule fois
  // =====================================================
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [tagsResponse, playersResponse, usersResponse] = await Promise.all([
          axios.get(`${backendUrl}/api/tags`, { params: { section } }),
          axios.get(`${backendUrl}/api/players/select`, { params: { section } }),
          axios.get(`${backendUrl}/api/users`, { params: { section } })
        ]);

        setTags(tagsResponse.data);
        setPlayers(playersResponse.data);
        setUsers(Array.isArray(usersResponse.data) ? usersResponse.data : []);

        // Restauration du joueur
        if (pendingPlayerId && playersResponse.data.players) {
          const foundP = playersResponse.data.players.find(
            (p) => p.id === pendingPlayerId
          );
          if (foundP) setSelectedPlayer(foundP);
        }

        // Restauration de l’utilisateur
        if (pendingUserId && usersResponse.data) {
          const foundU = usersResponse.data.find((u) => u.id === pendingUserId);
          if (foundU) setSelectedUser(foundU);
        }

        // Restauration des tags
        if (pendingTagIds.length > 0 && tagsResponse.data) {
          const foundTags = tagsResponse.data.filter((tag) =>
            pendingTagIds.includes(tag.id)
          );
          if (foundTags.length > 0) setSelectedTags(foundTags);
        }
      } catch (error) {
        console.error('Error al cargar los datos iniciales:', error);
      }
      // Ensuite, on rafraîchit la data
      refreshData();
    };

    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // =====================================================
  // 3) Re-fetch si on revient sur la page (focus)
  // =====================================================
  useEffect(() => {
    const handleFocus = () => {
      refreshData();
    };
    window.addEventListener('focus', handleFocus);
    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  // =====================================================
  // =============== HANDLERS CRUD ========================
  // =====================================================
  const handleEditClick = (boardId) => {
    navigate(`/bigboard/edit/${boardId}`);
  };

  const handleDelete = async (boardId) => {
    try {
      await axios.delete(`${backendUrl}/api/bigboard/${boardId}`, {
        params: { section }
      });
      await refreshData();
      setOpenDeleteDialog(false);
      setBoardToDelete(null);
    } catch (error) {
      console.error('Error al eliminar el Big Board:', error);
      setError('Error al eliminar el Big Board.');
    }
  };

  const handlePlayerClick = (player) => {
    navigate(`/players/${player.id}`);
  };

  const handlePlayerUrlClick = (url, event) => {
    event.stopPropagation();
    window.open(url, '_blank');
  };

  // =====================================================
  // ============== FILTRES LOCAUX (memo) ================
  // =====================================================
  const filteredBoards = React.useMemo(() => {
    if (!data) return [];

    return data.filter((board) => {
      // 1) Filtre par type, selon le rôle
      const boardTypeLower = board.type ? board.type.toLowerCase() : '';

      let passTypeCheck = true;
      if (userRole === 'super admin') {
        if (bigBoardTypeFilter === 'pro') {
          passTypeCheck = boardTypeLower === 'pro';
        } else if (bigBoardTypeFilter === 'cantera') {
          passTypeCheck = boardTypeLower === 'cantera';
        }
        // 'all' => passTypeCheck reste true
      } else if (userRole === 'admin pro') {
        passTypeCheck = boardTypeLower === 'pro';
      } else if (userRole === 'admin cantera') {
        passTypeCheck = boardTypeLower === 'cantera';
      } else if (userRole === 'entrenador' || userRole === 'pdp') {
        // On ne filtre pas par type,
        // mais on ne voit que les boards dont le user.id == currentUserId
        passTypeCheck = board.user.id === currentUserId;
      }

      if (!passTypeCheck) return false;

      // 2) Filtre joueur
      const matchesPlayer =
        !selectedPlayer || board.players.some((p) => p.id === selectedPlayer.id);
      // 3) Filtre user
      const matchesUser = !selectedUser || board.user.id === selectedUser.id;
      // 4) Filtre tags
      const matchesTags =
        selectedTags.length === 0 ||
        board.tags.some((boardTag) =>
          selectedTags.some((st) => st.id === boardTag.id)
        );

      return matchesPlayer && matchesUser && matchesTags;
    });
  }, [
    data,
    selectedPlayer,
    selectedUser,
    selectedTags,
    bigBoardTypeFilter,
    userRole,
    currentUserId
  ]);

  // Regrouper par tag
  const groupedByTag = React.useMemo(() => {
    if (!tags) return [];
    const groups = new Map();
    tags.forEach((t) => {
      groups.set(t.id, { tag: t, boards: [] });
    });
    filteredBoards.forEach((board) => {
      board.tags.forEach((t) => {
        if (groups.has(t.id)) {
          groups.get(t.id).boards.push(board);
        }
      });
    });
    // On ne garde que les tags ayant des boards
    return Array.from(groups.values()).filter((g) => g.boards.length > 0);
  }, [tags, filteredBoards]);

  // =====================================================
  // =========== MISE A JOUR DES URL PARAMS ==============
  // =====================================================
  const updateSearchParams = (newState) => {
    const params = new URLSearchParams(searchParams.toString());

    // On stocke l'expansion
    if (expandedTags.size > 0) {
      params.set('expanded', Array.from(expandedTags).join(','));
    } else {
      params.delete('expanded');
    }

    // Player
    if (newState.selectedPlayer !== undefined) {
      const val = newState.selectedPlayer ? newState.selectedPlayer.id : '';
      val ? params.set('playerId', val) : params.delete('playerId');
    } else if (selectedPlayer) {
      params.set('playerId', selectedPlayer.id);
    } else {
      params.delete('playerId');
    }

    // User
    if (newState.selectedUser !== undefined) {
      const val = newState.selectedUser ? newState.selectedUser.id : '';
      val ? params.set('userId', val) : params.delete('userId');
    } else if (selectedUser) {
      params.set('userId', selectedUser.id);
    } else {
      params.delete('userId');
    }

    // Tags
    if (newState.selectedTags !== undefined) {
      const tagIds = newState.selectedTags.map((t) => t.id);
      if (tagIds.length > 0) {
        params.set('tagIds', tagIds.join(','));
      } else {
        params.delete('tagIds');
      }
    } else if (selectedTags.length > 0) {
      params.set(
        'tagIds',
        selectedTags.map((t) => t.id).join(',')
      );
    } else {
      params.delete('tagIds');
    }

    setSearchParams(params, { replace: true });
  };

  // Mise à jour de l’URL quand on change de sélection
  useEffect(() => {
    updateSearchParams({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlayer, selectedUser, selectedTags, expandedTags]);

  // =====================================================
  // =========== RENDU ==================================
  // =====================================================
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error}
      </Alert>
    );
  }

  if (!data || data.length === 0) {
    return (
      <Alert severity="info" sx={{ m: 2 }}>
        No hay Rankings disponibles
      </Alert>
    );
  }

  // Pour les rôles entrenador / pdp => on désactive le lien vers la fiche joueur
  const disablePlayerLink = userRole === 'entrenador' || userRole === 'pdp';

  return (
    <Paper elevation={2}>
      <Box sx={{ p: 2, pb: isMobile ? 7 : 2 }}>
        {/* ======================================================
            BARRE DE FILTRES (Desktop)
        ====================================================== */}
        {!isMobile && (
          <Grid container spacing={2} sx={{ mb: 3 }}>
            {/* Filtre Joueur */}
            <Grid item xs={12} md={4}>
              <Autocomplete
                fullWidth
                options={players.players || []}
                getOptionLabel={(p) => p?.nom || ''}
                value={selectedPlayer}
                onChange={(_, newValue) => {
                  setSelectedPlayer(newValue);
                  updateSearchParams({ selectedPlayer: newValue });
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" label="Filtrar por Jugador" />
                )}
                renderOption={(props, player) => (
                  <Box
                    component="li"
                    {...props}
                    sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                  >
                    <Avatar
                      src={`${mediaUrl}/photos/${player.id}.png`}
                      alt={player.nom}
                      sx={{ width: 32, height: 32 }}
                    />
                    <Typography noWrap>{player.nom}</Typography>
                  </Box>
                )}
                ListboxComponent={ListboxComponent}
                clearOnEscape
              />
            </Grid>

            {/* Filtre User */}
            <Grid item xs={12} md={4}>
              <Autocomplete
                fullWidth
                options={users}
                getOptionLabel={(u) => (u ? `${u.prenom} ${u.nom}` : '')}
                value={selectedUser}
                onChange={(_, newValue) => {
                  setSelectedUser(newValue);
                  updateSearchParams({ selectedUser: newValue });
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" label="Filtrar por Usuario" />
                )}
                renderOption={(props, user) => (
                  <Box
                    component="li"
                    {...props}
                    sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                  >
                    <UserAvatar user={user} mediaUrl={mediaUrl} size={32} />
                    <Typography noWrap>{`${user.prenom} ${user.nom}`}</Typography>
                  </Box>
                )}
                clearOnEscape
              />
            </Grid>

            {/* Filtre Tags OU Filtre type si superadmin */}
            {userRole === 'super admin' ? (
              <>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    multiple
                    fullWidth
                    options={tags}
                    getOptionLabel={(t) => t.libelle}
                    value={selectedTags}
                    onChange={(_, newValue) => {
                      setSelectedTags(newValue);
                      updateSearchParams({ selectedTags: newValue });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" label="Filtrar por Tags" />
                    )}
                    renderOption={(props, t) => (
                      <Box
                        component="li"
                        {...props}
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <Chip
                          size="small"
                          label={t.libelle}
                          style={{
                            backgroundColor: t.code_couleur,
                            color: getContrastColor(t.code_couleur)
                          }}
                        />
                      </Box>
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((tag, index) => (
                        <Chip
                          key={tag.id}
                          size="small"
                          label={tag.libelle}
                          {...getTagProps({ index })}
                          style={{
                            backgroundColor: tag.code_couleur,
                            color: getContrastColor(tag.code_couleur)
                          }}
                        />
                      ))
                    }
                    clearOnEscape
                  />
                </Grid>
                <Grid item xs={12} md={1}>
                  <FormControl fullWidth>
                    <Select
                      size="small"
                      value={bigBoardTypeFilter}
                      onChange={(e) => setBigBoardTypeFilter(e.target.value)}
                    >
                      <MenuItem value="all">Todos</MenuItem>
                      <MenuItem value="pro">Pro</MenuItem>
                      <MenuItem value="cantera">Cantera</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} md={4}>
                <Autocomplete
                  multiple
                  fullWidth
                  options={tags}
                  getOptionLabel={(t) => t.libelle}
                  value={selectedTags}
                  onChange={(_, newValue) => {
                    setSelectedTags(newValue);
                    updateSearchParams({ selectedTags: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" label="Filtrar por Tags" />
                  )}
                  renderOption={(props, t) => (
                    <Box
                      component="li"
                      {...props}
                      sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    >
                      <Chip
                        size="small"
                        label={t.libelle}
                        style={{
                          backgroundColor: t.code_couleur,
                          color: getContrastColor(t.code_couleur)
                        }}
                      />
                    </Box>
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((tag, index) => (
                      <Chip
                        key={tag.id}
                        size="small"
                        label={tag.libelle}
                        {...getTagProps({ index })}
                        style={{
                          backgroundColor: tag.code_couleur,
                          color: getContrastColor(tag.code_couleur)
                        }}
                      />
                    ))
                  }
                  clearOnEscape
                />
              </Grid>
            )}
          </Grid>
        )}

        {/* ===================================
            LISTE DES ACCORDÉONS 
        =================================== */}
        {groupedByTag.map(({ tag, boards }) => (
          <TagAccordion
            key={tag.id}
            tag={tag}
            boards={boards}
            onEdit={handleEditClick}
            onDelete={(board) => {
              setBoardToDelete(board);
              setOpenDeleteDialog(true);
            }}
            onPlayerClick={handlePlayerClick}
            onPlayerUrlClick={handlePlayerUrlClick}
            mediaUrl={mediaUrl}
            expandedTags={expandedTags}
            setExpandedTags={setExpandedTags}
            disablePlayerLink={disablePlayerLink}
          />
        ))}

        {/* ===================================
            BOUTON FLOTTANT (Mobile)
        =================================== */}
        {isMobile && (
          <Fab
            color="primary"
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              zIndex: theme.zIndex.fab
            }}
            onClick={() => setOpenFilterDrawer(true)}
          >
            <FilterList />
          </Fab>
        )}
      </Box>

      {/* ===================================
          DRAWER de FILTRES (Mobile)
      =================================== */}
      <FilterDrawer
        open={openFilterDrawer}
        onClose={() => setOpenFilterDrawer(false)}
        players={players}
        users={users}
        selectedPlayer={selectedPlayer}
        selectedUser={selectedUser}
        onPlayerChange={(_, newValue) => {
          setSelectedPlayer(newValue);
          updateSearchParams({ selectedPlayer: newValue });
        }}
        onUserChange={(_, newValue) => {
          setSelectedUser(newValue);
          updateSearchParams({ selectedUser: newValue });
        }}
        mediaUrl={mediaUrl}
        tags={tags}
        selectedTags={selectedTags}
        onTagsChange={(_, newValue) => {
          setSelectedTags(newValue);
          updateSearchParams({ selectedTags: newValue });
        }}
        userRole={userRole}
        bigBoardTypeFilter={bigBoardTypeFilter}
        setBigBoardTypeFilter={setBigBoardTypeFilter}
      />

      {/* ===================================
          DIALOG DE SUPPRESSION
      =================================== */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
          setBoardToDelete(null);
        }}
      >
        <DialogTitle>Confirmar eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Está seguro de que desea eliminar el Big Board "
            {boardToDelete?.libelle}"? Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteDialog(false);
              setBoardToDelete(null);
            }}
          >
            Cancelar
          </Button>
          <Button
            color="error"
            onClick={() => handleDelete(boardToDelete?.id)}
            variant="contained"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default BigBoardTable;
