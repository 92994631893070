// RechercheInformeContext.jsx

import React, { createContext, useState } from 'react';

const RechercheInformeContext = createContext();

export const RechercheInformeProvider = ({ children }) => {
  // Filtres
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [anneeNaissance, setAnneeNaissance] = useState('');
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [selectedEventos, setSelectedEventos] = useState([]);

  // Gestion de l'état d'ouverture des rows
  const [openRows, setOpenRows] = useState({});

  // Gère l'ouverture/fermeture d'une ligne (informe)
  const toggleRow = (rowId) => {
    setOpenRows((prev) => ({ ...prev, [rowId]: !prev[rowId] }));
  };

  return (
    <RechercheInformeContext.Provider
      value={{
        // Filtres
        selectedPlayer,
        setSelectedPlayer,
        selectedUser,
        setSelectedUser,
        anneeNaissance,
        setAnneeNaissance,
        selectedPositions,
        setSelectedPositions,
        selectedEventos,
        setSelectedEventos,

        // Rows ouvertes
        openRows,
        setOpenRows,
        toggleRow,
      }}
    >
      {children}
    </RechercheInformeContext.Provider>
  );
};

export default RechercheInformeContext;
