import React, { useState, useEffect } from 'react';
import {
  TextField, Button, MenuItem, Select, FormControl, InputLabel,
  Box, Typography, Checkbox, ListItemText,
  Paper, Grid, Autocomplete
} from '@mui/material';
import axios from 'axios';

// === Composant pour la sélection nationale (adaptez-le à vos besoins) ===
import SelectSelectionNational from '../components/SelectSelectionNational';

// === Rôles et attributs ===
const offensiveRoles = [
  // Guard
  "Generador con balón",
  "Generador secundario con balón",
  "Anotador Dominante",
  "Base Director",
  "Tirador Especialista",

  // Wing
  "Alero Generador",
  "Ala Versátil",
  "Alero Fuerte",
  "Alero Slasher",
  "Alero Spot-up",
  "3&D",

  // Forward
  "Ala-Pívot Tradicional",
  "Skilled Forward",
  "4 Abierto",

  // Big
  "Pívot Generador",
  "Pívot Versátil",
  "Pívot Mid-Range",
  "5 Abierto",
  "Anotador en el poste bajo",
  "Pívot finalizador cerca del aro",
  "Pívot de bloqueo y continuación",
  "Rol Ofensivo Marginal"
];

const defensiveRoles = [
  "1x1 Intenso",
  "Defensor Perimetral", 
  "Wing Stopper",
  "Switch Defensor",
  "Anchor Big",
  "Reboteador Defensivo",
  "Rim Protector",
  "No Defensor"
];

const JEAttributes = [
  "1x1",
  "3-Level Scorer",
  "Actividad sin balón",
  "Atlético",
  "Capacidad en el Pick & Roll",
  "Capacidad para sacar faltas",
  "Clutch",
  "Duro",
  "Energía",
  "Envergadura",
  "Físico",
  "Hustle",
  "IQ/Visión de juego",
  "Juego en el poste bajo",
  "Paint Touches",
  "Penetrador",
  "Puntos",
  "Primer paso",
  "Producción constante",
  "Rebote",
  "Talento",
  "Tirador",
  "Tiro en Catch & Shoot (OTC 3)",
  "Tiro desde la esquina (Corner 3)",
  "Tiro tras Bote",
  "Transiciones/Ritmo en la pista",
  "Trabajo sucio (Blue Collar)",
  "Velocidad/Agilidad",
  "Versatilidad",
  "Verticalidad"
];

const JIAttributes = [
  "1x1",
  "Actividad sin balón",
  "Atlético",
  "Bloqueo, continuación y finalización",
  "Bloqueos eficaces",
  "Buen tacto",
  "Clutch",
  "Duro",
  "Energía",
  "Envergadura",
  "Footwork",
  "Físico",
  "Hustle",
  "IQ/Visión de juego",
  "Juego poste bajo/Juego de cara",
  "Lob Threat",
  "Puntos",
  "Producción constante",
  "Protector del aro",
  "Rebote",
  "Rim-Runner",
  "Talento",
  "Tirador",
  "Tiro en Catch & Shoot (OTC 3)",
  "Tiro desde la esquina + P&Pop",
  "Tiro tras bote",
  "Trabajo sucio (Blue Collar)",
  "Transiciones/Ritmo en la pista",
  "Varios esfuerzos en la misma acción",
  "Velocidad/Agilidad",
  "Versatilidad",
  "Verticalidad"
];

// === Listes pour "Rango Salarial", "Contrato", etc. ===
const priceRanges = [
  "0-50k€",
  "50-100k€",
  "100-150k€",
  "150-200k€",
  "200-250k€",
  "250-300k€",
  "300-350k€",
  "350-400k€",
  "400-450k€",
  "450-500k€",
  "500k€-1M€"
];

const contratos = [
  "2-Way",
  "Exhibit-10",
  "1 año",
  "1+1",
  "2 años",
  "3 años",
  "On Loan"
];

// === Nouveau champ Buyout ===
const buyoutOptions = [
  "0-50k€",
  "50-100k€",
  "100-150k€",
  "150-200k€",
  "200-250k€",
  "250-300k€",
  "300-350k€",
  "350-400k€",
  "400-450k€",
  "450-500k€",
  "500k€-1M€"
];

// === Origen Genético ===
const origenGeneticoOptions = [
  "Africanos (subsahariana) - Camerun, Nigeria, Senegal, Mali, etc.",
  "Afrocaribenos - Martinica, Republica Dominicana, Puerto Rico, Islas Virgenes, etc.",
  "Balcanes - Serbia, Croacia, Bosnia, Montenegro, Macedonia del Norte, etc.",
  "Europa Occidental - Francia, Alemania, Espana, Italia, Belgica, etc.",
  "Latinoamericanos - Argentina, Brasil, Venezuela, Mexico, etc.",
  "Nordafricanos - Tunez, Marruecos, Egipto, etc.",
  "Norteamericanos - Estados Unidos, Canada, etc."
];

// Simple composant pour "envelopper" un champ sur la grille
const FormField = ({ children }) => (
  <Grid item xs={12} md={4}>
    <Box sx={{ p: 1 }}>
      {children}
    </Box>
  </Grid>
);

// Formatage de date pour l'input type="date"
const formatDateForInput = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toISOString().split('T')[0];
};

const formatDateForBackend = (input) => {
  if (!input || input.includes('T')) return input;
  const [year, month, day] = input.split('-');
  if (year && month && day) {
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }
  return input;
};

/**
 * PlayerProfileEditor
 * @param {object}   player     - Les données initiales du joueur
 * @param {function} onSave     - Callback lorsqu'on a sauvegardé
 * @param {function} onCancel   - Callback lorsqu'on annule
 * @param {string}   backendUrl - URL du backend
 * @param {string}   section    - La section (ex. "hommes")
 */
const PlayerProfileEditor = ({
  player,
  onSave,
  onCancel,
  backendUrl,
  section,
}) => {
  // On clone "player" pour l'édition
  const [editablePlayer, setEditablePlayer] = useState({ ...player });
  const [errorMessage, setErrorMessage] = useState('');
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  // Listes récupérées du backend
  const [nationalities, setNationalities] = useState([]);
  const [teams, setTeams] = useState([]);
  const [leagues1, setLeagues1] = useState([]);
  const [leagues2, setLeagues2] = useState([]);
  const [agencies, setAgencies] = useState([]);

  // === JE/JI ===
  const [jeJiType, setJeJiType] = useState(editablePlayer.je_ji || '');
  const [jeJiAttributes, setJeJiAttributes] = useState(
    editablePlayer.selection_je_ji ? editablePlayer.selection_je_ji.split(',') : []
  );

  // === Rôles offensif/défensif ===
  const [rolOfensivo, setRolOfensivo] = useState(
    editablePlayer.offensive_role ? editablePlayer.offensive_role.split(',') : []
  );
  const [rolDefensivo, setRolDefensivo] = useState(
    editablePlayer.defensive_role ? editablePlayer.defensive_role.split(',') : []
  );

  // Chargement des listes du backend
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players/teams`, { params: { section } });
        setTeams(response.data.teams || []);
      } catch (error) {
        console.error('Error fetching teams:', error);
      }
    };

    const fetchLeagues1 = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players/leagues1`, { params: { section } });
        setLeagues1(response.data.leagues1 || []);
      } catch (error) {
        console.error('Error fetching leagues1:', error);
      }
    };

    const fetchLeagues2 = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players/leagues2`, { params: { section } });
        setLeagues2(response.data.leagues2 || []);
      } catch (error) {
        console.error('Error fetching leagues2:', error);
      }
    };

    const fetchAgencies = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players/agencies`, { params: { section } });
        setAgencies(response.data.agencies || []);
      } catch (error) {
        console.error('Error fetching agencies:', error);
      }
    };

    const fetchNationalities = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players/nationalities`, { params: { section } });
        setNationalities(response.data.nationalities || []);
      } catch (error) {
        console.error('Error fetching nationalities:', error);
      }
    };

    fetchTeams();
    fetchLeagues1();
    fetchLeagues2();
    fetchAgencies();
    fetchNationalities();
  }, [backendUrl, section]);

  // Handler de changement générique
  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setEditablePlayer((prev) => ({ ...prev, [name]: fieldValue }));
  };

  // Mise à jour dans la BDD
  const handleUpdate = async () => {
    // Formatage de la date
    const dateNaissanceFormatted = formatDateForBackend(editablePlayer.date_naissance);
    const anneeNaissanceFormatted = dateNaissanceFormatted 
      ? new Date(dateNaissanceFormatted).getFullYear()
      : '';

    const formData = new FormData();
    // Champs principaux
    formData.append('nom', (editablePlayer.nom || '').trim());
    formData.append('annee_naissance', anneeNaissanceFormatted || '');
    formData.append('date_naissance', dateNaissanceFormatted || '');
    formData.append('taille', editablePlayer.taille || '');
    formData.append('poids', editablePlayer.poids || '');
    formData.append('poste', editablePlayer.poste || '');
    formData.append('section', section);

    // "Grupo" => type
    formData.append('type', editablePlayer.type || '');

    // Nationalités
    formData.append('nationalite', editablePlayer.nationalite || '');
    formData.append('nationalite_2', editablePlayer.nationalite_2 || '');
    formData.append('selection_national', editablePlayer.selection_national || '');

    // === Origen Genético (nouveau champ) ===
    formData.append('origen_genetico', editablePlayer.origen_genetico || '');

    // JE / JI
    formData.append('je_ji', jeJiType || '');
    const jeJiAttrJoined = jeJiAttributes.length > 0 ? jeJiAttributes.join(',') : '';
    formData.append('selection_je_ji', jeJiAttrJoined);

    // Rôles offensif/défensif
    formData.append('offensive_role', rolOfensivo.length > 0 ? rolOfensivo.join(',') : '');
    formData.append('defensive_role', rolDefensivo.length > 0 ? rolDefensivo.join(',') : '');

    // Mano Dominante
    formData.append('mano', editablePlayer.mano || '');

    // Shooting Range
    formData.append('shooting_range', editablePlayer.shooting_range || '');

    // Flag Behaviour / Injury
    formData.append('flag_behaviour', editablePlayer.flag_behaviour || '');
    formData.append('injury', editablePlayer.injury || '');
    formData.append('pro_comparaison', editablePlayer.pro_comparaison || '');

    // Current/Proyección VBC Rating
    formData.append('level', editablePlayer.level || '');
    formData.append('proyeccion', editablePlayer.proyeccion || '');

    // Licencia
    formData.append('passport', editablePlayer.passport || '');

    // Equipo / Ligas / Competición Europa
    formData.append('team', editablePlayer.team || '');
    formData.append('league_1', editablePlayer.league_1 || '');
    formData.append('league_2', editablePlayer.league_2 || '');
    formData.append('competicion_europa', editablePlayer.competicion_europa || '');

    // Draft
    formData.append('pre_draft_team', editablePlayer.pre_draft_team || '');
    formData.append('draft_year', editablePlayer.draft_year || '');
    formData.append('draft_pick', editablePlayer.draft_pick || '');

    // PIT / Eurocamp
    formData.append('pit', editablePlayer.pit || '');
    formData.append('eurocamp', editablePlayer.eurocamp || '');

    // === SL Vegas (nouveau champ) ===
    formData.append('slvegas', editablePlayer.slvegas || '');

    // Expériences
    formData.append('nba_experience', editablePlayer.nba_experience || '');
    formData.append('acb_experience', editablePlayer.acb_experience || '');
    formData.append('experienca_europa', editablePlayer.experienca_europa || '');

    // === Experiencia Fuera de su País (nouveau champ) ===
    formData.append('experiencia_fuera', editablePlayer.experiencia_fuera || '');

    // === Experiencia NCAA (nouveau champ) ===
    formData.append('experienca_ncaa', editablePlayer.experienca_ncaa || '');

    // Contractuel
    formData.append('agency', editablePlayer.agency || '');
    formData.append('price_range', editablePlayer.price_range || '');
    formData.append('contract', editablePlayer.contract || '');

    // === Buyout (nouveau champ) ===
    formData.append('buyout', editablePlayer.buyout || '');

    // URL
    formData.append('url', editablePlayer.url || '');

    try {
      await axios.put(
        `${backendUrl}/api/players/${editablePlayer.id}?section=${section}`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      alert('¡Jugador actualizado con éxito!');
      onSave(editablePlayer);
    } catch (error) {
      console.error('Error updating player:', error);
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.message || 'Error al actualizar el jugador.');
      } else {
        setErrorMessage('Error al actualizar el jugador.');
      }
      setErrorModalOpen(true);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      {/* 1. INFORMACIÓN BÁSICA */}
      <Paper sx={{ p: 3, mb: 2 }}>
        <Typography variant="h6" gutterBottom>1. Información Básica</Typography>
        <Grid container>
          {/* Nombre */}
          <FormField>
            <TextField
              label="Nombre"
              name="nom"
              value={editablePlayer.nom || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          {/* Grupo (type) */}
          <FormField>
            <FormControl fullWidth>
              <InputLabel>Grupo</InputLabel>
              <Select
                name="type"
                value={editablePlayer.type || ''}
                onChange={handleChange}
                label="Grupo"
              >
                <MenuItem value="pro">Pro</MenuItem>
                <MenuItem value="cantera">Cantera</MenuItem>
              </Select>
            </FormControl>
          </FormField>
        </Grid>

        {/* Peso (kg) / Altura (cm) */}
        <Grid container>
          <FormField>
            <TextField
              label="Peso (kg)"
              name="poids"
              value={editablePlayer.poids || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          <FormField>
            <TextField
              label="Altura (cm)"
              name="taille"
              value={editablePlayer.taille || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>
        </Grid>

        {/* Nacionalidad / Segunda Nacionalidad */}
        <Grid container>
          <FormField>
            <Autocomplete
              freeSolo
              options={nationalities}
              inputValue={editablePlayer.nationalite || ''}
              onInputChange={(event, newInputValue) => {
                setEditablePlayer((prev) => ({ ...prev, nationalite: newInputValue }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Nacionalidad"
                  fullWidth
                />
              )}
            />
          </FormField>

          <FormField>
            <Autocomplete
              freeSolo
              options={nationalities}
              inputValue={editablePlayer.nationalite_2 || ''}
              onInputChange={(event, newInputValue) => {
                setEditablePlayer((prev) => ({ ...prev, nationalite_2: newInputValue }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Segunda Nacionalidad"
                  fullWidth
                />
              )}
            />
          </FormField>
        </Grid>

        {/* Origen Genético (nouveau champ) */}
        <Grid container>
          <FormField>
            <FormControl fullWidth>
              <InputLabel>Origen Genético</InputLabel>
              <Select
                name="origen_genetico"
                value={editablePlayer.origen_genetico || ''}
                onChange={handleChange}
                label="Origen Genético"
              >
                {origenGeneticoOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormField>
        </Grid>

        {/* Fecha de Nacimiento */}
        <Grid container>
          <FormField>
            <TextField
              label="Fecha de Nacimiento"
              name="date_naissance"
              type="date"
              value={formatDateForInput(editablePlayer.date_naissance)}
              onChange={(e) => setEditablePlayer(prev => ({ 
                ...prev, 
                date_naissance: e.target.value 
              }))}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </FormField>
        </Grid>
      </Paper>

      {/* 2. CARACTERÍSTICAS DEL JUGADOR */}
      <Paper sx={{ p: 3, mb: 2 }}>
        <Typography variant="h6" gutterBottom>2. Características del Jugador</Typography>
        <Grid container>
          {/* Posición (poste) */}
          <FormField>
            <TextField
              label="Posición"
              name="poste"
              value={editablePlayer.poste || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          {/* Mano Dominante */}
          <FormField>
            <FormControl fullWidth>
              <InputLabel>Mano Dominante</InputLabel>
              <Select
                name="mano"
                value={editablePlayer.mano || ''}
                onChange={handleChange}
                label="Mano Dominante"
              >
                <MenuItem value="Derecha">Derecha</MenuItem>
                <MenuItem value="Izquierda">Izquierda</MenuItem>
                <MenuItem value="Ambas">Ambas</MenuItem>
              </Select>
            </FormControl>
          </FormField>

          {/* Shooting Range */}
          <FormField>
            <FormControl fullWidth>
              <InputLabel>Shooting Range</InputLabel>
              <Select
                name="shooting_range"
                value={editablePlayer.shooting_range || ''}
                onChange={handleChange}
                label="Shooting Range"
              >
                <MenuItem value="Rim">Rim</MenuItem>
                <MenuItem value="Mid-Range">Mid-Range</MenuItem>
                <MenuItem value="3Pts">3Pts</MenuItem>
              </Select>
            </FormControl>
          </FormField>
        </Grid>

        {/* Perfil Ofensivo / Perfil Defensivo */}
        <Grid container>
          <FormField>
            <FormControl fullWidth>
              <InputLabel>Perfil Ofensivo</InputLabel>
              <Select
                multiple
                value={rolOfensivo}
                onChange={(e) => setRolOfensivo(e.target.value)}
                label="Perfil Ofensivo"
                renderValue={(selected) => selected.join(', ')}
              >
                {offensiveRoles.map((role) => (
                  <MenuItem key={role} value={role}>
                    <Checkbox checked={rolOfensivo.indexOf(role) > -1} />
                    <ListItemText primary={role} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormField>

          <FormField>
            <FormControl fullWidth>
              <InputLabel>Perfil Defensivo</InputLabel>
              <Select
                multiple
                value={rolDefensivo}
                onChange={(e) => setRolDefensivo(e.target.value)}
                label="Perfil Defensivo"
                renderValue={(selected) => selected.join(', ')}
              >
                {defensiveRoles.map((role) => (
                  <MenuItem key={role} value={role}>
                    <Checkbox checked={rolDefensivo.indexOf(role) > -1} />
                    <ListItemText primary={role} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormField>
        </Grid>

        {/* JE/JI + JE/JI Attributes */}
        <Grid container>
          <FormField>
            <FormControl fullWidth>
              <InputLabel>Skills JE/JI</InputLabel>
              <Select
                name="je_ji"
                value={jeJiType || ''}
                onChange={(e) => {
                  setJeJiType(e.target.value);
                  setJeJiAttributes([]);
                }}
                label="Skills JE/JI"
              >
                <MenuItem value="JE">JE (Juego Exterior)</MenuItem>
                <MenuItem value="JI">JI (Juego Interior)</MenuItem>
              </Select>
            </FormControl>
          </FormField>

          {jeJiType && (
            <FormField>
              <FormControl fullWidth>
                <InputLabel>Skills JE ou JI </InputLabel>
                <Select
                  multiple
                  value={jeJiAttributes}
                  onChange={(e) => setJeJiAttributes(e.target.value)}
                  renderValue={(selected) => selected.join(', ')}
                  label="Skills JE ou JI "
                >
                  {(jeJiType === 'JE' ? JEAttributes : JIAttributes).map((attr) => (
                    <MenuItem key={attr} value={attr}>
                      <Checkbox checked={jeJiAttributes.indexOf(attr) > -1} />
                      <ListItemText primary={attr} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormField>
          )}
        </Grid>

        {/* Current VBC Rating / Proyección VBC Rating */}
        <Grid container>
          <FormField>
            <FormControl fullWidth>
              <InputLabel>Current VBC Rating</InputLabel>
              <Select
                name="level"
                value={editablePlayer.level || ''}
                onChange={handleChange}
                label="Current VBC Rating"
              >
                <MenuItem value="Proyecto Nivel Bajo">Proyecto Nivel Bajo</MenuItem>
                <MenuItem value="Proyecto Nivel Medio">Proyecto Nivel Medio</MenuItem>
                <MenuItem value="Proyecto Nivel Bueno">Proyecto Nivel Bueno</MenuItem>
                <MenuItem value="Proyecto Top">Proyecto Top</MenuItem>
                <MenuItem value="Futura Estrella">Futura Estrella</MenuItem>
                <MenuItem value="EBA Nivel Bajo (10-20)">EBA Nivel Bajo (10-20)</MenuItem>
                <MenuItem value="EBA Nivel Alto (20-30)">EBA Nivel Alto (20-30)</MenuItem>
                <MenuItem value="LEB Nivel Bajo (30-40)">LEB Nivel Bajo (30-40)</MenuItem>
                <MenuItem value="LEB Nivel Alto (40-50)">LEB Nivel Alto (40-50)</MenuItem>
                <MenuItem value="ACB Rotación (50-60)">ACB Rotación (50-60)</MenuItem>
                <MenuItem value="Bottom BCL/Eurocup (60-65)">Bottom BCL/Eurocup (60-65)</MenuItem>
                <MenuItem value="Mid BCL/Eurocup (65-70)">Mid BCL/Eurocup (65-70)</MenuItem>
                <MenuItem value="Top BCL/Eurocup (70-75)">Top BCL/Eurocup (70-75)</MenuItem>
                <MenuItem value="EL Nivel Medio (75-80)">EL Nivel Medio (75-80)</MenuItem>
                <MenuItem value="EL Top (80-85)">EL Top (80-85)</MenuItem>
                <MenuItem value="NBA Rotación (85-90)">NBA Rotación (85-90)</MenuItem>
                <MenuItem value="NBA All-Star (95-100)">NBA All-Star (95-100)</MenuItem>
                <MenuItem value="College Nivel Bajo">College Nivel Bajo</MenuItem>
                <MenuItem value="College Nivel Medio">College Nivel Medio</MenuItem>
                <MenuItem value="College Top">College Top</MenuItem>
              </Select>
            </FormControl>
          </FormField>

          <FormField>
            <FormControl fullWidth>
              <InputLabel>Proyección VBC Rating</InputLabel>
              <Select
                name="proyeccion"
                value={editablePlayer.proyeccion || ''}
                onChange={handleChange}
                label="Proyección VBC Rating"
              >
                <MenuItem value="EBA Nivel Bajo (10-20)">EBA Nivel Bajo (10-20)</MenuItem>
                <MenuItem value="EBA Nivel Alto (20-30)">EBA Nivel Alto (20-30)</MenuItem>
                <MenuItem value="LEB Nivel Bajo (30-40)">LEB Nivel Bajo (30-40)</MenuItem>
                <MenuItem value="LEB Nivel Alto (40-50)">LEB Nivel Alto (40-50)</MenuItem>
                <MenuItem value="ACB Rotación (50-60)">ACB Rotación (50-60)</MenuItem>
                <MenuItem value="Bottom BCL/Eurocup (60-65)">Bottom BCL/Eurocup (60-65)</MenuItem>
                <MenuItem value="Mid BCL/Eurocup (65-70)">Mid BCL/Eurocup (65-70)</MenuItem>
                <MenuItem value="Top BCL/Eurocup (70-75)">Top BCL/Eurocup (70-75)</MenuItem>
                <MenuItem value="EL Nivel Medio (75-80)">EL Nivel Medio (75-80)</MenuItem>
                <MenuItem value="EL Top (80-85)">EL Top (80-85)</MenuItem>
                <MenuItem value="NBA Rotación (85-90)">NBA Rotación (85-90)</MenuItem>
                <MenuItem value="NBA All-Star (95-100)">NBA All-Star (95-100)</MenuItem>
              </Select>
            </FormControl>
          </FormField>
        </Grid>

        {/* Flag Behaviour / Injury Prone */}
        <Grid container>
          <FormField>
            <FormControl fullWidth>
              <InputLabel>Flag Behaviour</InputLabel>
              <Select
                name="flag_behaviour"
                value={editablePlayer.flag_behaviour || ''}
                onChange={handleChange}
                label="Flag Behaviour"
              >
                <MenuItem value="Green">Green</MenuItem>
                <MenuItem value="Orange">Orange</MenuItem>
                <MenuItem value="Red">Red</MenuItem>
              </Select>
            </FormControl>
          </FormField>

          <FormField>
            <FormControl fullWidth>
              <InputLabel>Injury Prone</InputLabel>
              <Select
                name="injury"
                value={editablePlayer.injury || ''}
                onChange={handleChange}
                label="Injury Prone"
              >
                <MenuItem value="Green">Green</MenuItem>
                <MenuItem value="Orange">Orange</MenuItem>
                <MenuItem value="Red">Red</MenuItem>
              </Select>
            </FormControl>
          </FormField>
        </Grid>

        {/* Comparación con un Jugador Pro */}
        <Grid container>
          <FormField>
            <TextField
              label="Comparación con un Jugador Pro"
              name="pro_comparaison"
              value={editablePlayer.pro_comparaison || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>
        </Grid>
      </Paper>

      {/* 3. SITUACIÓN ACTUAL */}
      <Paper sx={{ p: 3, mb: 2 }}>
        <Typography variant="h6" gutterBottom>3. Situación Actual</Typography>
        <Grid container>
          {/* Licencia */}
          <FormField>
            <TextField
              label="Licencia"
              name="passport"
              value={editablePlayer.passport || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          {/* Selección Nacional */}
          <FormField>
            <SelectSelectionNational
              value={editablePlayer.selection_national || ''}
              onChange={(event) => {
                setEditablePlayer(prev => ({ 
                  ...prev, 
                  selection_national: event.target.value 
                }));
              }}
            />
          </FormField>

          {/* Equipo Actual */}
          <FormField>
            <Autocomplete
              freeSolo
              options={teams}
              inputValue={editablePlayer.team || ''}
              onInputChange={(event, newInputValue) => {
                setEditablePlayer((prev) => ({ ...prev, team: newInputValue }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Equipo Actual"
                  fullWidth
                />
              )}
            />
          </FormField>
        </Grid>

        {/* Competición Europea / Liga Principal / Liga Secundaria */}
        <Grid container>
          <FormField>
            <FormControl fullWidth>
              <InputLabel>Competición Europea</InputLabel>
              <Select
                name="competicion_europa"
                value={editablePlayer.competicion_europa || ''}
                onChange={handleChange}
                label="Competición Europea"
              >
                <MenuItem value="EuroLeague">EuroLeague</MenuItem>
                <MenuItem value="EuroCup">EuroCup</MenuItem>
                <MenuItem value="FIBA BCL">FIBA BCL</MenuItem>
                <MenuItem value="FIBA EuropeCup">FIBA EuropeCup</MenuItem>
                <MenuItem value="Lat-Est">Lat-Est</MenuItem>
                <MenuItem value="ENBL">ENBL</MenuItem>
              </Select>
            </FormControl>
          </FormField>

          <FormField>
            <Autocomplete
              freeSolo
              options={leagues1}
              inputValue={editablePlayer.league_1 || ''}
              onInputChange={(event, newInputValue) => {
                setEditablePlayer((prev) => ({ ...prev, league_1: newInputValue }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Liga Principal"
                  fullWidth
                />
              )}
            />
          </FormField>

          <FormField>
            <Autocomplete
              freeSolo
              options={leagues2}
              inputValue={editablePlayer.league_2 || ''}
              onInputChange={(event, newInputValue) => {
                setEditablePlayer((prev) => ({ ...prev, league_2: newInputValue }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Liga Secundaria"
                  fullWidth
                />
              )}
            />
          </FormField>
        </Grid>
      </Paper>

      {/* 4. EXPERIENCIA PROFESIONAL */}
      <Paper sx={{ p: 3, mb: 2 }}>
        <Typography variant="h6" gutterBottom>4. Experiencia Profesional</Typography>
        <Grid container>
          {/* Año del Draft / Posición en el Draft / Equipo Pre-Draft */}
          <FormField>
            <TextField
              label="Año del Draft"
              name="draft_year"
              type="number"
              value={editablePlayer.draft_year || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          <FormField>
            <FormControl fullWidth>
              <InputLabel>Posición en el Draft</InputLabel>
              <Select
                name="draft_pick"
                value={editablePlayer.draft_pick || ''}
                onChange={handleChange}
                label="Posición en el Draft"
              >
                {/* 1st Round */}
                {[...Array(30)].map((_, index) => (
                  <MenuItem key={index + 1} value={`1st Round, #${index + 1}`}>
                    1st Round, #{index + 1}
                  </MenuItem>
                ))}
                {/* 2nd Round */}
                {[...Array(30)].map((_, index) => (
                  <MenuItem key={index + 31} value={`2nd Round, #${index + 31}`}>
                    2nd Round, #{index + 31}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormField>

          <FormField>
            <Autocomplete
              freeSolo
              options={teams}
              inputValue={editablePlayer.pre_draft_team || ''}
              onInputChange={(event, newInputValue) => {
                setEditablePlayer((prev) => ({ ...prev, pre_draft_team: newInputValue }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Pre-Draft"
                  fullWidth
                />
              )}
            />
          </FormField>
        </Grid>

        {/* PIT / Eurocamp / SL Vegas (nouveau champ) */}
        <Grid container>
          <FormField>
            <TextField
              label="PIT"
              name="pit"
              type="number"
              value={editablePlayer.pit || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          <FormField>
            <TextField
              label="Eurocamp"
              name="eurocamp"
              type="number"
              value={editablePlayer.eurocamp || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>

          <FormField>
            <TextField
              label="SL Vegas"
              name="slvegas"
              type="number"
              value={editablePlayer.slvegas || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>
        </Grid>

        {/* Expériences NBA / ACB / Europa */}
        <Grid container>
          <FormField>
            <FormControl fullWidth>
              <InputLabel>Experiencia NBA</InputLabel>
              <Select
                name="nba_experience"
                value={editablePlayer.nba_experience || ''}
                onChange={handleChange}
                label="Experiencia NBA"
              >
                <MenuItem value="SI">SI</MenuItem>
                <MenuItem value="NO">NO</MenuItem>
              </Select>
            </FormControl>
          </FormField>

          <FormField>
            <FormControl fullWidth>
              <InputLabel>Experiencia ACB</InputLabel>
              <Select
                name="acb_experience"
                value={editablePlayer.acb_experience || ''}
                onChange={handleChange}
                label="Experiencia ACB"
              >
                <MenuItem value="SI">SI</MenuItem>
                <MenuItem value="NO">NO</MenuItem>
              </Select>
            </FormControl>
          </FormField>

          <FormField>
            <FormControl fullWidth>
              <InputLabel>Experiencia Europa</InputLabel>
              <Select
                name="experienca_europa"
                value={editablePlayer.experienca_europa || ''}
                onChange={handleChange}
                label="Experiencia Europa"
              >
                <MenuItem value="SI">SI</MenuItem>
                <MenuItem value="NO">NO</MenuItem>
              </Select>
            </FormControl>
          </FormField>
        </Grid>

        {/* Experiencia Fuera de su País (nouveau champ) / Experiencia NCAA (nouveau champ) */}
        <Grid container>
          <FormField>
            <FormControl fullWidth>
              <InputLabel>Experiencia Fuera de su País</InputLabel>
              <Select
                name="experiencia_fuera"
                value={editablePlayer.experiencia_fuera || ''}
                onChange={handleChange}
                label="Experiencia Fuera de su País"
              >
                <MenuItem value="SI">SI</MenuItem>
                <MenuItem value="NO">NO</MenuItem>
              </Select>
            </FormControl>
          </FormField>

          <FormField>
            <FormControl fullWidth>
              <InputLabel>Experiencia NCAA</InputLabel>
              <Select
                name="experienca_ncaa"
                value={editablePlayer.experienca_ncaa || ''}
                onChange={handleChange}
                label="Experiencia NCAA"
              >
                <MenuItem value="SI">SI</MenuItem>
                <MenuItem value="NO">NO</MenuItem>
              </Select>
            </FormControl>
          </FormField>
        </Grid>
      </Paper>

      {/* 5. INFORMACIÓN CONTRACTUAL */}
      <Paper sx={{ p: 3, mb: 2 }}>
        <Typography variant="h6" gutterBottom>5. Información Contractual</Typography>
        <Grid container>
          {/* Agencia / Rango Salarial / Contrato */}
          <FormField>
            <Autocomplete
              freeSolo
              options={agencies}
              inputValue={editablePlayer.agency || ''}
              onInputChange={(event, newInputValue) => {
                setEditablePlayer((prev) => ({ ...prev, agency: newInputValue }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Agencia"
                  fullWidth
                />
              )}
            />
          </FormField>

          <FormField>
            <Autocomplete
              freeSolo
              options={priceRanges}
              inputValue={editablePlayer.price_range || ''}
              onInputChange={(event, newInputValue) => {
                setEditablePlayer((prev) => ({ ...prev, price_range: newInputValue }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Rango Salarial"
                  fullWidth
                />
              )}
            />
          </FormField>

          <FormField>
            <Autocomplete
              freeSolo
              options={contratos}
              inputValue={editablePlayer.contract || ''}
              onInputChange={(event, newInputValue) => {
                setEditablePlayer((prev) => ({ ...prev, contract: newInputValue }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Contrato"
                  fullWidth
                />
              )}
            />
          </FormField>
        </Grid>

        {/* Buyout (nouveau champ) */}
        <Grid container>
          <FormField>
            <Autocomplete
              freeSolo
              options={buyoutOptions}
              inputValue={editablePlayer.buyout || ''}
              onInputChange={(event, newInputValue) => {
                setEditablePlayer((prev) => ({ ...prev, buyout: newInputValue }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Buyout"
                  fullWidth
                />
              )}
            />
          </FormField>
        </Grid>
      </Paper>

      {/* 6. INFORMACIÓN ADICIONAL */}
      <Paper sx={{ p: 3, mb: 2 }}>
        <Typography variant="h6" gutterBottom>6. Información Adicional</Typography>
        <Grid container>
          {/* URL Estadística */}
          <FormField>
            <TextField
              label="URL Estadística"
              name="url"
              value={editablePlayer.url || ''}
              onChange={handleChange}
              fullWidth
            />
          </FormField>
        </Grid>
      </Paper>

      {/* Boutons de sauvegarde / annulation */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Button variant="contained" onClick={handleUpdate} sx={{ mr: 2 }}>
          Guardar cambios
        </Button>
        <Button variant="outlined" onClick={onCancel}>
          Cancelar
        </Button>
      </Box>

      {/* Affichage des erreurs éventuelles */}
      {errorModalOpen && (
        <Paper sx={{ p: 2, mt: 2 }}>
          <Typography color="error">{errorMessage}</Typography>
        </Paper>
      )}
    </Box>
  );
};

export default PlayerProfileEditor;
