// RechercheInforme.jsx

import React, { useEffect, useState, useRef, useContext } from 'react';
import { VariableSizeList } from 'react-window';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  IconButton,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  Grid,
  useTheme,
  useMediaQuery,
  SwipeableDrawer,
  Fab,
  Divider,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Tooltip,
  Autocomplete,
  Checkbox,
  MenuItem,
  CircularProgress,
  Pagination,           // <-- Pour la pagination mobile
  TablePagination,      // <-- Pour la pagination de la table en desktop
} from '@mui/material';

import { Link } from 'react-router-dom';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';

import VideocamIcon from '@mui/icons-material/Videocam';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';

import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import RechercheInformeContext from './RechercheInformeContext';

// === Configuration Autocomplete virtualisé ===
const LISTBOX_PADDING = 8;
function renderRow(props) {
  const { data, index, style } = props;
  const item = data[index];
  return React.cloneElement(item, {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return (
    <div
      ref={ref}
      {...props}
      {...outerProps}
      style={{
        ...props.style,
        overflowX: 'hidden',
        overflowY: 'scroll',
        maxWidth: '100%',
      }}
    />
  );
});

function useResetCache(data) {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = React.forwardRef((props, ref) => {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 48;

  const getChildSize = () => itemSize;

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemCount * itemSize;
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={getChildSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

// === Mapping des postes ===
const positionMappings = {
  Base: ['Base', 'Point Guard'],
  Escolta: ['Escolta', 'Shooting Guard', 'Guard'],
  Alero: ['Alero', 'Small Forward', 'Swingman'],
  'Ala-Pívot': ['Ala-Pívot', 'Power Forward', 'Power Forward / Center'],
  Pívot: ['Pívot', 'Center'],
};
const positionOptions = Object.keys(positionMappings);

// === Fonctions utilitaires ===
const getInitials = (nom, prenom) => {
  const firstInitial = nom ? nom[0] : '';
  const secondInitial = prenom ? prenom[0] : '';
  return (firstInitial + secondInitial).toUpperCase();
};

const getAvatarColor = (name) => {
  if (!name) return '#757575';
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = Math.abs(hash % 360);
  return `hsl(${hue}, 70%, 50%)`;
};

const getAvatarUrl = (userId) => {
  return userId
    ? `${process.env.REACT_APP_MEDIA_URL}/users/${userId}.png?${new Date().getTime()}`
    : '/path/to/default-avatar.png';
};

const getPlayerAvatarUrl = (playerId) => {
  return playerId
    ? `${process.env.REACT_APP_MEDIA_URL}/photos/${playerId}.png?${new Date().getTime()}`
    : '/path/to/default-player-avatar.png';
};

/**
 * Calcule l'âge en décimal d'un joueur à la date d'un informe donné.
 */
const getDecimalAge = (fecha, dob) => {
  if (!fecha || !dob) return '';
  const dateInforme = new Date(fecha);
  const dateNaissance = new Date(dob);

  const diffMs = dateInforme - dateNaissance;
  if (diffMs < 0) return '';
  const diffDays = diffMs / (1000 * 60 * 60 * 24);
  const diffYears = diffDays / 365;
  return diffYears.toFixed(1);
};

/**
 * Extrait l'abréviation de la nationalité si elle est entre parenthèses.
 */
const getShortNationality = (nat) => {
  if (!nat) return '';
  const match = nat.match(/\((.*?)\)/);
  return match ? match[1] : nat;
};

// === Composant mobile card ===
const MobileInformeCard = ({ informe, onEdit, onDelete, open, onToggle, user }) => {
  const theme = useTheme();
  const decimalAge = getDecimalAge(informe.fecha, informe.player_date_naissance);
  const shortNat = getShortNationality(informe.player_nationalite);

  return (
    <Card sx={{ mb: 2, boxShadow: theme.shadows[2] }}>
      <CardHeader
        avatar={
          <Avatar src={getPlayerAvatarUrl(informe.player_id)} alt={informe.player_nom} />
        }
        title={
          user?.role?.toLowerCase() === 'entrenador' || user?.role?.toLowerCase() === 'pdp'
            ? <Typography>{informe.player_nom}</Typography>
            : <Link
                to={`/players/${informe.player_id}`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                {informe.player_nom}
              </Link>
        }
        subheader={new Date(informe.fecha).toLocaleDateString()}
        action={
          <IconButton onClick={() => onToggle(informe.id)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        }
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <CardContent>
          <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
            <Avatar src={getAvatarUrl(informe.usuario_id)} sx={{ width: 24, height: 24 }} />
            <Typography variant="body2" color="text.secondary">
              {informe.usuario_prenom} {informe.usuario_nom}
            </Typography>
          </Box>

          <Typography variant="subtitle1" gutterBottom>
            <strong>Posición:</strong> {informe.player_poste}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            <strong>Edad:</strong> {informe.player_annee_naissance}
            {decimalAge ? ` (${decimalAge})` : ''}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            <strong>Nacionalidad:</strong> {shortNat}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            <strong>Equipo:</strong> {informe.equipo || 'N/A'}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            <strong>Evento:</strong> {informe.evento}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            <strong>Scouting:</strong>{' '}
            {informe.visu === 'video' ? 'Video' : informe.visu === 'live' ? 'Live' : ''}
          </Typography>

          <Typography
            variant="body2"
            component="div"
            sx={{
              backgroundColor: '#FFECB3',
              p: 2,
              borderRadius: 1,
              mt: 1,
              mb: 2,
            }}
            dangerouslySetInnerHTML={{ __html: informe.detalles }}
          />
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
          <Button size="small" startIcon={<EditIcon />} onClick={() => onEdit(informe)}>
            Editar
          </Button>
          <Button
            size="small"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => onDelete(informe)}
          >
            Eliminar
          </Button>
        </CardActions>
      </Collapse>
    </Card>
  );
};

// === Composant tiroir de filtres mobile ===
const MobileFilterDrawer = ({
  open,
  onClose,
  players,
  users,
  eventos,
  selectedPlayer,
  selectedUser,
  onPlayerChange,
  onUserChange,
  anneeNaissance,
  onAnneeNaissanceChange,
  selectedPositions,
  onPositionsChange,
  selectedEventos,
  onEventosChange,
}) => {
  const theme = useTheme();

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      sx={{
        '& .MuiDrawer-paper': {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          maxHeight: '80vh',
        },
      }}
    >
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">Filtros</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                options={players}
                getOptionLabel={(player) => player.nom}
                value={selectedPlayer}
                onChange={onPlayerChange}
                ListboxComponent={ListboxComponent}
                renderInput={(params) => (
                  <TextField {...params} label="Seleccionar Jugador" variant="outlined" />
                )}
                renderOption={(props, player) => (
                  <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      src={getPlayerAvatarUrl(player.id)}
                      alt={player.nom}
                      sx={{ mr: 1, width: 32, height: 32 }}
                    />
                    {player.nom}
                  </Box>
                )}
                clearOnEscape
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                options={users}
                getOptionLabel={(user) => `${user.prenom} ${user.nom}`}
                value={selectedUser}
                onChange={onUserChange}
                ListboxComponent={ListboxComponent}
                renderInput={(params) => (
                  <TextField {...params} label="Seleccionar Usuario" variant="outlined" />
                )}
                renderOption={(props, user) => (
                  <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      src={getAvatarUrl(user.id)}
                      alt={user.nom}
                      sx={{ mr: 1, width: 32, height: 32 }}
                    />
                    {user.prenom} {user.nom}
                  </Box>
                )}
                clearOnEscape
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Año de Nacimiento"
              variant="outlined"
              fullWidth
              value={anneeNaissance}
              onChange={onAnneeNaissanceChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={positionOptions}
              value={selectedPositions}
              onChange={onPositionsChange}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Seleccionar Posición" />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Checkbox checked={selectedPositions.indexOf(option) > -1} />
                  {option}
                </li>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              multiple
              freeSolo
              options={eventos}
              value={selectedEventos}
              onChange={onEventosChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Seleccionar Evento"
                  placeholder="Seleccione o escriba el evento"
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {option}
                </li>
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  );
};

// === Composant principal RechercheInforme ===
const RechercheInforme = ({ onDataLoaded }) => {
  const {
    // Filtres
    selectedPlayer,
    setSelectedPlayer,
    selectedUser,
    setSelectedUser,
    anneeNaissance,
    setAnneeNaissance,
    selectedPositions,
    setSelectedPositions,
    selectedEventos,
    setSelectedEventos,

    // openRows
    openRows,
    toggleRow,
  } = useContext(RechercheInformeContext);

  const [allInformeData, setAllInformeData] = useState([]);
  const [informeData, setInformeData] = useState([]);

  // Champs d'édition
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [currentInforme, setCurrentInforme] = useState(null);
  const [editedDetalles, setEditedDetalles] = useState('');
  const [editedDate, setEditedDate] = useState('');
  const [editedEvento, setEditedEvento] = useState('');
  const [editedEquipo, setEditedEquipo] = useState('');
  // Ajout pour scouting => visu
  const [editedVisu, setEditedVisu] = useState('');

  // Suppression
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [informeToDelete, setInformeToDelete] = useState(null);

  // Datas
  const [eventos, setEventos] = useState([]);
  const [players, setPlayers] = useState([]);
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  // --- ÉTATS POUR LA PAGINATION ---
  // Remarque: on met 5 pour rowsPerPage par défaut, vous pouvez mettre la valeur souhaitée
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setLoading(true);

        const [resInforme, resPlayers, resUsers, resTeams, resEventos] = await Promise.all([
          axios.get(`${backendUrl}/api/informe/all`, { params: { section } }),
          axios.get(`${backendUrl}/api/players/select`, { params: { section } }),
          axios.get(`${backendUrl}/api/users`, { params: { section } }),
          axios.get(`${backendUrl}/api/players/teams`, { params: { section } }),
          axios.get(`${backendUrl}/api/informe/eventos`, { params: { section } }),
        ]);

        let informes = resInforme.data || [];

        // Joueurs
        const _players = Array.isArray(resPlayers.data)
          ? resPlayers.data
          : resPlayers.data.players || [];
        // Users
        const _users = Array.isArray(resUsers.data)
          ? resUsers.data
          : resUsers.data.users || [];
        // Teams
        const teamsResponse = resTeams.data;
        const _teams = teamsResponse.teams || [];

        // User connecté
        const loggedUser = JSON.parse(localStorage.getItem('user'));
        setUser(loggedUser);

        // Filtrage selon le rôle
        if (loggedUser) {
          const roleLower = loggedUser.role?.toLowerCase();
          if (roleLower === 'entrenador' || roleLower === 'pdp') {
            informes = informes.filter((inf) => inf.usuario_id === loggedUser.id);
          } else if (roleLower === 'admin pro') {
            informes = informes.filter(
              (inf) =>
                inf.player_type?.toLowerCase() === 'pro' || inf.usuario_id === loggedUser.id
            );
          } else if (roleLower === 'admin cantera') {
            informes = informes.filter(
              (inf) =>
                inf.player_type?.toLowerCase() === 'cantera' || inf.usuario_id === loggedUser.id
            );
          }
        }

        setAllInformeData(informes);
        setInformeData(informes);
        setPlayers(_players);
        setUsers(_users);
        setTeams(_teams);
        setEventos(resEventos.data);

        if (onDataLoaded) {
          onDataLoaded(informes.length > 0);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchAllData();
  }, [backendUrl, section, onDataLoaded]);

  // === Filtrage local ===
  useEffect(() => {
    let filteredData = allInformeData;

    if (selectedPlayer) {
      filteredData = filteredData.filter((inf) => inf.player_id === selectedPlayer.id);
    }
    if (selectedUser) {
      filteredData = filteredData.filter((inf) => inf.usuario_id === selectedUser.id);
    }
    if (anneeNaissance) {
      filteredData = filteredData.filter(
        (inf) =>
          inf.player_annee_naissance &&
          inf.player_annee_naissance.toString().includes(anneeNaissance)
      );
    }
    if (selectedPositions.length > 0) {
      filteredData = filteredData.filter((inf) =>
        selectedPositions.some((posKey) => positionMappings[posKey].includes(inf.player_poste))
      );
    }
    if (selectedEventos.length > 0) {
      filteredData = filteredData.filter((inf) => selectedEventos.includes(inf.evento));
    }

    setInformeData(filteredData);
    // Quand on refiltre, on remet la page 0
    setPage(0);
  }, [
    allInformeData,
    selectedPlayer,
    selectedUser,
    anneeNaissance,
    selectedPositions,
    selectedEventos,
  ]);

  // === Pagination : découpe des données ===
  const totalResults = informeData.length;

  // Si rowsPerPage === -1, on affiche tout
  const paginatedData = React.useMemo(() => {
    if (rowsPerPage <= 0) {
      return informeData;
    }
    return informeData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [informeData, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Pour TablePagination (desktop)
  const handleChangeRowsPerPage = (event) => {
    const newVal = parseInt(event.target.value, 10);
    setRowsPerPage(newVal);
    setPage(0);
  };

  // === Pagination mobile ===
  // Ici, MUI <Pagination> est 1-based (la première page est 1),
  // donc on fait la conversion avec (value - 1) ou (page + 1).
  const handleChangeMobilePage = (event, newValue) => {
    // newValue = 1, 2, 3, ...
    setPage(newValue - 1);
  };

  // === Toggle row (détails) ===
  const handleToggleRow = (rowId) => {
    toggleRow(rowId);
  };

  // === Édition ===
  const handleEditClick = (informe) => {
    setCurrentInforme(informe);
    setEditedDetalles(informe.detalles || '');
    // Correction date => compenser le fuseau
    if (informe.fecha) {
      let dateObj = new Date(informe.fecha);
      dateObj = new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * 60000);
      const isoDate = dateObj.toISOString().split('T')[0];
      setEditedDate(isoDate);
    } else {
      setEditedDate('');
    }
    setEditedEvento(informe.evento || '');
    setEditedEquipo(informe.equipo || '');
    setEditedVisu(informe.visu || '');
    setOpenEditDialog(true);
  };

  const handleEditSave = async () => {
    try {
      const transformedDetalles = editedDetalles
        .replace(/<p><br><\/p>/g, '<br>')
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '<br>')
        .replace(/<br><br>/g, '<br>');

      const updateData = {
        detalles: transformedDetalles,
        fecha: editedDate,
        evento: editedEvento,
        equipo: editedEquipo,
        visu: editedVisu,
      };

      await axios.put(`${backendUrl}/api/informe/${currentInforme.id}`, updateData, {
        params: { section },
      });

      // Mettre à jour localement
      setAllInformeData((prevAll) =>
        prevAll.map((item) =>
          item.id === currentInforme.id
            ? {
                ...item,
                detalles: transformedDetalles,
                fecha: editedDate,
                evento: editedEvento,
                equipo: editedEquipo,
                visu: editedVisu,
              }
            : item
        )
      );

      // Recharger la liste des eventos (optionnel)
      try {
        const timestamp = new Date().getTime();
        const resEventos = await axios.get(`${backendUrl}/api/informe/eventos`, {
          params: { section, t: timestamp },
        });
        setEventos(resEventos.data);
      } catch (error) {
        console.error('Error reloading eventos:', error);
      }

      setOpenEditDialog(false);
      alert('Informe actualizado con éxito!');
    } catch (error) {
      console.error('Error al actualizar el informe:', error);
      alert('Error al actualizar el informe.');
    }
  };

  // === Suppression ===
  const handleDeleteClick = (informe) => {
    setInformeToDelete(informe);
    setOpenDeleteDialog(true);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`${backendUrl}/api/informe/${informeToDelete.id}`, {
        params: { section },
      });
      setAllInformeData((prevAll) => prevAll.filter((item) => item.id !== informeToDelete.id));
      setOpenDeleteDialog(false);
      alert('Informe eliminado con éxito!');
    } catch (error) {
      console.error('Error al eliminar el informe:', error);
      alert('Error al eliminar el informe.');
    }
  };

  const cancelDelete = () => {
    setOpenDeleteDialog(false);
    setInformeToDelete(null);
  };

  // === Rendu principal ===
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 8 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ position: 'relative', pb: isMobile ? 7 : 0 }}>
      {isMobile ? (
        <>
          {/* ---- Vue smartphone ---- */}
          <Box
            sx={{
              mx: 2,
              mt: 2,
              mb: 1,
              display: 'flex',
              alignItems: 'center',
              p: 2,
              bgcolor: theme.palette.primary.main,
              color: 'white',
              borderRadius: 1,
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            }}
          >
            <Box
              sx={{
                bgcolor: 'rgba(255, 255, 255, 0.2)',
                borderRadius: '50%',
                width: 40,
                height: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mr: 2,
                fontWeight: 'bold',
                fontSize: '1.1rem',
              }}
            >
              {totalResults}
            </Box>
            <Typography variant="subtitle1">
              {totalResults === 1 ? 'Informe encontrado' : 'Informes encontrados'}
            </Typography>
          </Box>

          <Box sx={{ p: 2 }}>
            {paginatedData.map((informe) => (
              <MobileInformeCard
                key={informe.id}
                informe={informe}
                user={user}
                open={openRows[informe.id] || false}
                onToggle={handleToggleRow}
                onEdit={handleEditClick}
                onDelete={handleDeleteClick}
              />
            ))}
          </Box>

          {/* Pagination mobile (seulement si rowsPerPage != -1) */}
          {rowsPerPage !== -1 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <Pagination
                color="primary"
                count={Math.ceil(totalResults / rowsPerPage)} // nombre total de pages
                page={page + 1} // base 1
                onChange={handleChangeMobilePage}
              />
            </Box>
          )}

          {/* Bouton de filtre mobile */}
          <Fab
            color="primary"
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              zIndex: theme.zIndex.fab,
            }}
            onClick={() => setOpenFilterDrawer(true)}
          >
            <FilterListIcon />
          </Fab>

          {/* Tiroir de filtres mobile */}
          <MobileFilterDrawer
            open={openFilterDrawer}
            onClose={() => setOpenFilterDrawer(false)}
            players={players}
            users={users}
            eventos={eventos}
            selectedPlayer={selectedPlayer}
            selectedUser={selectedUser}
            onPlayerChange={(_, newValue) => setSelectedPlayer(newValue)}
            onUserChange={(_, newValue) => setSelectedUser(newValue)}
            anneeNaissance={anneeNaissance}
            onAnneeNaissanceChange={(e) => setAnneeNaissance(e.target.value)}
            selectedPositions={selectedPositions}
            onPositionsChange={(_, newValues) => setSelectedPositions(newValues)}
            selectedEventos={selectedEventos}
            onEventosChange={(_, newValues) => setSelectedEventos(newValues)}
          />
        </>
      ) : (
        <>
          {/* ---- Vue desktop ---- */}
          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth>
                <Autocomplete
                  options={players}
                  getOptionLabel={(player) => player.nom}
                  value={selectedPlayer}
                  onChange={(_, newValue) => setSelectedPlayer(newValue)}
                  ListboxComponent={ListboxComponent}
                  renderInput={(params) => (
                    <TextField {...params} label="Seleccionar Jugador" variant="outlined" />
                  )}
                  renderOption={(props, player) => (
                    <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        src={getPlayerAvatarUrl(player.id)}
                        alt={player.nom}
                        sx={{ mr: 1 }}
                      />
                      {player.nom}
                    </Box>
                  )}
                  clearOnEscape
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={2}>
              <FormControl fullWidth>
                <Autocomplete
                  options={users}
                  getOptionLabel={(u) => `${u.prenom} ${u.nom}`}
                  value={selectedUser}
                  onChange={(_, newValue) => setSelectedUser(newValue)}
                  ListboxComponent={ListboxComponent}
                  renderInput={(params) => (
                    <TextField {...params} label="Seleccionar Usuario" variant="outlined" />
                  )}
                  renderOption={(props, u) => (
                    <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        src={getAvatarUrl(u.id)}
                        alt={u.nom}
                        sx={{ width: 30, height: 30, mr: 1 }}
                      />
                      {u.prenom} {u.nom}
                    </Box>
                  )}
                  clearOnEscape
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                label="Año de Nacimiento"
                variant="outlined"
                fullWidth
                value={anneeNaissance}
                onChange={(e) => setAnneeNaissance(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Autocomplete
                multiple
                options={positionOptions}
                value={selectedPositions}
                onChange={(_, newValues) => setSelectedPositions(newValues)}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Posición" />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option}
                  </li>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Autocomplete
                multiple
                freeSolo
                options={eventos}
                value={selectedEventos}
                onChange={(_, newValues) => setSelectedEventos(newValues)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Seleccionar Evento"
                    variant="outlined"
                    placeholder="Seleccione o escriba el evento"
                    fullWidth
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option}
                  </li>
                )}
              />
            </Grid>
          </Grid>

          <Box
            sx={{
              mb: 3,
              display: 'flex',
              alignItems: 'center',
              p: 2,
              bgcolor: theme.palette.primary.main,
              color: 'white',
              borderRadius: 1,
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            }}
          >
            <Box
              sx={{
                bgcolor: 'rgba(255, 255, 255, 0.2)',
                borderRadius: '50%',
                width: 48,
                height: 48,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mr: 2,
                fontWeight: 'bold',
                fontSize: '1.2rem',
              }}
            >
              {totalResults}
            </Box>
            <Typography variant="h6">
              {totalResults === 1 ? 'Informe encontrado' : 'Informes encontrados'}
            </Typography>
          </Box>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Creador</TableCell>
                  <TableCell align="center">Fecha</TableCell>
                  <TableCell>Jugador</TableCell>
                  <TableCell align="center">Posición</TableCell>
                  <TableCell align="center">Edad</TableCell>
                  <TableCell align="center">Nacionalidad</TableCell>
                  <TableCell align="center">Equipo</TableCell>
                  <TableCell align="center">Evento</TableCell>
                  <TableCell align="center">Scouting</TableCell>
                  <TableCell align="center">Detalles</TableCell>
                  <TableCell align="center">Acción</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map((row) => {
                  const decimalAge = getDecimalAge(row.fecha, row.player_date_naissance);
                  const shortNat = getShortNationality(row.player_nationalite);
                  return (
                    <React.Fragment key={row.id}>
                      <TableRow>
                        <TableCell align="center">
                          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Tooltip
                              arrow
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: 'white',
                                    '& .MuiTooltip-arrow': { color: 'white' },
                                    boxShadow: '0px 2px 8px rgba(0,0,0,0.15)',
                                  },
                                },
                              }}
                              title={
                                <Box sx={{ p: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
                                  <Avatar src={getAvatarUrl(row.usuario_id)} sx={{ width: 50, height: 50 }} />
                                  <Box>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'black' }}>
                                      {row.usuario_prenom} {row.usuario_nom}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: 'rgba(0,0,0,0.7)' }}>
                                      {row.usuario_nombre}
                                    </Typography>
                                  </Box>
                                </Box>
                              }
                            >
                              <Avatar
                                sx={{
                                  bgcolor: getAvatarColor(row.usuario_nom + row.usuario_prenom),
                                }}
                              >
                                {getInitials(row.usuario_prenom, row.usuario_nom)}
                              </Avatar>
                            </Tooltip>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {new Date(row.fecha).toLocaleDateString()}
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar
                              src={getPlayerAvatarUrl(row.player_id)}
                              alt={row.player_nom}
                              sx={{ mr: 1 }}
                            />
                            {user?.role?.toLowerCase() === 'entrenador' ||
                            user?.role?.toLowerCase() === 'pdp' ? (
                              <Typography variant="body2">{row.player_nom}</Typography>
                            ) : (
                              <Typography variant="body2">
                                <Link
                                  to={`/players/${row.player_id}`}
                                  style={{ textDecoration: 'none', color: 'inherit' }}
                                >
                                  {row.player_nom}
                                </Link>
                              </Typography>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell align="center">{row.player_poste}</TableCell>
                        <TableCell align="center">
                          {row.player_annee_naissance}
                          {decimalAge ? ` (${decimalAge})` : ''}
                        </TableCell>
                        <TableCell align="center">{shortNat}</TableCell>
                        <TableCell align="center">{row.equipo}</TableCell>
                        <TableCell align="center">{row.evento}</TableCell>
                        <TableCell align="center">
                          {row.visu === 'video' ? (
                            <Tooltip title="Video">
                              <VideocamIcon />
                            </Tooltip>
                          ) : row.visu === 'live' ? (
                            <Tooltip title="Live">
                              <PersonPinCircleIcon />
                            </Tooltip>
                          ) : (
                            ''
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton onClick={() => handleToggleRow(row.id)}>
                            {openRows[row.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell align="center">
                          <IconButton onClick={() => handleEditClick(row)} color="primary">
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={() => handleDeleteClick(row)} sx={{ color: 'red' }}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>

                      {/* Ligne détails */}
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
                          <Collapse in={openRows[row.id]} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                              <Typography
                                variant="body2"
                                style={{
                                  backgroundColor: '#FFECB3',
                                  padding: '10px',
                                  borderRadius: '4px',
                                }}
                                dangerouslySetInnerHTML={{ __html: row.detalles }}
                              />
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>

            {/* === TablePagination en pied de tableau (desktop) === */}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
              component="div"
              count={totalResults}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </>
      )}

      {/* Dialog édition */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} fullWidth maxWidth="md">
        <DialogTitle>Modificar el Informe</DialogTitle>
        <DialogContent>
          {currentInforme && (
            <Box sx={{ mt: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Fecha"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={editedDate}
                    onChange={(e) => setEditedDate(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    freeSolo
                    options={eventos}
                    value={editedEvento}
                    onChange={(_, newValue) => setEditedEvento(newValue || '')}
                    onInputChange={(event, newInputValue) => {
                      if (event && event.type === 'change') {
                        setEditedEvento(newInputValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Evento"
                        variant="outlined"
                        placeholder="Seleccione o escriba el evento"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    options={teams}
                    value={editedEquipo}
                    freeSolo
                    getOptionLabel={(option) => option || ''}
                    isOptionEqualToValue={(option, value) => option === value}
                    onChange={(_, newValue) => setEditedEquipo(newValue)}
                    renderOption={(props, option) => <li {...props}>{option}</li>}
                    renderInput={(params) => (
                      <TextField {...params} label="Equipo" variant="outlined" fullWidth />
                    )}
                  />
                </Grid>

                {/* Sélecteur scouting (visu) */}
                <Grid item xs={12} sm={4}>
                  <TextField
                    select
                    label="Scouting"
                    value={editedVisu}
                    onChange={(e) => setEditedVisu(e.target.value)}
                    fullWidth
                    sx={{ mt: 2 }}
                  >
                    <MenuItem value="video">Video</MenuItem>
                    <MenuItem value="live">Live</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
                    <strong>Detalles:</strong>
                  </Typography>
                  <ReactQuill
                    theme="snow"
                    value={editedDetalles}
                    onChange={setEditedDetalles}
                    placeholder="Modificar los detalles del informe..."
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleEditSave} color="primary" variant="contained">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog suppression */}
      <Dialog
        open={openDeleteDialog}
        onClose={cancelDelete}
        aria-labelledby="delete-confirmation-dialog"
      >
        <DialogTitle id="delete-confirmation-dialog">Eliminar Informe</DialogTitle>
        <DialogContent>
          <Typography>¿Está seguro de que desea eliminar este informe?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="secondary">
            Cancelar
          </Button>
          <Button onClick={confirmDelete} color="primary" variant="contained">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RechercheInforme;
