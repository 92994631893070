import React, { useEffect, useState } from 'react';
import { 
  Paper, Typography, Box, TextField, 
  Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, IconButton, Avatar,
  Collapse, CircularProgress, Alert,
  Autocomplete, Chip, Button,
  Dialog, DialogTitle, DialogContent,
  DialogContentText, DialogActions,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Fab,
  SwipeableDrawer,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';
import { 
  KeyboardArrowDown, 
  KeyboardArrowRight,
  Remove,
  Add,
  Close
} from '@mui/icons-material';
import axios from 'axios';
import { Link } from 'react-router-dom';

import FichaTecnificacion from '../components/FichaTecnificacion';
import EditableFichaTecnification from '../components/EditableFichaTecnification';

// Fonction utilitaire pour déterminer la couleur du texte basée sur la couleur de fond
const getContrastColor = (hexcolor) => {
  if (!hexcolor) return '#000000';
  const r = parseInt(hexcolor.slice(1, 3), 16);
  const g = parseInt(hexcolor.slice(3, 5), 16);
  const b = parseInt(hexcolor.slice(5, 7), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

// Composant pour la vue mobile d'un joueur
const PlayerCard = ({ player, mediaUrl, onToggle, isExpanded, onRemove, isPdp }) => {
  return (
    <Card sx={{ mb: 2 }}>
      <CardHeader
        avatar={
          <Avatar
            src={`${mediaUrl}/photos/${player.id}.png`}
            alt={player.nom}
            sx={{ width: 52, height: 52 }}
          />
        }
        title={
          isPdp ? (
            // Si le rôle est PDP, on n'affiche pas de lien
            <Typography>{player.nom}</Typography>
          ) : (
            // Sinon on affiche le lien
            <Link 
              to={`/players/${player.id}`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Typography sx={{ '&:hover': { textDecoration: 'underline' } }}>
                {player.nom}
              </Typography>
            </Link>
          )
        }
        subheader={new Date(player.date_entree).toLocaleDateString()}
        action={
          <IconButton onClick={() => onToggle(player.id)}>
            {isExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
        }
      />
      <CardContent>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {player.groupes?.map((groupe) => (
            <Chip
              key={groupe.id}
              label={groupe.libelle}
              size="small"
              style={{
                backgroundColor: groupe.code_couleur,
                color: getContrastColor(groupe.code_couleur)
              }}
            />
          ))}
        </Box>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          size="small"
          color="error"
          startIcon={<Remove />}
          onClick={() => onRemove(player)}
        >
          Retirar
        </Button>
      </CardActions>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <Box sx={{ px: 2, pb: 2 }}>
          <FichaTecnificacion 
            playerId={player.tecnification_player_id}
            onFichaAdded={null}
          />
          <EditableFichaTecnification 
            playerId={player.tecnification_player_id}
            refresh={false}
          />
        </Box>
      </Collapse>
    </Card>
  );
};

// Composant pour le tiroir d'ajout de joueur (mobile)
const AddPlayerDrawer = ({
  open,
  onClose,
  availablePlayers,
  mediaUrl,
  onAddPlayer
}) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      sx={{
        '& .MuiDrawer-paper': {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          maxHeight: '80vh'
        }
      }}
    >
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">Añadir jugador</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <List sx={{ pb: 2 }}>
          {availablePlayers.map((player) => (
            <ListItem 
              key={player.id}
              button
              onClick={() => {
                onAddPlayer(player);
                onClose();
              }}
            >
              <ListItemAvatar>
                <Avatar
                  src={`${mediaUrl}/photos/${player.id}.png`}
                  alt={player.nom}
                />
              </ListItemAvatar>
              <ListItemText 
                primary={player.nom}
                secondary={
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 0.5 }}>
                    {player.groupes.map((groupe) => (
                      <Chip
                        key={groupe.id}
                        label={groupe.libelle}
                        size="small"
                        style={{
                          backgroundColor: groupe.code_couleur,
                          color: getContrastColor(groupe.code_couleur)
                        }}
                      />
                    ))}
                  </Box>
                }
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </SwipeableDrawer>
  );
};

// Composant principal
const Tecnification = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const section = process.env.REACT_APP_SECTION;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Récupérer le rôle de l'utilisateur depuis localStorage
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const userRole = storedUser?.role || '';

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [availablePlayers, setAvailablePlayers] = useState([]);
  const [programPlayers, setProgramPlayers] = useState([]);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [playerToRemove, setPlayerToRemove] = useState(null);
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const [shouldRefreshFichas, setShouldRefreshFichas] = useState(false);

  const handleFichaAdded = async () => {
    try {
      const programResponse = await axios.get(`${backendUrl}/api/tecnification/players`, { params: { section } });
      setProgramPlayers(programResponse.data || []);
      setShouldRefreshFichas((prev) => !prev); // Met à jour les sous-composants si nécessaire
    } catch (error) {
      console.error("Erreur lors de la mise à jour des fiches :", error);
    }
  };
  
  // Charger les joueurs disponibles
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [availableResponse, programResponse] = await Promise.all([
          axios.get(`${backendUrl}/api/players/selectcantera`, { params: { section } }),
          axios.get(`${backendUrl}/api/tecnification/players`, { params: { section } })
        ]);
        
        setAvailablePlayers(availableResponse.data.players || []);
        setProgramPlayers(programResponse.data || []);
        setLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setError("No se pueden cargar los datos");
        setLoading(false);
      }
    };
    fetchData();
  }, [backendUrl, section]);

  const toggleRow = (id) => {
    const newExpandedRows = new Set(expandedRows);
    if (newExpandedRows.has(id)) {
      newExpandedRows.delete(id);
    } else {
      newExpandedRows.add(id);
    }
    setExpandedRows(newExpandedRows);
  };

  const handleAddPlayer = async (playerToAdd) => {
    if (!playerToAdd) return;
    try {
      await axios.post(`${backendUrl}/api/tecnification/players`, {
        player_id: playerToAdd.id,
        section
      });
      
      const [availableResponse, programResponse] = await Promise.all([
        axios.get(`${backendUrl}/api/players/selectcantera`, { params: { section } }),
        axios.get(`${backendUrl}/api/tecnification/players`, { params: { section } })
      ]);
      
      setAvailablePlayers(availableResponse.data.players || []);
      setProgramPlayers(programResponse.data || []);
      setSelectedPlayer(null);
    } catch (error) {
      console.error("Error al añadir jugador:", error);
      setError("No se puede añadir el jugador al programa");
    }
  };

  const handleRemovePlayer = async () => {
    if (!playerToRemove) return;
  
    try {
      await axios.put(
        `${backendUrl}/api/tecnification/players/${playerToRemove.tecnification_player_id}/deactivate`,
        { section },
        { params: { section } }
      );
      
      const [availableResponse, programResponse] = await Promise.all([
        axios.get(`${backendUrl}/api/players/selectcantera`, { params: { section } }),
        axios.get(`${backendUrl}/api/tecnification/players`, { params: { section } })
      ]);
      
      setAvailablePlayers(availableResponse.data.players || []);
      setProgramPlayers(programResponse.data || []);
      
      setOpenConfirmDialog(false);
      setPlayerToRemove(null);
    } catch (error) {
      console.error("Error al retirar jugador:", error);
      setError("No se puede retirar el jugador del programa");
      setOpenConfirmDialog(false);
      setPlayerToRemove(null);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error}
      </Alert>
    );
  }

  return (
    <Paper elevation={2}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
        Player Development Program VBC
        </Typography>

        {isMobile ? (
          // Vue mobile
          <>
            <Box sx={{ pb: 7 }}>
              {programPlayers.map((player) => (
                <PlayerCard
                  key={player.id}
                  player={player}
                  mediaUrl={mediaUrl}
                  isExpanded={expandedRows.has(player.id)}
                  onToggle={toggleRow}
                  onRemove={(player) => {
                    setPlayerToRemove(player);
                    setOpenConfirmDialog(true);
                  }}
                  // On passe la prop isPdp pour savoir s'il faut mettre un lien ou pas
                  isPdp={userRole === 'PDP'}
                />
              ))}
            </Box>

            <Fab
              color="primary"
              sx={{
                position: 'fixed',
                bottom: 16,
                right: 16,
                zIndex: theme.zIndex.fab
              }}
              onClick={() => setOpenAddDrawer(true)}
            >
              <Add />
            </Fab>

            <AddPlayerDrawer
              open={openAddDrawer}
              onClose={() => setOpenAddDrawer(false)}
              availablePlayers={availablePlayers}
              mediaUrl={mediaUrl}
              onAddPlayer={handleAddPlayer}
            />
          </>
        ) : (
          // Vue desktop 
          <>
            <Box sx={{ mb: 3 }}>
              <Autocomplete
                fullWidth
                options={availablePlayers}
                getOptionLabel={(player) => player?.nom || ''}
                value={selectedPlayer}
                onChange={(event, newValue) => {
                  if (newValue) {
                    handleAddPlayer(newValue);
                  }
                  setSelectedPlayer(newValue);
                }}
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    label="Añadir Jugador al PDP" 
                    variant="outlined" 
                  />
                )}
                renderOption={(props, player) => (
                  <Box 
                    component="li" 
                    {...props} 
                    sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                  >
                    <Avatar
                      src={`${mediaUrl}/photos/${player.id}.png`}
                      alt={player.nom}
                      sx={{ width: 34, height: 34 }}
                    />
                    <Typography>{player.nom}</Typography>
                    <Box sx={{ display: 'flex', gap: 0.5, ml: 'auto' }}>
                      {player.groupes.map((groupe) => (
                        <Chip
                          key={groupe.id}
                          label={groupe.libelle}
                          size="small"
                          style={{
                            backgroundColor: groupe.code_couleur,
                            color: getContrastColor(groupe.code_couleur)
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                )}
              />
            </Box>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '50px' }} />
                    <TableCell>Jugador</TableCell>
                    <TableCell>Grupos</TableCell>
                    <TableCell>Fecha de entrada</TableCell>
                    <TableCell align="right">Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {programPlayers.map((player) => (
                    <React.Fragment key={player.id}>
                      <TableRow hover>
                        <TableCell>
                          <IconButton size="small" onClick={() => toggleRow(player.id)}>
                            {expandedRows.has(player.id) ? 
                              <KeyboardArrowDown /> : 
                              <KeyboardArrowRight />
                            }
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Avatar
                              src={`${mediaUrl}/photos/${player.id}.png`}
                              alt={player.nom}
                              sx={{ width: 52, height: 52 }}
                            />

                            {userRole === 'PDP' ? (
                              <Typography>{player.nom}</Typography>
                            ) : (
                              <Link 
                                to={`/players/${player.id}`}
                                style={{ textDecoration: 'none', color: 'inherit' }}
                              >
                                <Typography sx={{ '&:hover': { textDecoration: 'underline' } }}>
                                  {player.nom}
                                </Typography>
                              </Link>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', gap: 0.5 }}>
                            {player.groupes?.map((groupe) => (
                              <Chip
                                key={groupe.id}
                                label={groupe.libelle}
                                size="small"
                                style={{
                                  backgroundColor: groupe.code_couleur,
                                  color: getContrastColor(groupe.code_couleur)
                                }}
                              />
                            ))}
                          </Box>
                        </TableCell>
                        <TableCell>
                          {new Date(player.date_entree).toLocaleDateString()}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            size="small"
                            color="error"
                            sx={{ 
                              '&:hover': { 
                                bgcolor: 'error.light'
                              },
                              bgcolor: 'error.lighter'
                            }}
                            onClick={() => {
                              setPlayerToRemove(player);
                              setOpenConfirmDialog(true);
                            }}
                          >
                            <Remove />
                          </IconButton>
                        </TableCell>
                      </TableRow>

                      {/* Ligne de détail (Collapse) */}
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                          <Collapse in={expandedRows.has(player.id)} timeout="auto" unmountOnExit>
                            <Box sx={{ m: 2 }}>
                              <FichaTecnificacion 
                                playerId={player.tecnification_player_id}
                                onFichaAdded={handleFichaAdded}
                              />
                              <EditableFichaTecnification 
                                playerId={player.tecnification_player_id}
                                refresh={shouldRefreshFichas}
                              />
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>

      {/* Dialog de confirmation */}
      <Dialog
        open={openConfirmDialog}
        onClose={() => {
          setOpenConfirmDialog(false);
          setPlayerToRemove(null);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Confirmar retirada del programa</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Está seguro que desea retirar a {playerToRemove?.nom} del programa ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              setOpenConfirmDialog(false);
              setPlayerToRemove(null);
            }}
          >
            Cancelar
          </Button>
          <Button 
            onClick={handleRemovePlayer} 
            color="error"
            variant="contained"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default Tecnification;
