// RechercheIntelContext.jsx

import React, { createContext, useState } from 'react';

const RechercheIntelContext = createContext();

export const RechercheIntelProvider = ({ children }) => {
  // === Filtres ===
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [anneeNaissance, setAnneeNaissance] = useState('');
  const [selectedPositions, setSelectedPositions] = useState([]);

  // === Ouverture des lignes (Collapses) ===
  const [openRows, setOpenRows] = useState({});

  // Toggle d'une ligne (ouvre/ferme la collapse)
  const toggleRow = (rowId) => {
    setOpenRows((prev) => ({ ...prev, [rowId]: !prev[rowId] }));
  };

  return (
    <RechercheIntelContext.Provider
      value={{
        // Filtres
        selectedPlayer,
        setSelectedPlayer,
        selectedUser,
        setSelectedUser,
        anneeNaissance,
        setAnneeNaissance,
        selectedPositions,
        setSelectedPositions,

        // openRows
        openRows,
        setOpenRows,
        toggleRow,
      }}
    >
      {children}
    </RechercheIntelContext.Provider>
  );
};

export default RechercheIntelContext;
