import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, Paper,
  CircularProgress, Alert,
  Card, CardContent,
  Grid,
  useTheme, useMediaQuery
} from '@mui/material';
import axios from 'axios';
import DOMPurify from 'dompurify';

const PDPTab = ({ playerId, onDataLoaded }) => {
  const [pdpData, setPdpData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const fetchPDPData = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/api/tecnification/player/${playerId}/fiches`,
          { params: { section } }
        );
        setPdpData(response.data);

        // Informe le parent si on a chargé au moins une fiche
        onDataLoaded(response.data.length > 0);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching PDP data:', error);
        setError('Error al cargar los datos del programa PDP');
        onDataLoaded(false);
        setLoading(false);
      }
    };

    fetchPDPData();
  }, [playerId, backendUrl, section, onDataLoaded]);

  const createMarkup = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  /**
   * VUE MOBILE
   * - Chaque entrée PDP est affichée dans une Card
   * - On affiche les champs : Objetivos, Implementar, Observaciones, Rutinas, Número de Sesiones
   */
  const MobileView = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {pdpData.map((entry) => (
        <Card key={entry.id} sx={{ width: '100%' }}>
          <CardContent>
            {/* En-tête de la carte : Date et Evaluador */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 2,
                pb: 1,
                borderBottom: 1,
                borderColor: 'divider',
              }}
            >
              <Typography variant="subtitle1" sx={{ color: '#FF8C00' }}>
                {new Date(entry.fecha).toLocaleDateString()}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {entry.evaluador}
              </Typography>
            </Box>

            {/* Objetivos */}
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 'bold', color: '#FF8C00', mb: 1 }}
              >
                Objetivos
              </Typography>
              <div dangerouslySetInnerHTML={createMarkup(entry.objetivos)} />
            </Box>

            {/* Implementar */}
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 'bold', color: '#FF8C00', mb: 1 }}
              >
                Implementar
              </Typography>
              <div dangerouslySetInnerHTML={createMarkup(entry.implementar)} />
            </Box>

            {/* Observaciones (optionnel si non vide) */}
            {entry.observaciones && (
              <Box sx={{ mb: 2 }}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 'bold', color: '#FF8C00', mb: 1 }}
                >
                  Observaciones
                </Typography>
                <div
                  dangerouslySetInnerHTML={createMarkup(entry.observaciones)}
                />
              </Box>
            )}

            {/* Rutinas (optionnel si non vide) */}
            {entry.routinas && (
              <Box sx={{ mb: 2 }}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 'bold', color: '#FF8C00', mb: 1 }}
                >
                  Rutinas
                </Typography>
                <div dangerouslySetInnerHTML={createMarkup(entry.routinas)} />
              </Box>
            )}

            {/* Número de Sesiones (optionnel si non vide) */}
            {entry.numero_sesiones && (
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 'bold', color: '#FF8C00', mb: 1 }}
                >
                  Número de Sesiones
                </Typography>
                <Typography>{entry.numero_sesiones}</Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      ))}
    </Box>
  );

  /**
   * VUE DESKTOP
   * - Chaque entrée PDP est un Paper avec un en-tête coloré
   * - On remplace le tableau 5 colonnes par une grille pour être plus responsive.
   */
  const DesktopView = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      {pdpData.map((entry) => (
        <Paper key={entry.id} sx={{ overflow: 'hidden' }}>
          {/* En-tête avec date et évaluateur */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#FF8C00',
              p: 1.5,
              color: 'white',
            }}
          >
            <Typography sx={{ fontWeight: 'medium' }}>
              {new Date(entry.fecha).toLocaleDateString()}
            </Typography>
            <Typography>{entry.evaluador}</Typography>
          </Box>

          {/* On crée une grille responsive pour afficher nos champs
              2 colonnes par ligne, et on affiche 
              - Objetivos / Implementar
              - Observaciones / Rutinas
              - Número de Sesiones  (sur une ligne entière ou 1 col si on préfère)
          */}
          <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
              {/* Objetivos */}
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
                  Objetivos
                </Typography>
                <div dangerouslySetInnerHTML={createMarkup(entry.objetivos)} />
              </Grid>

              {/* Implementar */}
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
                  Implementar
                </Typography>
                <div dangerouslySetInnerHTML={createMarkup(entry.implementar)} />
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              {/* Observaciones */}
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
                  Observaciones
                </Typography>
                <div dangerouslySetInnerHTML={createMarkup(entry.observaciones)} />
              </Grid>

              {/* Rutinas */}
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
                  Rutinas
                </Typography>
                <div dangerouslySetInnerHTML={createMarkup(entry.routinas)} />
              </Grid>
            </Grid>

            {/* Número de Sesiones (on peut l'afficher seul sur une ligne) */}
            {entry.numero_sesiones && (
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Número de Sesiones
                  </Typography>
                  <Typography>{entry.numero_sesiones}</Typography>
                </Grid>
              </Grid>
            )}
          </Box>
        </Paper>
      ))}
    </Box>
  );

  /**
   * État de chargement
   */
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress sx={{ color: '#FF8C00' }} />
      </Box>
    );
  }

  /**
   * Gestion d'erreur
   */
  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error}
      </Alert>
    );
  }

  /**
   * Aucune fiche trouvée
   */
  if (!pdpData || pdpData.length === 0) {
    return (
      <Alert severity="info" sx={{ m: 2 }}>
        No hay datos PDP disponibles para este jugador
      </Alert>
    );
  }

  /**
   * Rendu final : vue mobile ou desktop en fonction du breakpoint
   */
  return (
    <Box sx={{ p: 2 }}>
      {isMobile ? <MobileView /> : <DesktopView />}
    </Box>
  );
};

export default PDPTab;
