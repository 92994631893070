import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Avatar, Paper, Grid, Checkbox,
  IconButton, Button, TablePagination, TextField, FormControl, InputLabel, Select, MenuItem, ListItemText, Slider, Chip,
  Switch
} from '@mui/material';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import { SeguimientoContext } from '../SeguimientoContext';

const Seguimiento = () => {
  const [players, setPlayers] = useState([]);
  const { seguimientoPlayers, setSeguimientoPlayers } = useContext(SeguimientoContext);

  const [page, setPage] = useState(0);
  const rowsPerPage = 50;

  // -- FILTRES --
  const [nameFilter, setNameFilter] = useState('');
  const [teamFilter, setTeamFilter] = useState('');
  const [positionFilter, setPositionFilter] = useState([]);
  const [heightFilter, setHeightFilter] = useState([130, 230]);
  const [ageFilter, setAgeFilter] = useState([12, 45]);
  const [uniqueLeagues, setUniqueLeagues] = useState([]);
  const [leagueFilter, setLeagueFilter] = useState([]);

  // -- PLAYER TYPE (Pro / Cantera) --
  const [playerType, setPlayerType] = useState('Pro'); // Valeur par défaut

  // -- RÉCUP ROLE D'UTILISATEUR --
  const userData = JSON.parse(localStorage.getItem('user'));
  const userRole = userData?.role?.toLowerCase() || '';

  // -- TAGS & SEGUIMIENTO --
  // Au lieu d'un seul state pour seguimientoName, on ajoute un Select permettant
  // de choisir un nom prédéfini ou "Otro". Si "Otro", l'utilisateur saisit un texte libre.
  const [selectedSeguimientoNameOption, setSelectedSeguimientoNameOption] = useState('');
  const [seguimientoName, setSeguimientoName] = useState('');

  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  // -- AUTRES --
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  // -- ENV --
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Mappage des positions (pour le filtre)
  const positionMappings = {
    Base: ['Base', 'Point Guard'],
    Escolta: ['Escolta', 'Shooting Guard', 'Guard'],
    Alero: ['Alero', 'Small Forward', 'Swingman'],
    'Ala-Pívot': ['Ala-Pívot', 'Power Forward', 'Power Forward / Center'],
    Pívot: ['Pívot', 'Center'],
  };

  // 1) GESTION DU RÔLE ET DU PLAYER TYPE (Pro / Cantera)
  useEffect(() => {
    if (userRole === 'super admin') {
      // superadmin => switch libre
      return;
    } else if (userRole === 'admin pro') {
      setPlayerType('Pro');
    } else {
      // admin cantera, entrenador, pdp => Cantera
      setPlayerType('Cantera');
    }
  }, [userRole]);

  // 2) CHARGEMENT DES FILTRES DEPUIS L'URL (premier rendu)
  useEffect(() => {
    if (isInitialLoad) {
      const params = new URLSearchParams(location.search);
      setNameFilter(params.get('nameFilter') || '');
      setTeamFilter(params.get('teamFilter') || '');
      setPositionFilter(
        params.get('positionFilter') ? params.get('positionFilter').split(',') : []
      );
      setLeagueFilter(
        params.get('leagueFilter') ? params.get('leagueFilter').split(',') : []
      );
      setHeightFilter(
        params.get('heightFilter')
          ? params.get('heightFilter').split(',').map(Number)
          : [130, 230]
      );
      setAgeFilter(
        params.get('ageFilter')
          ? params.get('ageFilter').split(',').map(Number)
          : [12, 45]
      );
      setPage(params.get('page') ? parseInt(params.get('page'), 10) : 0);

      setIsInitialLoad(false);
    }
  }, [location.search, isInitialLoad]);

  // 3) MISE À JOUR DE L'URL QUAND LES FILTRES CHANGENT
  useEffect(() => {
    if (!isInitialLoad) {
      const params = new URLSearchParams();
      if (nameFilter) params.set('nameFilter', nameFilter);
      if (teamFilter) params.set('teamFilter', teamFilter);
      if (positionFilter.length) params.set('positionFilter', positionFilter.join(','));
      if (leagueFilter.length) params.set('leagueFilter', leagueFilter.join(','));
      if (heightFilter.length) params.set('heightFilter', heightFilter.join(','));
      if (ageFilter.length) params.set('ageFilter', ageFilter.join(','));
      if (page) params.set('page', page);

      const newUrl = `${location.pathname}?${params.toString()}`;
      if (location.search !== `?${params.toString()}`) {
        navigate(newUrl, { replace: true });
      }
    }
  }, [
    nameFilter,
    teamFilter,
    positionFilter,
    leagueFilter,
    heightFilter,
    ageFilter,
    page,
    isInitialLoad,
    location.pathname,
    navigate
  ]);

  // 4) RÉCUPÉRATION DES JOUEURS (API)
  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players?section=${section}`);
        const allPlayers = response.data.players;

        // Récupérer les ligues uniques
        const leagues = Array.from(
          new Set(
            allPlayers
              .map((p) => p.league_1)
              .filter(
                (league) =>
                  league !== null &&
                  league !== undefined &&
                  typeof league === 'string' &&
                  league.trim() !== '' &&
                  league.trim() !== 'null'
              )
          )
        ).sort();
        setUniqueLeagues(leagues);

        // Exclure les joueurs déjà en suivi
        const seguimientoPlayerIds = seguimientoPlayers.map((p) => p.id);
        const availablePlayers = allPlayers.filter(
          (p) => !seguimientoPlayerIds.includes(p.id)
        );

        setPlayers(availablePlayers);
      } catch (error) {
        console.error('Erreur lors de la récupération des joueurs :', error);
      }
    };
    fetchPlayers();
  }, [backendUrl, section, seguimientoPlayers]);

  // 5) RÉCUPÉRATION DES TAGS (API)
  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/tags`, { params: { section } });
        setTags(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des tags :', error);
      }
    };
    fetchTags();
  }, [backendUrl, section]);

  // 6) FONCTION POUR CALCULER L'ÂGE
  const calculateAge = (anneeNaissance) => {
    const currentYear = new Date().getFullYear();
    return currentYear - anneeNaissance;
  };

  // 7) FILTRAGE PAR TYPE, NOM, ÉQUIPE, ETC.
  const filteredPlayers = players.filter((player) => {
    // Filtre par type
    if (!player.type || player.type.toLowerCase() !== playerType.toLowerCase()) {
      return false;
    }

    const playerAge = calculateAge(player.annee_naissance);
    const searchTermName = nameFilter.toLowerCase();
    const searchTermTeam = teamFilter.toLowerCase();

    // Filtre de position avec équivalences
    const positionMatches =
      positionFilter.length === 0 ||
      positionFilter.some((pos) =>
        positionMappings[pos].some(
          (mappedPos) => player.poste?.toLowerCase() === mappedPos.toLowerCase()
        )
      );

    return (
      player.nom.toLowerCase().includes(searchTermName) &&
      (!searchTermTeam ||
        (player.team && player.team.toLowerCase().includes(searchTermTeam))) &&
      positionMatches &&
      (leagueFilter.length === 0 || leagueFilter.includes(player.league_1)) &&
      player.taille >= heightFilter[0] &&
      player.taille <= heightFilter[1] &&
      playerAge >= ageFilter[0] &&
      playerAge <= ageFilter[1]
    );
  });

  // 8) PAGINATION
  const getCurrentPagePlayers = () => {
    return filteredPlayers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  // 9) AJOUT / RETRAIT JOUEUR EN SUIVI
  const movePlayerToSeguimiento = (player) => {
    if (!seguimientoPlayers.some((p) => p.id === player.id)) {
      const updated = [...seguimientoPlayers, player].sort((a, b) => a.nom.localeCompare(b.nom));
      setSeguimientoPlayers(updated);
      setPlayers(players.filter((p) => p.id !== player.id));
    }
  };

  const removePlayerFromSeguimiento = (player) => {
    setSeguimientoPlayers(seguimientoPlayers.filter((p) => p.id !== player.id));
    // On remet le joueur parmi les 'players', trié par nom
    const updated = [...players, player].sort((a, b) => a.nom.localeCompare(b.nom));
    setPlayers(updated);
  };

  // 10) DROIT DE VOIR LA FICHE JOUEUR OU NON
  const canViewPlayer = !(userRole === 'entrenador' || userRole === 'pdp');

  const handleViewPlayer = (player) => {
    if (canViewPlayer) {
      navigate(`/players/${player.id}`);
    }
  };

  // 11) ENREGISTREMENT DU SUIVI
  const handleSaveSeguimiento = async () => {
    if (!seguimientoName) {
      alert('Por favor, introduce un nombre para el Seguimiento.');
      return;
    }
    if (seguimientoPlayers.length === 0) {
      alert('Por favor, añade jugador@s al Seguimiento.');
      return;
    }
    if (selectedTags.length === 0) {
      alert('Por favor, selecciona al menos un tag para el Seguimiento.');
      return;
    }

    try {
      const userData = JSON.parse(localStorage.getItem('user'));
      const userId = userData?.id;
      if (!userId) {
        alert('Error: ID de usuario no disponible.');
        return;
      }

      await axios.post(`${backendUrl}/api/seguimiento`, {
        nom: seguimientoName,
        tags: selectedTags,
        players: seguimientoPlayers.map((p) => p.id),
        user_id: userId,
        section,
        // On envoie aussi le type Pro / Cantera
        type: playerType,
      });

      alert('Seguimiento guardado con éxito.');

      // Reset
      setSelectedSeguimientoNameOption('');
      setSeguimientoName('');
      setSelectedTags([]);
      setSeguimientoPlayers([]);
    } catch (error) {
      console.error('Error al guardar el Seguimiento.', error);
      alert('Error al guardar el Seguimiento.');
    }
  };

  // 12) GESTION DU SELECT POUR "Nombre del Seguimiento"
  const handleSeguimientoNameChange = (event) => {
    const value = event.target.value;
    setSelectedSeguimientoNameOption(value);
    if (value !== 'Otro') {
      setSeguimientoName(value);
    } else {
      setSeguimientoName('');
    }
  };

  // -- Composants de tableau / DND --

  // Joueur disponible (liste de gauche)
  const PlayerRow = ({ player }) => {
    const [{ isDragging }, dragRef] = useDrag({
      type: 'player',
      item: player,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const isInSeguimiento = seguimientoPlayers.some((p) => p.id === player.id);

    const handleCheckboxChange = (e) => {
      if (e.target.checked) {
        movePlayerToSeguimiento(player);
      } else {
        removePlayerFromSeguimiento(player);
      }
    };

    return (
      <TableRow
        ref={dragRef}
        key={player.id}
        style={{ opacity: isDragging ? 0.5 : 1 }}
        sx={{
          cursor: 'move',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        }}
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={isInSeguimiento}
            onChange={handleCheckboxChange}
            onClick={(e) => e.stopPropagation()}
          />
        </TableCell>
        <TableCell>
          <Avatar
            src={`${mediaUrl}/photos/${player.id}.png`}
            alt={player.nom}
            sx={{ width: 40, height: 40 }}
          />
        </TableCell>
        <TableCell>
          {canViewPlayer ? (
            <Box
              component="a"
              href={`/players/${player.id}`}
              onClick={(e) => {
                e.preventDefault();
                handleViewPlayer(player);
              }}
              sx={{
                color: 'black',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                  cursor: 'pointer',
                },
              }}
            >
              {player.nom}
            </Box>
          ) : (
            <span>{player.nom}</span>
          )}
        </TableCell>
        <TableCell>{player.annee_naissance}</TableCell>
        <TableCell>{player.taille}</TableCell>
        <TableCell>{player.poste}</TableCell>
        <TableCell>{player.team || '-'}</TableCell>
        <TableCell>{player.league_1 || '-'}</TableCell>
      </TableRow>
    );
  };

  // Joueur déjà en suivi (colonne de droite)
  const SeguimientoPlayer = ({ player }) => {
    return (
      <Box display="flex" alignItems="center" mb={2}>
        <Avatar src={`${mediaUrl}/photos/${player.id}.png`} alt={player.nom} />
        <Typography style={{ marginLeft: '10px' }}>
          {canViewPlayer ? (
            <Box
              component="a"
              href={`/players/${player.id}`}
              onClick={(e) => {
                e.preventDefault();
                handleViewPlayer(player);
              }}
              sx={{
                color: 'inherit',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                  cursor: 'pointer',
                },
              }}
            >
              {player.nom}
            </Box>
          ) : (
            <span>{player.nom}</span>
          )}
        </Typography>
        <IconButton
          onClick={() => removePlayerFromSeguimiento(player)}
          style={{ marginLeft: 'auto' }}
        >
          <DeleteIcon color="error" />
        </IconButton>
      </Box>
    );
  };

  // Zone de dépôt (colonne de droite)
  const SeguimientoDropZone = () => {
    const [{ isOver }, dropRef] = useDrop({
      accept: 'player',
      drop: (player) => movePlayerToSeguimiento(player),
      collect: (monitor) => ({ isOver: monitor.isOver() }),
    });

    return (
      <Paper
        ref={dropRef}
        style={{
          minHeight: '400px',
          backgroundColor: isOver ? '#e0f7fa' : '#f5f5f5',
          padding: '20px',
        }}
      >
        <Typography variant="h6">Seguimiento</Typography>
        {seguimientoPlayers.length === 0 && <p>Arrastra jugador@s aquí.</p>}
        {seguimientoPlayers.map((player) => (
          <SeguimientoPlayer key={player.id} player={player} />
        ))}
      </Paper>
    );
  };

  // 13) RENDU FINAL
  return (
    <DndProvider backend={HTML5Backend}>
      <Box sx={{ p: 2 }}>
        {/* Interrupteur Pro / Cantera visible seulement pour superadmin */}
        {userRole === 'super admin' && (
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Typography>Cantera</Typography>
            <Switch
              checked={playerType === 'Pro'}
              onChange={() => setPlayerType(playerType === 'Pro' ? 'Cantera' : 'Pro')}
            />
            <Typography>Pro</Typography>
          </Box>
        )}

        <Box sx={{ mt: 4, px: 4 }}>
          {/* Filtres par nom et équipe */}
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Filtrar por nombre"
                variant="outlined"
                value={nameFilter}
                onChange={(e) => setNameFilter(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Filtrar por equipo"
                variant="outlined"
                value={teamFilter}
                onChange={(e) => setTeamFilter(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>

          {/* Filtres par position et ligue */}
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="position-filter-label">Filtrar por Posición</InputLabel>
                <Select
                  labelId="position-filter-label"
                  multiple
                  value={positionFilter}
                  onChange={(e) => setPositionFilter(e.target.value)}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {Object.entries(positionMappings).map(([position, equivalents]) => (
                    <MenuItem key={position} value={position}>
                      <Checkbox checked={positionFilter.indexOf(position) > -1} />
                      <ListItemText
                        primary={position}
                        secondary={equivalents.slice(1).join(', ')}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="league-filter-label">Filtrar por liga</InputLabel>
                <Select
                  labelId="league-filter-label"
                  multiple
                  value={leagueFilter}
                  onChange={(e) => setLeagueFilter(e.target.value)}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {uniqueLeagues.map((league) => (
                    <MenuItem key={league} value={league}>
                      <Checkbox checked={leagueFilter.indexOf(league) > -1} />
                      <ListItemText primary={league} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Sliders pour la taille et l'âge */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: isSmallScreen ? 'column' : 'row',
              gap: 5,
              mb: 3,
            }}
          >
            <Box sx={{ width: isSmallScreen ? '100%' : '50%' }}>
              <Typography gutterBottom sx={{ textAlign: 'center' }}>
                Filtrar por altura (130 a 230 cm)
              </Typography>
              <Slider
                value={heightFilter}
                onChange={(e, newValue) => setHeightFilter(newValue)}
                valueLabelDisplay="auto"
                min={130}
                max={230}
                sx={{ color: '#ff9800' }}
              />
            </Box>

            <Box sx={{ width: isSmallScreen ? '100%' : '50%' }}>
              <Typography gutterBottom sx={{ textAlign: 'center' }}>
                Filtrar por edad (12 a 45 años)
              </Typography>
              <Slider
                value={ageFilter}
                onChange={(e, newValue) => setAgeFilter(newValue)}
                valueLabelDisplay="auto"
                min={12}
                max={45}
                sx={{ color: '#ff9800' }}
              />
            </Box>
          </Box>

          {/* Configuration du Seguimiento : Select + TextField si "Otro" */}
          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="seguimiento-name-label">Nombre del Seguimiento</InputLabel>
                <Select
                  labelId="seguimiento-name-label"
                  value={selectedSeguimientoNameOption}
                  onChange={handleSeguimientoNameChange}
                  label="Nombre del Seguimiento"
                >
                  <MenuItem value="">--Seleccione--</MenuItem>
                  <MenuItem value="Base">Base</MenuItem>
                  <MenuItem value="Escolta">Escolta</MenuItem>
                  <MenuItem value="Alero">Alero</MenuItem>
                  <MenuItem value="Ala-Pívot">Ala-Pívot</MenuItem>
                  <MenuItem value="Pívot">Pívot</MenuItem>
                  <MenuItem value="Otro">Otro</MenuItem>
                </Select>
              </FormControl>

              {selectedSeguimientoNameOption === 'Otro' && (
                <TextField
                  label="Escribe otro nombre"
                  variant="outlined"
                  value={seguimientoName}
                  onChange={(e) => setSeguimientoName(e.target.value)}
                  fullWidth
                  sx={{ mt: 2 }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="tags-select-label">Seleccionar Tags</InputLabel>
                <Select
                  labelId="tags-select-label"
                  multiple
                  value={selectedTags}
                  onChange={(e) => setSelectedTags(e.target.value)}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((tagId) => {
                        const tag = tags.find((t) => t.id === tagId);
                        return (
                          <Chip
                            key={tagId}
                            label={tag.libelle}
                            sx={{
                              backgroundColor: tag.code_couleur,
                              color: 'white',
                            }}
                          />
                        );
                      })}
                    </Box>
                  )}
                >
                  {tags.map((tag) => (
                    <MenuItem key={tag.id} value={tag.id}>
                      <Checkbox checked={selectedTags.indexOf(tag.id) > -1} />
                      <Chip
                        label={tag.libelle}
                        sx={{ backgroundColor: tag.code_couleur, color: 'white', mr: 1 }}
                      />
                      <ListItemText primary={tag.libelle} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Bouton enregistrer */}
          <Button
            variant="contained"
            sx={{ backgroundColor: '#ff9800', color: 'white', mb: 4 }}
            onClick={handleSaveSeguimiento}
          >
            Guardar Seguimiento
          </Button>

          {/* Contenu principal : tableau (gauche) + dropzone (droite) */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox disabled />
                      </TableCell>
                      <TableCell>Avatar</TableCell>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Año de nacimiento</TableCell>
                      <TableCell>Altura (cm)</TableCell>
                      <TableCell>Posición</TableCell>
                      <TableCell>Equipo</TableCell>
                      <TableCell>Liga</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getCurrentPagePlayers().map((player) => (
                      <PlayerRow key={player.id} player={player} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                component="div"
                count={filteredPlayers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                labelRowsPerPage="Filas por página"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <SeguimientoDropZone />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DndProvider>
  );
};

export default Seguimiento;
