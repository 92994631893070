import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Clear } from '@mui/icons-material';
import {
  Tabs, 
  Tooltip,
  Tab, 
  Box, 
  Avatar,
  Typography,
  Chip,
  Paper,
  IconButton,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Grid,
  Drawer,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  CircularProgress,
  Alert,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery
} from '@mui/material';

import {
  ArrowBack,
  Edit as EditIcon,
  Close as CloseIcon,
  Save as SaveIcon,
  PhotoCamera,
  Image as ImageIcon,
  Edit,
  OpenInNew
} from '@mui/icons-material';

import axios from 'axios';

// === IMPORTS de vos composants ===
import InformeTab from '../components/InformeTab';
import IntelTab from '../components/IntelTab';
import TechnicaTab from '../components/TechnicaTab';
import PDPTab from '../components/PDPTab';
import SelectSelectionNational from '../components/SelectSelectionNational';
import NationalitiesSelect from '../components/NationalitiesSelect';
import NationalitiesSelect2 from '../components/NationalitiesSelect2';


// === Rôles et attributs (comme dans CrearJugador) ===
const offensiveRoles = [
  // Guard
  "Generador con balón",
  "Generador secundario con balón",
  "Anotador Dominante",
  "Base Director",
  "Tirador Especialista",

  // Wing
  "Alero Generador",
  "Ala Versátil",
  "Alero Fuerte",
  "Alero Slasher",
  "Alero Spot-up",
  "3&D",

  // Forward
  "Ala-Pívot Tradicional",
  "Skilled Forward",
  "4 Abierto",

  // Big
  "Pívot Generador",
  "Pívot Versátil",
  "Pívot Mid-Range",
  "5 Abierto",
  "Anotador en el poste bajo",
  "Pívot finalizador cerca del aro",
  "Pívot de bloqueo y continuación",
  "Rol Ofensivo Marginal"
];

const defensiveRoles = [
  "1x1 Intenso",
  "Defensor Perimetral", 
  "Wing Stopper",
  "Switch Defensor",
  "Anchor Big",
  "Reboteador Defensivo",
  "Rim Protector",
  "No Defensor"
];

const JEAttributes = [
  "1x1",
  "3-Level Scorer",
  "Actividad sin balón",
  "Atlético",
  "Capacidad en el Pick & Roll",
  "Capacidad para sacar faltas",
  "Clutch",
  "Duro",
  "Energía",
  "Envergadura",
  "Físico",
  "Hustle",
  "IQ/Visión de juego",
  "Juego en el poste bajo",
  "Paint Touches",
  "Penetrador",
  "Puntos",
  "Primer paso",
  "Producción constante",
  "Rebote",
  "Talento",
  "Tirador",
  "Tiro en Catch & Shoot (OTC 3)",
  "Tiro desde la esquina (Corner 3)",
  "Tiro tras Bote",
  "Transiciones/Ritmo en la pista",
  "Trabajo sucio (Blue Collar)",
  "Velocidad/Agilidad",
  "Versatilidad",
  "Verticalidad"
];

const JIAttributes = [
  "1x1",
  "Actividad sin balón",
  "Atlético",
  "Bloqueo, continuación y finalización",
  "Bloqueos eficaces",
  "Buen tacto",
  "Clutch",
  "Duro",
  "Energía",
  "Envergadura",
  "Footwork",
  "Físico",
  "Hustle",
  "IQ/Visión de juego",
  "Juego poste bajo/Juego de cara",
  "Lob Threat",
  "Puntos",
  "Producción constante",
  "Protector del aro",
  "Rebote",
  "Rim-Runner",
  "Talento",
  "Tirador",
  "Tiro en Catch & Shoot (OTC 3)",
  "Tiro desde la esquina + P&Pop",
  "Tiro tras bote",
  "Trabajo sucio (Blue Collar)",
  "Transiciones/Ritmo en la pista",
  "Varios esfuerzos en la misma acción",
  "Velocidad/Agilidad",
  "Versatilidad",
  "Verticalidad"
];

const priceRanges = [
  "0-50k€",
  "50-100k€",
  "100-150k€",
  "150-200k€",
  "200-250k€",
  "250-300k€",
  "300-350k€",
  "350-400k€",
  "400-450k€",
  "450-500k€",
  "500-550k€",
  "550-600k€",
  "600-650k€",
  "650-700k€",
  "700-750k€",
  "750-800k€",
  "800-850k€",
  "850-900k€",
  "900-950k€",
  "950k€-1M€",
  "1M€-1.2M€",
  "1.2M€-1.4M€",
  "1.4M€-1.6M€",
  "1.6M€-1.8M€",
  "1.8M€-2M€",
  "2M€-2.2M€",
  "2.2M€-2.4M€",
  "2.4M€-2.5M€"
];

 // Par exemple, vous pouvez définir vos options pour "Buyout" :
 const buyoutOptions = [
  "0-50k€",
  "50-100k€",
  "100-150k€",
  "150-200k€",
  "200-250k€",
  "250-300k€",
  "300-350k€",
  "350-400k€",
  "400-450k€",
  "450-500k€",
  "500-550k€",
  "550-600k€",
  "600-650k€",
  "650-700k€",
  "700-750k€",
  "750-800k€",
  "800-850k€",
  "850-900k€",
  "900-950k€",
  "950k€-1M€",
  "1M€-1.2M€",
  "1.2M€-1.4M€",
  "1.4M€-1.6M€",
  "1.6M€-1.8M€",
  "1.8M€-2M€",
  "2M€-2.2M€",
  "2.2M€-2.4M€",
  "2.4M€-2.5M€"
];

const contratos = [
  "2-Way",
  "Exhibit-10",
  "1 año",
  "1+1",
  "2 años",
  "3 años",
  "On Loan"
];



// Utils pour formater les dates
const formatDateForDisplay = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const formatDateForInput = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split('T')[0];
};

const formatDateForBackend = (input) => {
  if (!input || input.includes('T')) return input;
  const [year, month, day] = input.split('-');
  if (year && month && day) {
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }
  return input;
};

// Composant photo capture
const PhotoCapture = ({ onCapture }) => {
  const handlePhotoCapture = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Resize avant upload
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;
          const MAX_DIMENSION = 800;

          if (width > height && width > MAX_DIMENSION) {
            height = height * (MAX_DIMENSION / width);
            width = MAX_DIMENSION;
          } else if (height > MAX_DIMENSION) {
            width = width * (MAX_DIMENSION / height);
            height = MAX_DIMENSION;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            const resizedFile = new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            });
            onCapture(resizedFile);
          }, 'image/jpeg', 0.8);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box>
      <input
        accept="image/*"
        id="photo-capture"
        type="file"
        capture="environment"
        onChange={handlePhotoCapture}
        style={{ display: 'none' }}
      />
      <label htmlFor="photo-capture">
        <Button
          variant="contained"
          component="span"
          startIcon={<PhotoCamera />}
          fullWidth
        >
          Tomar foto
        </Button>
      </label>
    </Box>
  );
};

// Composant photo selection
const PhotoSelection = ({ onSelect }) => {
  const handlePhotoSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;
          const MAX_DIMENSION = 800;

          if (width > height && width > MAX_DIMENSION) {
            height = height * (MAX_DIMENSION / width);
            width = MAX_DIMENSION;
          } else if (height > MAX_DIMENSION) {
            width = width * (MAX_DIMENSION / height);
            height = MAX_DIMENSION;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            const resizedFile = new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            });
            onSelect(resizedFile);
          }, 'image/jpeg', 0.8);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box>
      <input
        accept="image/*"
        id="photo-select"
        type="file"
        onChange={handlePhotoSelect}
        style={{ display: 'none' }}
      />
      <label htmlFor="photo-select">
        <Button
          variant="contained"
          component="span"
          startIcon={<ImageIcon />}
          fullWidth
        >
          Seleccionar foto
        </Button>
      </label>
    </Box>
  );
};

// Drawer mobile
const MobileDrawerMenu = ({
  open, 
  onClose, 
  sections, 
  activeSection, 
  onSectionChange 
}) => {
  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: '80%',
          maxWidth: 360
        }
      }}
    >
      <Box sx={{ width: '100%' }}>
        <List>
          <ListItem>
            <Typography variant="h6">Secciones</Typography>
          </ListItem>
          <Divider />
          {sections.map((section, index) => (
            <ListItem key={section.name} disablePadding>
              <ListItemButton
                selected={activeSection === index}
                onClick={() => {
                  onSectionChange(index);
                  onClose();
                }}
              >
                <ListItemIcon>
                  {section.icon}
                </ListItemIcon>
                <ListItemText primary={section.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

// Entête sur mobile
const MobileProfileHeader = ({
  player,
  avatarUrl,
  isEditing,
  onEditPhoto,
  onBack
}) => {
  return (
    <AppBar position="static" color="default" elevation={0}>
      <Toolbar>
        <IconButton edge="start" onClick={onBack}>
          <ArrowBack />
        </IconButton>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          width: '100%',
          py: 2
        }}>
          <Box sx={{ position: 'relative' }}>
            <Avatar
              src={avatarUrl}
              alt={player?.nom}
              sx={{ 
                width: 120, 
                height: 120,
                border: '3px solid white',
                boxShadow: 1
              }}
            />
            {isEditing && (
              <IconButton
                sx={{
                  position: 'absolute',
                  bottom: -10,
                  right: -10,
                  backgroundColor: 'background.paper',
                  boxShadow: 1,
                  '&:hover': { backgroundColor: 'background.paper' }
                }}
                onClick={onEditPhoto}
              >
                <Edit />
              </IconButton>
            )}
          </Box>
          <Typography variant="h6" sx={{ mt: 2 }}>
            {player?.nom}
          </Typography>
          {player?.type && (
            <Chip
              label={player.type === 'cantera' ? "Cantera" : "Pro"}
              sx={{
                backgroundColor: player.type === 'cantera' ? '#FF8C00' : '#007FFF',
                color: '#fff',
                mt: 1
              }}
            />
          )}
          {player?.url && (
            <Button
              variant="outlined" 
              startIcon={<OpenInNew />}
              href={player.url}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ mt: 1,mb :2, backgroundColor: 'white' }}
            >
              Ficha Estadistica
            </Button>
          )}

{/* TAGS */}
{player?.tags && player.tags.length > 0 && (
  <Box 
    sx={{ 
      display: 'flex', 
      flexWrap: 'wrap', 
      gap: 0.5,
      justifyContent: 'center',
      mb: 2
    }}
  >
    {player.tags.map((tag) => (
      <Tooltip
        key={tag.id}
        title={tag.source === 'big_board' ? 'Ranking' : 'Seguimiento'}
        placement="top"
        arrow
      >
        <Chip
          label={tag.libelle}
          sx={{
            // 2 couleurs distinctes
            backgroundColor:
              tag.source === 'big_board'
                ? '#1565c0'        // bleu pour big_board
                : '#c62828',       // rouge pour seguimiento
            color: '#fff'
          }}
        />
      </Tooltip>
    ))}
  </Box>
)}


          
          {player?.groupes && player.groupes.length > 0 && (
            <Box sx={{ 
              display: 'flex', 
              flexWrap: 'wrap', 
              gap: 0.5,
              justifyContent: 'center',
              mt: 1 
            }}>
              {player.groupes.map((group) => (
                <Chip
                  key={group.id}
                  label={group.libelle}
                  size="small"
                  sx={{
                    backgroundColor: group.code_couleur || 'grey',
                    color: '#fff',
                  }}
                />
              ))}
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

// Dialogue pour éditer la photo
const PhotoEditDialog = ({
  open,
  onClose,
  onCapture,
  onSelect
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        Cambiar foto
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
          <PhotoCapture onCapture={(file) => {
            onCapture(file);
            onClose();
          }} />
          <PhotoSelection onSelect={(file) => {
            onSelect(file);
            onClose();
          }} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
};

// Section sur mobile
const MobileSection = ({
  title,
  children
}) => {
  return (
    <Paper 
      elevation={0} 
      sx={{ 
        mb: 2,
        backgroundColor: 'background.default'
      }}
    >
      {title && (
        <Typography 
          variant="subtitle1" 
          sx={{ 
            fontWeight: 'medium',
            px: 2,
            py: 1,
            backgroundColor: 'background.paper'
          }}
        >
          {title}
        </Typography>
      )}
      <Box sx={{ p: 2 }}>
        {children}
      </Box>
    </Paper>
  );
};


const PlayerProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;

  // States
  const [player, setPlayer] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const [isEditing, setIsEditing] = useState(false);
  const [editablePlayer, setEditablePlayer] = useState({});
  
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImagePreview, setSelectedImagePreview] = useState(null);
  
  const [allGroups, setAllGroups] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  
  const [activeTab, setActiveTab] = useState(0);
  const [avatarTimestamp, setAvatarTimestamp] = useState(Date.now());
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Indicateurs de data pour Informe/Intel/Technique/PDP
  const [hasInformeData, setHasInformeData] = useState(false);
  const [hasIntelData, setHasIntelData] = useState(false);
  const [hasTechnicaData, setHasTechnicaData] = useState(false);
  const [hasPDPData, setHasPDPData] = useState(false);

  // Listes
  const [teams, setTeams] = useState([]);
  const [leagues1, setLeagues1] = useState([]);
  const [leagues2, setLeagues2] = useState([]);
  const [agencies, setAgencies] = useState([]);

  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    setUserRole(user?.role?.toLowerCase());
  }, []);

  // Récupération listes (teams, leagues, agencies)
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players/teams`, { params: { section } });
        setTeams(response.data.teams || []);
      } catch (error) {
        console.error('Error fetching teams:', error);
      }
    };

    const fetchLeagues1 = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players/leagues1`, { params: { section } });
        setLeagues1(response.data.leagues1 || []);
      } catch (error) {
        console.error('Error fetching leagues1:', error);
      }
    };

    const fetchLeagues2 = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players/leagues2`, { params: { section } });
        setLeagues2(response.data.leagues2 || []);
      } catch (error) {
        console.error('Error fetching leagues2:', error);
      }
    };

    const fetchAgencies = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/players/agencies`, { params: { section } });
        setAgencies(response.data.agencies || []);
      } catch (error) {
        console.error('Error fetching agencies:', error);
      }
    };

    fetchTeams();
    fetchLeagues1();
    fetchLeagues2();
    fetchAgencies();
  }, [backendUrl, section]);

  // Récupération du joueur
  useEffect(() => {
    const fetchPlayer = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${backendUrl}/api/players/${id}?section=${section}`);
        
        // On mappe le backend => un "player" plus facile à éditer
        const mappedPlayer = {
          ...response.data,
          dateNaissance: response.data.date_naissance,
          nacionalidad: response.data.nationalite,
          segundaNacionalidad: response.data.nationalite_2,
          seleccionNacional: response.data.selection_national,
          peso: response.data.poids,
          rolOfensivo: response.data.offensive_role,
          rolDefensivo: response.data.defensive_role,
          pasaporte: response.data.passport,
          estatus: response.data.statut,
          equipo: response.data.team,
          liga1: response.data.league_1,
          liga2: response.data.league_2,
          equipoPreDraft: response.data.pre_draft_team,
          añoDraft: response.data.draft_year,
          posicionDraft: response.data.draft_pick,
          experienciaAcb: response.data.acb_experience,
          nbaExperience: response.data.nba_experience,
          agencia: response.data.agency,
          comparacionPro: response.data.pro_comparaison,
          comportamiento: response.data.flag_behaviour,
          rangoPrecios: response.data.price_range,
          contrato: response.data.contract,
          nivel: response.data.level,
          proyeccion: response.data.proyeccion,
          jeJiType: response.data.je_ji || '',
          jeJiAttributes: response.data.selection_je_ji ? response.data.selection_je_ji.split(',') : [],
          pit: response.data.pit,
          eurocamp: response.data.eurocamp,
          injury: response.data.injury,

          // === 3 CHAMPS AJOUTÉS ===
          experiencaEuropa: response.data.experienca_europa,
          shootingRange: response.data.shooting_range,
          competicionEuropa: response.data.competicion_europa,

          origen_genetico:response.data.origen_genetico
        };

        setPlayer(mappedPlayer);
        setEditablePlayer(mappedPlayer);
        setError(null);

      } catch (err) {
        setError(err.response?.data?.message || 'Error al cargar los datos del jugador');
        console.error('Error fetching player:', err);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) fetchPlayer();
  }, [id, backendUrl, section]);

  // Récupération des data informe/intel/tech/pdp
  useEffect(() => {
    const fetchData = async () => {
      if (player) {
        try {
          const [informeRes, intelRes, technicaRes, pdpRes] = await Promise.all([
            axios.get(`${backendUrl}/api/informe`, { params: { playerId: player.id, section } }),
            axios.get(`${backendUrl}/api/intel`, { params: { playerId: player.id, section } }),
            axios.get(`${backendUrl}/api/technique`, { params: { playerId: player.id, section } }),
            axios.get(`${backendUrl}/api/tecnification/player/${player.id}/fiches`, { params: { section } })
          ]);

          setHasInformeData(informeRes.data.length > 0);
          setHasIntelData(intelRes.data.length > 0);
          setHasTechnicaData(technicaRes.data.length > 0);
          setHasPDPData(pdpRes.data.length > 0);
        } catch (error) {
          console.error("Error al recuperar los datos:", error);
        }
      }
    };

    fetchData();
  }, [player, section, backendUrl]);

  // Récupération des groupes pour tous types (pro ou cantera)
  useEffect(() => {
    const fetchGroups = async () => {
      if (player) {
        try {
          const response = await axios.get(`${backendUrl}/api/groups`, { params: { section } });
          setAllGroups(response.data);
        } catch (error) {
          console.error("Error al recuperar grupos:", error);
        }
      }
    };
    fetchGroups();
  }, [player, section, backendUrl]);

  // Handler generique
  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setEditablePlayer((prev) => ({ ...prev, [name]: fieldValue }));
  };

  // Photo
  const handlePhotoCapture = (file) => {
    setSelectedImage(file);
    setSelectedImagePreview(URL.createObjectURL(file));
    setOpenPhotoDialog(false);
  };

  const handlePhotoSelect = (file) => {
    setSelectedImage(file);
    setSelectedImagePreview(URL.createObjectURL(file));
    setOpenPhotoDialog(false);
  };

  // Groupes (pour tout type = pro ou cantera)
  const handleGroupChange = (e, group) => {
    setEditablePlayer((prev) => {
      const isGroupSelected = prev.groupes?.some(g => g.id === group.id);
      const updatedGroups = isGroupSelected
        ? prev.groupes.filter(g => g.id !== group.id)
        : [...(prev.groupes || []), group];
      return { ...prev, groupes: updatedGroups };
    });
  };

  // Envoi update
  const handleUpdate = async () => {
    const dateNaissanceFormatted = formatDateForBackend(editablePlayer.dateNaissance);
    const anneeNaissanceFormatted = dateNaissanceFormatted ? new Date(dateNaissanceFormatted).getFullYear() : '';

    const formData = new FormData();
    formData.append('nom', editablePlayer.nom || '');
    formData.append('type', editablePlayer.type || '');
    formData.append('annee_naissance', anneeNaissanceFormatted || '');
    formData.append('date_naissance', dateNaissanceFormatted || '');
    formData.append('taille', editablePlayer.altura || editablePlayer.taille || '');
    formData.append('poids', editablePlayer.peso || '');
    formData.append('poste', editablePlayer.poste || '');
    formData.append('nationalite', editablePlayer.nacionalidad || '');
    formData.append('nationalite_2', editablePlayer.segundaNacionalidad || '');
    formData.append('selection_national', editablePlayer.seleccionNacional || '');
    formData.append('mano', editablePlayer.mano || '');
    formData.append('passport', editablePlayer.pasaporte || '');
    formData.append('statut', editablePlayer.estatus || '');
    formData.append('team', editablePlayer.equipo || '');
    formData.append('league_1', editablePlayer.liga1 || '');
    formData.append('league_2', editablePlayer.liga2 || '');
    formData.append('pre_draft_team', editablePlayer.equipoPreDraft || '');
    formData.append('draft_year', editablePlayer.añoDraft || '');
    formData.append('draft_pick', editablePlayer.posicionDraft || '');
    formData.append('acb_experience', editablePlayer.experienciaAcb || '');
    formData.append('nba_experience', editablePlayer.nbaExperience || '');
    formData.append('level', editablePlayer.nivel || '');
    formData.append('proyeccion', editablePlayer.proyeccion || '');
    formData.append('agency', editablePlayer.agencia || '');
    formData.append('pro_comparaison', editablePlayer.comparacionPro || '');
    formData.append('flag_behaviour', editablePlayer.comportamiento || '');
    formData.append('injury', editablePlayer.injury || '');
    formData.append('price_range', editablePlayer.rangoPrecios || '');
    formData.append('contract', editablePlayer.contrato || '');
    formData.append('url', editablePlayer.url || '');
    formData.append('pit', editablePlayer.pit || '');
    formData.append('eurocamp', editablePlayer.eurocamp || '');

    // JE/JI
    formData.append('je_ji', editablePlayer.jeJiType || '');
    const jeJiAttributesValue = editablePlayer.jeJiAttributes?.length
      ? editablePlayer.jeJiAttributes.join(',')
      : '';
    formData.append('selection_je_ji', jeJiAttributesValue);

    // Rôles
    formData.append('offensive_role', editablePlayer.rolOfensivo || '');
    formData.append('defensive_role', editablePlayer.rolDefensivo || '');

    // Groupes
    if (editablePlayer.groupes) {
      formData.append('groupes', JSON.stringify(editablePlayer.groupes.map(g => g.id)));
    }

    // === CHAMPS EXISTANTS ===
    formData.append('experienca_europa', editablePlayer.experiencaEuropa || '');
    formData.append('shooting_range', editablePlayer.shootingRange || '');
    formData.append('competicion_europa', editablePlayer.competicionEuropa || '');

    // === NOUVEAUX CHAMPS AJOUTÉS ===
    formData.append('buyout', editablePlayer.buyout || '');
    formData.append('origen_genetico', editablePlayer.origen_genetico || '');
    formData.append('slvegas', editablePlayer.slvegas || '');
    formData.append('experienca_fuera', editablePlayer.experienca_fuera || '');
    formData.append('experienca_ncaa', editablePlayer.experienca_ncaa || '');

    // Photo
    if (selectedImage) {
      formData.append('avatar', selectedImage);
    }

    try {
      await axios.put(
        `${backendUrl}/api/players/${editablePlayer.id}?section=${section}`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      setPlayer({
        ...editablePlayer,
        date_naissance: dateNaissanceFormatted,
        annee_naissance: anneeNaissanceFormatted
      });
      setIsEditing(false);
      setAvatarTimestamp(Date.now());
      if (isMobile) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Error al actualizar el jugador');
      console.error('Update error:', error);
    }
};
  // ----------- RENDER SECTIONS -----------

  /**
   * 1. INFORMACIÓN BÁSICA 
   *    Nombre
   *    Grupo (Tipo)
   *    Peso, Altura
   *    Nacionalidad, Segunda Nacionalidad
   *    Fecha de Nacimiento, Licencia
   */

  const renderInformacionBasica = () => {
    // MOBILE
    if (isMobile) {
      return (
        <MobileSection title="1. Información Básica">
          {isEditing ? (
            <Grid container spacing={2}>
              {/* Nombre */}
              <Grid item xs={12}>
                <TextField
                  label="Nombre"
                  name="nom"
                  value={editablePlayer.nom || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
  
              {/* Grupo (Tipo) */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Grupo</InputLabel>
                  <Select
                    name="type"
                    value={editablePlayer.type || ''}
                    onChange={handleChange}
                    label="Grupo"
                    endAdornment={
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditablePlayer((prev) => ({ ...prev, type: '' }));
                        }}
                        sx={{
                          visibility: editablePlayer.type ? 'visible' : 'hidden',
                          position: 'absolute',
                          right: 32
                        }}
                        size="small"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    }
                  >
                    <MenuItem value="pro">Pro</MenuItem>
                    <MenuItem value="cantera">Cantera</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
  
              {/* Groups (pour pro ou cantera) */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Grupos</InputLabel>
                  <Select
                    multiple
                    value={editablePlayer.groupes || []}
                    renderValue={(selected) =>
                      selected.map((g) => g.libelle).join(', ')
                    }
                    endAdornment={
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditablePlayer((prev) => ({ ...prev, groupes: [] }));
                        }}
                        sx={{
                          visibility: editablePlayer.groupes?.length
                            ? 'visible'
                            : 'hidden',
                          position: 'absolute',
                          right: 32
                        }}
                        size="small"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    }
                  >
                    {allGroups.map((group) => (
                      <MenuItem key={group.id} value={group}>
                        <Checkbox
                          checked={
                            editablePlayer.groupes?.some(
                              (g) => g.id === group.id
                            ) || false
                          }
                          onChange={(e) => handleGroupChange(e, group)}
                          style={{ color: group.code_couleur || '#000' }}
                        />
                        <ListItemText primary={group.libelle} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
  
              {/* Peso, Altura */}
              <Grid item xs={6}>
                <TextField
                  label="Peso (kg)"
                  name="peso"
                  type="number"
                  value={editablePlayer.peso || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Altura (cm)"
                  name="altura"
                  type="number"
                  value={editablePlayer.altura || editablePlayer.taille || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
  
              {/* Nacionalidad, 2a Nacionalidad */}
              <Grid item xs={12}>
                <NationalitiesSelect
                  value={editablePlayer.nacionalidad || ''}
                  onChange={(e) =>
                    setEditablePlayer((prev) => ({
                      ...prev,
                      nacionalidad: e.target.value
                    }))
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <NationalitiesSelect2
                  value={editablePlayer.segundaNacionalidad || ''}
                  onChange={(e) =>
                    setEditablePlayer((prev) => ({
                      ...prev,
                      segundaNacionalidad: e.target.value
                    }))
                  }
                />
              </Grid>
  
              {/* Origen Genético */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Origen Genético</InputLabel>
                  <Select
                    name="origen_genetico"
                    value={editablePlayer.origen_genetico || ''}
                    onChange={handleChange}
                    label="Origen Genético"
                    endAdornment={
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditablePlayer((prev) => ({
                            ...prev,
                            origen_genetico: ''
                          }));
                        }}
                        sx={{
                          visibility: editablePlayer.origen_genetico
                            ? 'visible'
                            : 'hidden',
                          position: 'absolute',
                          right: 32
                        }}
                        size="small"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    }
                  >
                    <MenuItem value="Africanos (subsahariana) - Camerun, Nigeria, Senegal, Mali, etc.">
  Africanos (subsahariana) - Camerun, Nigeria, Senegal, Mali, etc.
</MenuItem>
<MenuItem value="Afrocaribenos - Martinica, Republica Dominicana, Puerto Rico, Islas Virgenes, etc.">
  Afrocaribenos - Martinica, Republica Dominicana, Puerto Rico, Islas Virgenes, etc.
</MenuItem>
<MenuItem value="Balcanes - Serbia, Croacia, Bosnia, Montenegro, Macedonia del Norte, etc.">
  Balcanes - Serbia, Croacia, Bosnia, Montenegro, Macedonia del Norte, etc.
</MenuItem>
<MenuItem value="Europa Occidental - Francia, Alemania, Espana, Italia, Belgica, etc.">
  Europa Occidental - Francia, Alemania, Espana, Italia, Belgica, etc.
</MenuItem>
<MenuItem value="Latinoamericanos - Argentina, Brasil, Venezuela, Mexico, etc.">
  Latinoamericanos - Argentina, Brasil, Venezuela, Mexico, etc.
</MenuItem>
<MenuItem value="Nordafricanos - Tunez, Marruecos, Egipto, etc.">
  Nordafricanos - Tunez, Marruecos, Egipto, etc.
</MenuItem>
<MenuItem value="Norteamericanos - Estados Unidos, Canada, etc.">
  Norteamericanos - Estados Unidos, Canada, etc.
</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
  
              {/* Fecha de nacimiento + Licencia */}
              <Grid item xs={6}>
                <TextField
                  label="Fecha de nacimiento"
                  name="dateNaissance"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={formatDateForInput(editablePlayer.dateNaissance)}
                  onChange={(e) =>
                    setEditablePlayer((prev) => ({
                      ...prev,
                      dateNaissance: e.target.value
                    }))
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Licencia</InputLabel>
                  <Select
                    name="pasaporte"
                    value={editablePlayer.pasaporte || ''}
                    onChange={handleChange}
                    label="Licencia"
                    endAdornment={
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditablePlayer((prev) => ({
                            ...prev,
                            pasaporte: ''
                          }));
                        }}
                        sx={{
                          visibility: editablePlayer.pasaporte
                            ? 'visible'
                            : 'hidden',
                          position: 'absolute',
                          right: 32
                        }}
                        size="small"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    }
                  >
                    <MenuItem value="EXT">EXT</MenuItem>
                    <MenuItem value="EUR">EUR</MenuItem>
                    <MenuItem value="JFL">JFL</MenuItem>
                    <MenuItem value="COT">COT</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          ) : (
            // READ mode
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {!!player.nom && (
                <Typography>
                  <strong>Nombre:</strong> {player.nom}
                </Typography>
              )}
              {!!player.type && (
                <Typography>
                  <strong>Grupo :</strong>{' '}
                  {player.type.charAt(0).toUpperCase() + player.type.slice(1)}
                </Typography>
              )}
              {!!player.groupes?.length && (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {player.groupes.map((g) => (
                    <Chip
                      key={g.id}
                      label={g.libelle}
                      sx={{
                        backgroundColor: g.code_couleur || 'grey',
                        color: '#fff'
                      }}
                    />
                  ))}
                </Box>
              )}
              {!!player.peso && (
                <Typography>
                  <strong>Peso:</strong> {player.peso}kg
                </Typography>
              )}
              {!!player.taille && (
                <Typography>
                  <strong>Altura:</strong> {player.taille} cm
                </Typography>
              )}
              {!!player.nacionalidad && (
                <Typography>
                  <strong>Nacionalidad:</strong> {player.nacionalidad}
                </Typography>
              )}
              {!!player.segundaNacionalidad && (
                <Typography>
                  <strong>Segunda Nacionalidad:</strong>{' '}
                  {player.segundaNacionalidad}
                </Typography>
              )}
              {!!player.origen_genetico && (
                <Typography>
                  <strong>Origen Genético:</strong> {player.origen_genetico}
                </Typography>
              )}
              {!!player.dateNaissance && (
                <Typography>
                  <strong>Fecha de Nacimiento:</strong>{' '}
                  {formatDateForDisplay(player.dateNaissance)}
                </Typography>
              )}
              {!!player.pasaporte && (
                <Typography>
                  <strong>Licencia:</strong> {player.pasaporte}
                </Typography>
              )}
            </Box>
          )}
        </MobileSection>
      );
    }
  
    // DESKTOP
    return (
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          1. Información Básica
        </Typography>
        {isEditing ? (
          <Grid container spacing={2}>
            {/* Nombre */}
            <Grid item xs={12} md={6}>
              <TextField
                label="Nombre"
                name="nom"
                value={editablePlayer.nom || ''}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
  
            {/* Grupo (Tipo) */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Grupo</InputLabel>
                <Select
                  name="type"
                  value={editablePlayer.type || ''}
                  onChange={handleChange}
                  label="Grupo"
                  endAdornment={
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditablePlayer((prev) => ({ ...prev, type: '' }));
                      }}
                      sx={{
                        visibility: editablePlayer.type ? 'visible' : 'hidden',
                        position: 'absolute',
                        right: 32
                      }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  }
                >
                  <MenuItem value="pro">Pro</MenuItem>
                  <MenuItem value="cantera">Cantera</MenuItem>
                </Select>
              </FormControl>
            </Grid>
  
            {/* Groups (pour pro ou cantera) */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Grupos</InputLabel>
                <Select
                  multiple
                  value={editablePlayer.groupes || []}
                  renderValue={(selected) =>
                    selected.map((g) => g.libelle).join(', ')
                  }
                  endAdornment={
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditablePlayer((prev) => ({
                          ...prev,
                          groupes: []
                        }));
                      }}
                      sx={{
                        visibility: editablePlayer.groupes?.length
                          ? 'visible'
                          : 'hidden',
                        position: 'absolute',
                        right: 32
                      }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  }
                >
                  {allGroups.map((group) => (
                    <MenuItem key={group.id} value={group}>
                      <Checkbox
                        checked={
                          editablePlayer.groupes?.some((g) => g.id === group.id)
                            ? true
                            : false
                        }
                        onChange={(e) => handleGroupChange(e, group)}
                        style={{ color: group.code_couleur || '#000' }}
                      />
                      <ListItemText primary={group.libelle} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
  
            {/* Peso, Altura */}
            <Grid item xs={12} md={6}>
              <TextField
                label="Peso (kg)"
                name="peso"
                type="number"
                value={editablePlayer.peso || ''}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Altura (cm)"
                name="altura"
                type="number"
                value={editablePlayer.altura || editablePlayer.taille || ''}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
  
            {/* Nacionalidad, 2a Nacionalidad */}
            <Grid item xs={12} md={6}>
              <NationalitiesSelect
                value={editablePlayer.nacionalidad || ''}
                onChange={(e) =>
                  setEditablePlayer((prev) => ({
                    ...prev,
                    nacionalidad: e.target.value
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <NationalitiesSelect2
                value={editablePlayer.segundaNacionalidad || ''}
                onChange={(e) =>
                  setEditablePlayer((prev) => ({
                    ...prev,
                    segundaNacionalidad: e.target.value
                  }))
                }
              />
            </Grid>
  
            {/* Origen Genético */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Origen Genético</InputLabel>
                <Select
                  name="origen_genetico"
                  value={editablePlayer.origen_genetico || ''}
                  onChange={handleChange}
                  label="Origen Genético"
                  endAdornment={
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditablePlayer((prev) => ({
                          ...prev,
                          origen_genetico: ''
                        }));
                      }}
                      sx={{
                        visibility: editablePlayer.origen_genetico
                          ? 'visible'
                          : 'hidden',
                        position: 'absolute',
                        right: 32
                      }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  }
                >
                  <MenuItem value="Africanos (subsahariana) - Camerun, Nigeria, Senegal, Mali, etc.">
  Africanos (subsahariana) - Camerun, Nigeria, Senegal, Mali, etc.
</MenuItem>
<MenuItem value="Afrocaribenos - Martinica, Republica Dominicana, Puerto Rico, Islas Virgenes, etc.">
  Afrocaribenos - Martinica, Republica Dominicana, Puerto Rico, Islas Virgenes, etc.
</MenuItem>
<MenuItem value="Balcanes - Serbia, Croacia, Bosnia, Montenegro, Macedonia del Norte, etc.">
  Balcanes - Serbia, Croacia, Bosnia, Montenegro, Macedonia del Norte, etc.
</MenuItem>
<MenuItem value="Europa Occidental - Francia, Alemania, Espana, Italia, Belgica, etc.">
  Europa Occidental - Francia, Alemania, Espana, Italia, Belgica, etc.
</MenuItem>
<MenuItem value="Latinoamericanos - Argentina, Brasil, Venezuela, Mexico, etc.">
  Latinoamericanos - Argentina, Brasil, Venezuela, Mexico, etc.
</MenuItem>
<MenuItem value="Nordafricanos - Tunez, Marruecos, Egipto, etc.">
  Nordafricanos - Tunez, Marruecos, Egipto, etc.
</MenuItem>
<MenuItem value="Norteamericanos - Estados Unidos, Canada, etc.">
  Norteamericanos - Estados Unidos, Canada, etc.
</MenuItem>
                </Select>
              </FormControl>
            </Grid>
  
            {/* Fecha de nacimiento */}
            <Grid item xs={12} md={4}>
              <TextField
                label="Fecha de nacimiento"
                name="dateNaissance"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={formatDateForInput(editablePlayer.dateNaissance)}
                onChange={(e) =>
                  setEditablePlayer((prev) => ({
                    ...prev,
                    dateNaissance: e.target.value
                  }))
                }
              />
            </Grid>
  
            {/* Licencia */}
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Licencia</InputLabel>
                <Select
                  name="pasaporte"
                  value={editablePlayer.pasaporte || ''}
                  onChange={handleChange}
                  label="Licencia"
                  endAdornment={
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditablePlayer((prev) => ({
                          ...prev,
                          pasaporte: ''
                        }));
                      }}
                      sx={{
                        visibility: editablePlayer.pasaporte
                          ? 'visible'
                          : 'hidden',
                        position: 'absolute',
                        right: 32
                      }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  }
                >
                  <MenuItem value="EXT">EXT</MenuItem>
                  <MenuItem value="EUR">EUR</MenuItem>
                  <MenuItem value="JFL">JFL</MenuItem>
                  <MenuItem value="COT">COT</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        ) : (
          // READ MODE
          <Box sx={{ mt: 1 }}>
            {!!player.nom && (
              <Typography sx={{ display: 'flex' }}>
                <strong style={{ minWidth: 'auto' }}>Nombre:&nbsp; </strong>
                {player.nom}
              </Typography>
            )}
            {!!player.type && (
              <Typography sx={{ display: 'flex' }}>
                <strong style={{ minWidth: 'auto' }}>Grupo :&nbsp; </strong>
                {player.type.charAt(0).toUpperCase() + player.type.slice(1)}
              </Typography>
            )}
            {!!player.groupes?.length && (
              <Box
                sx={{
                  display: 'flex',
                  ml: '140px',
                  flexWrap: 'wrap',
                  gap: 1,
                  mb: 1
                }}
              >
                {player.groupes.map((g) => (
                  <Chip
                    key={g.id}
                    label={g.libelle}
                    sx={{
                      backgroundColor: g.code_couleur || 'grey',
                      color: '#fff'
                    }}
                  />
                ))}
              </Box>
            )}
            {(!!player.peso || !!player.taille) && (
              <Box sx={{ display: 'flex', gap: 4 }}>
                {!!player.peso && (
                  <Typography sx={{ display: 'flex', flex: 1 }}>
                    <strong style={{ minWidth: 'auto' }}>Peso:&nbsp; </strong>
                    {player.peso}kg
                  </Typography>
                )}
                {!!player.taille && (
                  <Typography sx={{ display: 'flex', flex: 1 }}>
                    <strong style={{ minWidth: 'auto' }}>Altura:&nbsp; </strong>
                    {player.taille}cm
                  </Typography>
                )}
              </Box>
            )}
            {(!!player.nacionalidad || !!player.segundaNacionalidad) && (
              <Box sx={{ display: 'flex', gap: 4 }}>
                {!!player.nacionalidad && (
                  <Typography sx={{ display: 'flex', flex: 1 }}>
                    <strong style={{ minWidth: 'auto' }}>
                      Nacionalidad:&nbsp;{' '}
                    </strong>
                    {player.nacionalidad}
                  </Typography>
                )}
                {!!player.segundaNacionalidad && (
                  <Typography sx={{ display: 'flex', flex: 1 }}>
                    <strong style={{ minWidth: 'auto' }}>
                      Segunda Nacionalidad:&nbsp;{' '}
                    </strong>
                    {player.segundaNacionalidad}
                  </Typography>
                )}
              </Box>
            )}
            {!!player.origen_genetico && (
              <Typography sx={{ display: 'flex' }}>
                <strong style={{ minWidth: 'auto' }}>
                  Origen Genético:&nbsp;{' '}
                </strong>
                {player.origen_genetico}
              </Typography>
            )}
            {!!player.dateNaissance && (
              <Typography sx={{ display: 'flex' }}>
                <strong style={{ minWidth: 'auto' }}>
                  Fecha de Nacimiento:&nbsp;{' '}
                </strong>
                {formatDateForDisplay(player.dateNaissance)}
              </Typography>
            )}
            {!!player.pasaporte && (
              <Typography sx={{ display: 'flex' }}>
                <strong style={{ minWidth: 'auto' }}>Licencia:&nbsp; </strong>
                {player.pasaporte}
              </Typography>
            )}
          </Box>
        )}
      </Paper>
    );
  };

  /**
   * 2. CARACTERÍSTICAS DEL JUGADOR
   *    Posición
   *    Mano Dominante
   *    Shooting Range
   *    Perfil Ofensivo, Perfil Defensivo
   *    Skills JE/JI, Skills JE ou JI (Ex atributos)
   *    Current VBC Rating, Proyección VBC Rating
   *    Flag Behaviour, Injury Prone
   *    Comparación con un Jugador Pro
   */
  
  const renderCaracteristicasJugador = () => {
    // MOBILE
    if (isMobile) {
      return (
        <MobileSection title="2. Características del Jugador">
          {isEditing ? (
            <Grid container spacing={2}>
              {/* Posición */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Posición</InputLabel>
                  <Select
                    name="poste"
                    value={editablePlayer.poste || ''}
                    onChange={handleChange}
                    label="Posición"
                    endAdornment={
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditablePlayer((prev) => ({ ...prev, poste: '' }));
                        }}
                        sx={{
                          visibility: editablePlayer.poste ? 'visible' : 'hidden',
                          position: 'absolute',
                          right: 32
                        }}
                        size="small"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    }
                  >
                    <MenuItem value="Base">Base</MenuItem>
                    <MenuItem value="Escolta">Escolta</MenuItem>
                    <MenuItem value="Alero">Alero</MenuItem>
                    <MenuItem value="Ala-Pívot">Ala-Pívot</MenuItem>
                    <MenuItem value="Pívot">Pívot</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
  
              {/* Mano Dominante */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Mano Dominante</InputLabel>
                  <Select
                    name="mano"
                    value={editablePlayer.mano || ''}
                    onChange={handleChange}
                    label="Mano Dominante"
                    endAdornment={
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditablePlayer((prev) => ({ ...prev, mano: '' }));
                        }}
                        sx={{
                          visibility: editablePlayer.mano ? 'visible' : 'hidden',
                          position: 'absolute',
                          right: 32
                        }}
                        size="small"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    }
                  >
                    <MenuItem value="Derecha">Derecha</MenuItem>
                    <MenuItem value="Izquierda">Izquierda</MenuItem>
                    <MenuItem value="Ambas">Ambas</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
  
              {/* Shooting Range */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Shooting Range</InputLabel>
                  <Select
                    name="shootingRange"
                    value={editablePlayer.shootingRange || ''}
                    onChange={handleChange}
                    label="Shooting Range"
                    endAdornment={
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditablePlayer((prev) => ({
                            ...prev,
                            shootingRange: ''
                          }));
                        }}
                        sx={{
                          visibility: editablePlayer.shootingRange ? 'visible' : 'hidden',
                          position: 'absolute',
                          right: 32
                        }}
                        size="small"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    }
                  >
                    <MenuItem value="Rim">Rim</MenuItem>
                    <MenuItem value="Mid-Range">Mid-Range</MenuItem>
                    <MenuItem value="3Pts">3Pts</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
  
              {/* Perfil Ofensivo (rolOfensivo) */}
              {/* Perfil Ofensivo (rolOfensivo) */}
<Grid item xs={12}>
  <FormControl fullWidth>
    <InputLabel>Perfil Ofensivo</InputLabel>
    <Select
      multiple
      name="rolOfensivo"
      value={
        editablePlayer.rolOfensivo
          ? editablePlayer.rolOfensivo.split(',')
          : []
      }
      onChange={(e) =>
        setEditablePlayer((prev) => ({
          ...prev,
          rolOfensivo: e.target.value.join(',')
        }))
      }
      renderValue={(selected) => selected.join(', ')}
      endAdornment={
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setEditablePlayer((prev) => ({
              ...prev,
              rolOfensivo: ''
            }));
          }}
          sx={{
            visibility: editablePlayer.rolOfensivo ? 'visible' : 'hidden',
            position: 'absolute',
            right: 32
          }}
          size="small"
        >
          <Clear fontSize="small" />
        </IconButton>
      }
    >
      {/* === GUARD === */}
      <MenuItem disabled divider sx={{ pointerEvents: 'none', fontWeight: 600 }}>
        Guard
      </MenuItem>
      <MenuItem value="Generador con balón">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Generador con balón')
          }
        />
        <ListItemText primary="Generador con balón" />
      </MenuItem>
      <MenuItem value="Generador secundario con balón">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Generador secundario con balón')
          }
        />
        <ListItemText primary="Generador secundario con balón" />
      </MenuItem>
      <MenuItem value="Anotador Dominante">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Anotador Dominante')
          }
        />
        <ListItemText primary="Anotador Dominante" />
      </MenuItem>
      <MenuItem value="Base Director">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Base Director')
          }
        />
        <ListItemText primary="Base Director" />
      </MenuItem>
      <MenuItem value="Tirador Especialista">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Tirador Especialista')
          }
        />
        <ListItemText primary="Tirador Especialista" />
      </MenuItem>

      {/* === WING === */}
      <MenuItem disabled divider sx={{ pointerEvents: 'none', fontWeight: 600 }}>
        Wing
      </MenuItem>
      <MenuItem value="Alero Generador">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Alero Generador')
          }
        />
        <ListItemText primary="Alero Generador" />
      </MenuItem>
      <MenuItem value="Ala Versátil">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Ala Versátil')
          }
        />
        <ListItemText primary="Ala Versátil" />
      </MenuItem>
      <MenuItem value="Alero Fuerte">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Alero Fuerte')
          }
        />
        <ListItemText primary="Alero Fuerte" />
      </MenuItem>
      <MenuItem value="Alero Slasher">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Alero Slasher')
          }
        />
        <ListItemText primary="Alero Slasher" />
      </MenuItem>
      <MenuItem value="Alero Spot-up">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Alero Spot-up')
          }
        />
        <ListItemText primary="Alero Spot-up" />
      </MenuItem>
      <MenuItem value="3&D">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('3&D')
          }
        />
        <ListItemText primary="3&D" />
      </MenuItem>

      {/* === FORWARD === */}
      <MenuItem disabled divider sx={{ pointerEvents: 'none', fontWeight: 600 }}>
        Forward
      </MenuItem>
      <MenuItem value="Ala-Pívot Tradicional">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Ala-Pívot Tradicional')
          }
        />
        <ListItemText primary="Ala-Pívot Tradicional" />
      </MenuItem>
      <MenuItem value="Skilled Forward">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Skilled Forward')
          }
        />
        <ListItemText primary="Skilled Forward" />
      </MenuItem>
      <MenuItem value="4 Abierto">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('4 Abierto')
          }
        />
        <ListItemText primary="4 Abierto" />
      </MenuItem>

      {/* === BIG === */}
      <MenuItem disabled divider sx={{ pointerEvents: 'none', fontWeight: 600 }}>
        Big
      </MenuItem>
      <MenuItem value="Pívot Generador">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Pívot Generador')
          }
        />
        <ListItemText primary="Pívot Generador" />
      </MenuItem>
      <MenuItem value="Pívot Versátil">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Pívot Versátil')
          }
        />
        <ListItemText primary="Pívot Versátil" />
      </MenuItem>
      <MenuItem value="Pívot Mid-Range">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Pívot Mid-Range')
          }
        />
        <ListItemText primary="Pívot Mid-Range" />
      </MenuItem>
      <MenuItem value="5 Abierto">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('5 Abierto')
          }
        />
        <ListItemText primary="5 Abierto" />
      </MenuItem>
      <MenuItem value="Anotador en el poste bajo">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Anotador en el poste bajo')
          }
        />
        <ListItemText primary="Anotador en el poste bajo" />
      </MenuItem>
      <MenuItem value="Pívot finalizador cerca del aro">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Pívot finalizador cerca del aro')
          }
        />
        <ListItemText primary="Pívot finalizador cerca del aro" />
      </MenuItem>
      <MenuItem value="Pívot de bloqueo y continuación">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Pívot de bloqueo y continuación')
          }
        />
        <ListItemText primary="Pívot de bloqueo y continuación" />
      </MenuItem>
      <MenuItem value="Rol Ofensivo Marginal">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Rol Ofensivo Marginal')
          }
        />
        <ListItemText primary="Rol Ofensivo Marginal" />
      </MenuItem>
    </Select>
  </FormControl>
</Grid>

  
              {/* Perfil Defensivo (rolDefensivo) */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Perfil Defensivo</InputLabel>
                  <Select
                    multiple
                    name="rolDefensivo"
                    value={
                      editablePlayer.rolDefensivo
                        ? editablePlayer.rolDefensivo.split(',')
                        : []
                    }
                    onChange={(e) =>
                      setEditablePlayer((prev) => ({
                        ...prev,
                        rolDefensivo: e.target.value.join(',')
                      }))
                    }
                    renderValue={(selected) => selected.join(', ')}
                    endAdornment={
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditablePlayer((prev) => ({
                            ...prev,
                            rolDefensivo: ''
                          }));
                        }}
                        sx={{
                          visibility: editablePlayer.rolDefensivo
                            ? 'visible'
                            : 'hidden',
                          position: 'absolute',
                          right: 32
                        }}
                        size="small"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    }
                  >
                    {defensiveRoles.map((role) => (
                      <MenuItem key={role} value={role}>
                        <Checkbox
                          checked={
                            editablePlayer.rolDefensivo &&
                            editablePlayer.rolDefensivo.split(',').includes(role)
                          }
                        />
                        <ListItemText primary={role} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
  
              {/* Skills JE/JI */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Skills JE/JI</InputLabel>
                  <Select
                    name="jeJiType"
                    value={editablePlayer.jeJiType || ''}
                    onChange={(e) => {
                      // Reset attributes if user changes JE/JI type
                      setEditablePlayer((prev) => ({
                        ...prev,
                        jeJiType: e.target.value,
                        jeJiAttributes: []
                      }));
                    }}
                    label="Skills JE/JI"
                    endAdornment={
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditablePlayer((prev) => ({
                            ...prev,
                            jeJiType: '',
                            jeJiAttributes: []
                          }));
                        }}
                        sx={{
                          visibility: editablePlayer.jeJiType ? 'visible' : 'hidden',
                          position: 'absolute',
                          right: 32
                        }}
                        size="small"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    }
                  >
                    <MenuItem value="JE">JE (Juego Exterior)</MenuItem>
                    <MenuItem value="JI">JI (Juego Interior)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
  
              {/* Skills JE ou JI (Ex atributos) */}
              {editablePlayer.jeJiType && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>
                      Skills {editablePlayer.jeJiType === 'JE' ? 'Exterior' : 'Interior'}
                    </InputLabel>
                    <Select
                      multiple
                      value={editablePlayer.jeJiAttributes || []}
                      onChange={(e) =>
                        setEditablePlayer((prev) => ({
                          ...prev,
                          jeJiAttributes: e.target.value
                        }))
                      }
                      renderValue={(selected) => selected.join(', ')}
                      endAdornment={
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditablePlayer((prev) => ({
                              ...prev,
                              jeJiAttributes: []
                            }));
                          }}
                          sx={{
                            visibility: editablePlayer.jeJiAttributes?.length
                              ? 'visible'
                              : 'hidden',
                            position: 'absolute',
                            right: 32
                          }}
                          size="small"
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      }
                    >
                      {(editablePlayer.jeJiType === 'JE'
                        ? JEAttributes
                        : JIAttributes
                      ).map((attr) => (
                        <MenuItem key={attr} value={attr}>
                          <Checkbox
                            checked={editablePlayer.jeJiAttributes.includes(attr)}
                          />
                          <ListItemText primary={attr} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
  
              {/* Current VBC Rating, Proyección VBC Rating */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Current VBC Rating</InputLabel>
                  <Select
                    name="nivel"
                    value={editablePlayer.nivel || ''}
                    onChange={handleChange}
                    label="Current VBC Rating"
                    endAdornment={
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditablePlayer((prev) => ({
                            ...prev,
                            nivel: ''
                          }));
                        }}
                        sx={{
                          visibility: editablePlayer.nivel ? 'visible' : 'hidden',
                          position: 'absolute',
                          right: 32
                        }}
                        size="small"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    }
                  >
                    <MenuItem value="Proyecto Nivel Bajo">Proyecto Nivel Bajo</MenuItem>
                    <MenuItem value="Proyecto Nivel Medio">Proyecto Nivel Medio</MenuItem>
                    <MenuItem value="Proyecto Nivel Bueno">Proyecto Nivel Bueno</MenuItem>
                    <MenuItem value="Proyecto Top">Proyecto Top</MenuItem>
                    <MenuItem value="Futura Estrella">Futura Estrella</MenuItem>
                    <MenuItem value="EBA Nivel Bajo (10-20)">EBA Nivel Bajo (10-20)</MenuItem>
                    <MenuItem value="EBA Nivel Alto (20-30)">EBA Nivel Alto (20-30)</MenuItem>
                    <MenuItem value="LEB Nivel Bajo (30-40)">LEB Nivel Bajo (30-40)</MenuItem>
                    <MenuItem value="LEB Nivel Alto (40-50)">LEB Nivel Alto (40-50)</MenuItem>
                    <MenuItem value="ACB Rotación (50-60)">ACB Rotación (50-60)</MenuItem>
                    <MenuItem value="Bottom BCL/Eurocup (60-65)">Bottom BCL/Eurocup (60-65)</MenuItem>
                    <MenuItem value="Mid BCL/Eurocup (65-70)">Mid BCL/Eurocup (65-70)</MenuItem>
                    <MenuItem value="Top BCL/Eurocup (70-75)">Top BCL/Eurocup (70-75)</MenuItem>
                    <MenuItem value="EL Nivel Medio (75-80)">EL Nivel Medio (75-80)</MenuItem>
                    <MenuItem value="EL Top (80-85)">EL Top (80-85)</MenuItem>
                    <MenuItem value="NBA Rotación (85-90)">NBA Rotación (85-90)</MenuItem>
                    <MenuItem value="NBA All-Star (95-100)">NBA All-Star (95-100)</MenuItem>
                    <MenuItem value="College Nivel Bajo">College Nivel Bajo</MenuItem>
                    <MenuItem value="College Nivel Medio">College Nivel Medio</MenuItem>
                    <MenuItem value="College Top">College Top</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Proyección VBC Rating</InputLabel>
                  <Select
                    name="proyeccion"
                    value={editablePlayer.proyeccion || ''}
                    onChange={handleChange}
                    label="Proyección VBC Rating"
                    endAdornment={
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditablePlayer((prev) => ({
                            ...prev,
                            proyeccion: ''
                          }));
                        }}
                        sx={{
                          visibility: editablePlayer.proyeccion ? 'visible' : 'hidden',
                          position: 'absolute',
                          right: 32
                        }}
                        size="small"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    }
                  >
                    <MenuItem value="Proyecto Nivel Bajo">Proyecto Nivel Bajo</MenuItem>
                    <MenuItem value="Proyecto Nivel Medio">Proyecto Nivel Medio</MenuItem>
                    <MenuItem value="Proyecto Nivel Bueno">Proyecto Nivel Bueno</MenuItem>
                    <MenuItem value="Proyecto Top">Proyecto Top</MenuItem>
                    <MenuItem value="Futura Estrella">Futura Estrella</MenuItem>
                    <MenuItem value="EBA Nivel Bajo (10-20)">EBA Nivel Bajo (10-20)</MenuItem>
                    <MenuItem value="EBA Nivel Alto (20-30)">EBA Nivel Alto (20-30)</MenuItem>
                    <MenuItem value="LEB Nivel Bajo (30-40)">LEB Nivel Bajo (30-40)</MenuItem>
                    <MenuItem value="LEB Nivel Alto (40-50)">LEB Nivel Alto (40-50)</MenuItem>
                    <MenuItem value="ACB Rotación (50-60)">ACB Rotación (50-60)</MenuItem>
                    <MenuItem value="Bottom BCL/Eurocup (60-65)">Bottom BCL/Eurocup (60-65)</MenuItem>
                    <MenuItem value="Mid BCL/Eurocup (65-70)">Mid BCL/Eurocup (65-70)</MenuItem>
                    <MenuItem value="Top BCL/Eurocup (70-75)">Top BCL/Eurocup (70-75)</MenuItem>
                    <MenuItem value="EL Nivel Medio (75-80)">EL Nivel Medio (75-80)</MenuItem>
                    <MenuItem value="EL Top (80-85)">EL Top (80-85)</MenuItem>
                    <MenuItem value="NBA Rotación (85-90)">NBA Rotación (85-90)</MenuItem>
                    <MenuItem value="NBA All-Star (95-100)">NBA All-Star (95-100)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
  
              {/* Flag Behaviour, Injury Prone */}
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Flag Behaviour</InputLabel>
                  <Select
                    name="comportamiento"
                    value={editablePlayer.comportamiento || ''}
                    onChange={handleChange}
                    label="Flag Behaviour"
                    endAdornment={
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditablePlayer((prev) => ({
                            ...prev,
                            comportamiento: ''
                          }));
                        }}
                        sx={{
                          visibility: editablePlayer.comportamiento ? 'visible' : 'hidden',
                          position: 'absolute',
                          right: 32
                        }}
                        size="small"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    }
                  >
                    <MenuItem value="Green">Green</MenuItem>
                    <MenuItem value="Orange">Orange</MenuItem>
                    <MenuItem value="Red">Red</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Injury Prone</InputLabel>
                  <Select
                    name="injury"
                    value={editablePlayer.injury || ''}
                    onChange={handleChange}
                    label="Injury Prone"
                    endAdornment={
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditablePlayer((prev) => ({
                            ...prev,
                            injury: ''
                          }));
                        }}
                        sx={{
                          visibility: editablePlayer.injury ? 'visible' : 'hidden',
                          position: 'absolute',
                          right: 32
                        }}
                        size="small"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    }
                  >
                    <MenuItem value="Green">Green</MenuItem>
                    <MenuItem value="Orange">Orange</MenuItem>
                    <MenuItem value="Red">Red</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
  
              {/* Comparación con un Jugador Pro */}
              <Grid item xs={12}>
                <TextField
                  label="Comparación con un Jugador Pro"
                  name="comparacionPro"
                  value={editablePlayer.comparacionPro || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          ) : (
            // READ mode: skip empty
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {!!player.poste && (
                <Typography>
                  <strong>Posición:</strong> {player.poste}
                </Typography>
              )}
              {!!player.mano && (
                <Typography>
                  <strong>Mano Dominante:</strong> {player.mano}
                </Typography>
              )}
              {!!player.shootingRange && (
                <Typography>
                  <strong>Shooting Range:</strong> {player.shootingRange}
                </Typography>
              )}
              {!!player.rolOfensivo && (
                <Typography>
                  <strong>Perfil Ofensivo:</strong> {player.rolOfensivo}
                </Typography>
              )}
              {!!player.rolDefensivo && (
                <Typography>
                  <strong>Perfil Defensivo:</strong> {player.rolDefensivo}
                </Typography>
              )}
              {!!player.jeJiType && (
                <Typography>
                  <strong>Skills JE/JI:</strong> {player.jeJiType}
                </Typography>
              )}
              {!!player.jeJiAttributes?.length && (
                <Typography>
                  <strong>Skills ({player.jeJiType}):</strong>{' '}
                  {player.jeJiAttributes.join(', ')}
                </Typography>
              )}
              {!!player.nivel && (
                <Typography>
                  <strong>Current VBC Rating:</strong> {player.nivel}
                </Typography>
              )}
              {!!player.proyeccion && (
                <Typography>
                  <strong>Proyección VBC Rating:</strong> {player.proyeccion}
                </Typography>
              )}
              {!!player.comportamiento && (
                <Typography>
                  <strong>Flag Behaviour:</strong> {player.comportamiento}
                </Typography>
              )}
              {!!player.injury && (
                <Typography>
                  <strong>Injury Prone:</strong> {player.injury}
                </Typography>
              )}
              {!!player.comparacionPro && (
                <Typography>
                  <strong>Comparación con un Jugador Pro:</strong>{' '}
                  {player.comparacionPro}
                </Typography>
              )}
            </Box>
          )}
        </MobileSection>
      );
    }
  
    // DESKTOP
    return (
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          2. Características del Jugador
        </Typography>
        {isEditing ? (
          <Grid container spacing={2}>
            {/* Posición */}
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Posición</InputLabel>
                <Select
                  name="poste"
                  value={editablePlayer.poste || ''}
                  onChange={handleChange}
                  label="Posición"
                  endAdornment={
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditablePlayer((prev) => ({ ...prev, poste: '' }));
                      }}
                      sx={{
                        visibility: editablePlayer.poste ? 'visible' : 'hidden',
                        position: 'absolute',
                        right: 32
                      }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  }
                >
                  <MenuItem value="Base">Base</MenuItem>
                  <MenuItem value="Escolta">Escolta</MenuItem>
                  <MenuItem value="Alero">Alero</MenuItem>
                  <MenuItem value="Ala-Pívot">Ala-Pívot</MenuItem>
                  <MenuItem value="Pívot">Pívot</MenuItem>
                </Select>
              </FormControl>
            </Grid>
  
            {/* Mano Dominante */}
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Mano Dominante</InputLabel>
                <Select
                  name="mano"
                  value={editablePlayer.mano || ''}
                  onChange={handleChange}
                  label="Mano Dominante"
                  endAdornment={
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditablePlayer((prev) => ({ ...prev, mano: '' }));
                      }}
                      sx={{
                        visibility: editablePlayer.mano ? 'visible' : 'hidden',
                        position: 'absolute',
                        right: 32
                      }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  }
                >
                  <MenuItem value="Derecha">Derecha</MenuItem>
                  <MenuItem value="Izquierda">Izquierda</MenuItem>
                  <MenuItem value="Ambas">Ambas</MenuItem>
                </Select>
              </FormControl>
            </Grid>
  
            {/* Shooting Range */}
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Shooting Range</InputLabel>
                <Select
                  name="shootingRange"
                  value={editablePlayer.shootingRange || ''}
                  onChange={handleChange}
                  label="Shooting Range"
                  endAdornment={
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditablePlayer((prev) => ({
                          ...prev,
                          shootingRange: ''
                        }));
                      }}
                      sx={{
                        visibility: editablePlayer.shootingRange ? 'visible' : 'hidden',
                        position: 'absolute',
                        right: 32
                      }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  }
                >
                  <MenuItem value="Rim">Rim</MenuItem>
                  <MenuItem value="Mid-Range">Mid-Range</MenuItem>
                  <MenuItem value="3Pts">3Pts</MenuItem>
                </Select>
              </FormControl>
            </Grid>
  
            {/* Perfil Ofensivo / Perfil Defensivo */}
            {/* Perfil Ofensivo */}
<Grid item xs={12} md={6}>
  <FormControl fullWidth>
    <InputLabel>Perfil Ofensivo</InputLabel>
    <Select
      multiple
      name="rolOfensivo"
      value={
        editablePlayer.rolOfensivo
          ? editablePlayer.rolOfensivo.split(',')
          : []
      }
      onChange={(e) =>
        setEditablePlayer((prev) => ({
          ...prev,
          rolOfensivo: e.target.value.join(',')
        }))
      }
      renderValue={(selected) => selected.join(', ')}
      endAdornment={
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setEditablePlayer((prev) => ({
              ...prev,
              rolOfensivo: ''
            }));
          }}
          sx={{
            visibility: editablePlayer.rolOfensivo ? 'visible' : 'hidden',
            position: 'absolute',
            right: 32
          }}
          size="small"
        >
          <Clear fontSize="small" />
        </IconButton>
      }
    >
      {/* === GUARD === */}
      <MenuItem disabled divider sx={{ pointerEvents: 'none', fontWeight: 600 }}>
        Guard
      </MenuItem>
      <MenuItem value="Generador con balón">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Generador con balón')
          }
        />
        <ListItemText primary="Generador con balón" />
      </MenuItem>
      <MenuItem value="Generador secundario con balón">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Generador secundario con balón')
          }
        />
        <ListItemText primary="Generador secundario con balón" />
      </MenuItem>
      <MenuItem value="Anotador Dominante">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Anotador Dominante')
          }
        />
        <ListItemText primary="Anotador Dominante" />
      </MenuItem>
      <MenuItem value="Base Director">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Base Director')
          }
        />
        <ListItemText primary="Base Director" />
      </MenuItem>
      <MenuItem value="Tirador Especialista">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Tirador Especialista')
          }
        />
        <ListItemText primary="Tirador Especialista" />
      </MenuItem>

      {/* === WING === */}
      <MenuItem disabled divider sx={{ pointerEvents: 'none', fontWeight: 600 }}>
        Wing
      </MenuItem>
      <MenuItem value="Alero Generador">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Alero Generador')
          }
        />
        <ListItemText primary="Alero Generador" />
      </MenuItem>
      <MenuItem value="Ala Versátil">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Ala Versátil')
          }
        />
        <ListItemText primary="Ala Versátil" />
      </MenuItem>
      <MenuItem value="Alero Fuerte">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Alero Fuerte')
          }
        />
        <ListItemText primary="Alero Fuerte" />
      </MenuItem>
      <MenuItem value="Alero Slasher">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Alero Slasher')
          }
        />
        <ListItemText primary="Alero Slasher" />
      </MenuItem>
      <MenuItem value="Alero Spot-up">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Alero Spot-up')
          }
        />
        <ListItemText primary="Alero Spot-up" />
      </MenuItem>
      <MenuItem value="3&D">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('3&D')
          }
        />
        <ListItemText primary="3&D" />
      </MenuItem>

      {/* === FORWARD === */}
      <MenuItem disabled divider sx={{ pointerEvents: 'none', fontWeight: 600 }}>
        Forward
      </MenuItem>
      <MenuItem value="Ala-Pívot Tradicional">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Ala-Pívot Tradicional')
          }
        />
        <ListItemText primary="Ala-Pívot Tradicional" />
      </MenuItem>
      <MenuItem value="Skilled Forward">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Skilled Forward')
          }
        />
        <ListItemText primary="Skilled Forward" />
      </MenuItem>
      <MenuItem value="4 Abierto">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('4 Abierto')
          }
        />
        <ListItemText primary="4 Abierto" />
      </MenuItem>

      {/* === BIG === */}
      <MenuItem disabled divider sx={{ pointerEvents: 'none', fontWeight: 600 }}>
        Big
      </MenuItem>
      <MenuItem value="Pívot Generador">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Pívot Generador')
          }
        />
        <ListItemText primary="Pívot Generador" />
      </MenuItem>
      <MenuItem value="Pívot Versátil">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Pívot Versátil')
          }
        />
        <ListItemText primary="Pívot Versátil" />
      </MenuItem>
      <MenuItem value="Pívot Mid-Range">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Pívot Mid-Range')
          }
        />
        <ListItemText primary="Pívot Mid-Range" />
      </MenuItem>
      <MenuItem value="5 Abierto">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('5 Abierto')
          }
        />
        <ListItemText primary="5 Abierto" />
      </MenuItem>
      <MenuItem value="Anotador en el poste bajo">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Anotador en el poste bajo')
          }
        />
        <ListItemText primary="Anotador en el poste bajo" />
      </MenuItem>
      <MenuItem value="Pívot finalizador cerca del aro">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Pívot finalizador cerca del aro')
          }
        />
        <ListItemText primary="Pívot finalizador cerca del aro" />
      </MenuItem>
      <MenuItem value="Pívot de bloqueo y continuación">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Pívot de bloqueo y continuación')
          }
        />
        <ListItemText primary="Pívot de bloqueo y continuación" />
      </MenuItem>
      <MenuItem value="Rol Ofensivo Marginal">
        <Checkbox
          checked={
            editablePlayer.rolOfensivo &&
            editablePlayer.rolOfensivo.split(',').includes('Rol Ofensivo Marginal')
          }
        />
        <ListItemText primary="Rol Ofensivo Marginal" />
      </MenuItem>
    </Select>
  </FormControl>
</Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Perfil Defensivo</InputLabel>
                <Select
                  multiple
                  name="rolDefensivo"
                  value={
                    editablePlayer.rolDefensivo
                      ? editablePlayer.rolDefensivo.split(',')
                      : []
                  }
                  onChange={(e) =>
                    setEditablePlayer((prev) => ({
                      ...prev,
                      rolDefensivo: e.target.value.join(',')
                    }))
                  }
                  renderValue={(selected) => selected.join(', ')}
                  endAdornment={
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditablePlayer((prev) => ({
                          ...prev,
                          rolDefensivo: ''
                        }));
                      }}
                      sx={{
                        visibility: editablePlayer.rolDefensivo
                          ? 'visible'
                          : 'hidden',
                        position: 'absolute',
                        right: 32
                      }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  }
                >
                  {defensiveRoles.map((role) => (
                    <MenuItem key={role} value={role}>
                      <Checkbox
                        checked={
                          editablePlayer.rolDefensivo &&
                          editablePlayer.rolDefensivo.split(',').includes(role)
                        }
                      />
                      <ListItemText primary={role} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
  
            {/* Skills JE/JI */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Skills JE/JI</InputLabel>
                <Select
                  name="jeJiType"
                  value={editablePlayer.jeJiType || ''}
                  onChange={(e) => {
                    setEditablePlayer((prev) => ({
                      ...prev,
                      jeJiType: e.target.value,
                      jeJiAttributes: []
                    }));
                  }}
                  label="Skills JE/JI"
                  endAdornment={
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditablePlayer((prev) => ({
                          ...prev,
                          jeJiType: '',
                          jeJiAttributes: []
                        }));
                      }}
                      sx={{
                        visibility: editablePlayer.jeJiType ? 'visible' : 'hidden',
                        position: 'absolute',
                        right: 32
                      }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  }
                >
                  <MenuItem value="JE">JE (Juego Exterior)</MenuItem>
                  <MenuItem value="JI">JI (Juego Interior)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
  
            {/* Skills JE/JI Attributes */}
            {editablePlayer.jeJiType && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>
                    Skills {editablePlayer.jeJiType === 'JE' ? 'Exterior' : 'Interior'}
                  </InputLabel>
                  <Select
                    multiple
                    value={editablePlayer.jeJiAttributes || []}
                    onChange={(e) =>
                      setEditablePlayer((prev) => ({
                        ...prev,
                        jeJiAttributes: e.target.value
                      }))
                    }
                    renderValue={(selected) => selected.join(', ')}
                    endAdornment={
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditablePlayer((prev) => ({
                            ...prev,
                            jeJiAttributes: []
                          }));
                        }}
                        sx={{
                          visibility: editablePlayer.jeJiAttributes?.length
                            ? 'visible'
                            : 'hidden',
                          position: 'absolute',
                          right: 32
                        }}
                        size="small"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    }
                  >
                    {(editablePlayer.jeJiType === 'JE'
                      ? JEAttributes
                      : JIAttributes
                    ).map((attr) => (
                      <MenuItem key={attr} value={attr}>
                        <Checkbox
                          checked={editablePlayer.jeJiAttributes.includes(attr)}
                        />
                        <ListItemText primary={attr} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
  
            {/* Current VBC Rating / Proyección VBC Rating */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Current VBC Rating</InputLabel>
                <Select
                  name="nivel"
                  value={editablePlayer.nivel || ''}
                  onChange={handleChange}
                  label="Current VBC Rating"
                  endAdornment={
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditablePlayer((prev) => ({
                          ...prev,
                          nivel: ''
                        }));
                      }}
                      sx={{
                        visibility: editablePlayer.nivel ? 'visible' : 'hidden',
                        position: 'absolute',
                        right: 32
                      }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  }
                >
                  {/* Proyectos */}
                  <MenuItem value="Proyecto Nivel Bajo">Proyecto Nivel Bajo</MenuItem>
                  <MenuItem value="Proyecto Nivel Medio">Proyecto Nivel Medio</MenuItem>
                  <MenuItem value="Proyecto Nivel Bueno">Proyecto Nivel Bueno</MenuItem>
                  <MenuItem value="Proyecto Top">Proyecto Top</MenuItem>
                  <MenuItem value="Futura Estrella">Futura Estrella</MenuItem>
  
                  {/* EBA */}
                  <MenuItem value="EBA Nivel Bajo (10-20)">EBA Nivel Bajo (10-20)</MenuItem>
                  <MenuItem value="EBA Nivel Alto (20-30)">EBA Nivel Alto (20-30)</MenuItem>
  
                  {/* LEB */}
                  <MenuItem value="LEB Nivel Bajo (30-40)">LEB Nivel Bajo (30-40)</MenuItem>
                  <MenuItem value="LEB Nivel Alto (40-50)">LEB Nivel Alto (40-50)</MenuItem>
  
                  {/* ACB */}
                  <MenuItem value="ACB Rotación (50-60)">ACB Rotación (50-60)</MenuItem>
  
                  {/* BCL/Eurocup */}
                  <MenuItem value="Bottom BCL/Eurocup (60-65)">Bottom BCL/Eurocup (60-65)</MenuItem>
                  <MenuItem value="Mid BCL/Eurocup (65-70)">Mid BCL/Eurocup (65-70)</MenuItem>
                  <MenuItem value="Top BCL/Eurocup (70-75)">Top BCL/Eurocup (70-75)</MenuItem>
  
                  {/* Euroliga */}
                  <MenuItem value="EL Nivel Medio (75-80)">EL Nivel Medio (75-80)</MenuItem>
                  <MenuItem value="EL Top (80-85)">EL Top (80-85)</MenuItem>
  
                  {/* NBA */}
                  <MenuItem value="NBA Rotación (85-90)">NBA Rotación (85-90)</MenuItem>
                  <MenuItem value="NBA All-Star (95-100)">NBA All-Star (95-100)</MenuItem>
  
                  {/* College */}
                  <MenuItem value="College Nivel Bajo">College Nivel Bajo</MenuItem>
                  <MenuItem value="College Nivel Medio">College Nivel Medio</MenuItem>
                  <MenuItem value="College Top">College Top</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Proyección VBC Rating</InputLabel>
                <Select
                  name="proyeccion"
                  value={editablePlayer.proyeccion || ''}
                  onChange={handleChange}
                  label="Proyección VBC Rating"
                  endAdornment={
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditablePlayer((prev) => ({
                          ...prev,
                          proyeccion: ''
                        }));
                      }}
                      sx={{
                        visibility: editablePlayer.proyeccion ? 'visible' : 'hidden',
                        position: 'absolute',
                        right: 32
                      }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  }
                >
                  <MenuItem value="Proyecto Nivel Bajo">Proyecto Nivel Bajo</MenuItem>
                  <MenuItem value="Proyecto Nivel Medio">Proyecto Nivel Medio</MenuItem>
                  <MenuItem value="Proyecto Nivel Bueno">Proyecto Nivel Bueno</MenuItem>
                  <MenuItem value="Proyecto Top">Proyecto Top</MenuItem>
                  <MenuItem value="Futura Estrella">Futura Estrella</MenuItem>
  
                  {/* EBA */}
                  <MenuItem value="EBA Nivel Bajo (10-20)">EBA Nivel Bajo (10-20)</MenuItem>
                  <MenuItem value="EBA Nivel Alto (20-30)">EBA Nivel Alto (20-30)</MenuItem>
  
                  {/* LEB */}
                  <MenuItem value="LEB Nivel Bajo (30-40)">LEB Nivel Bajo (30-40)</MenuItem>
                  <MenuItem value="LEB Nivel Alto (40-50)">LEB Nivel Alto (40-50)</MenuItem>
  
                  {/* ACB */}
                  <MenuItem value="ACB Rotación (50-60)">ACB Rotación (50-60)</MenuItem>
  
                  {/* BCL/Eurocup */}
                  <MenuItem value="Bottom BCL/Eurocup (60-65)">Bottom BCL/Eurocup (60-65)</MenuItem>
                  <MenuItem value="Mid BCL/Eurocup (65-70)">Mid BCL/Eurocup (65-70)</MenuItem>
                  <MenuItem value="Top BCL/Eurocup (70-75)">Top BCL/Eurocup (70-75)</MenuItem>
  
                  {/* Euroliga */}
                  <MenuItem value="EL Nivel Medio (75-80)">EL Nivel Medio (75-80)</MenuItem>
                  <MenuItem value="EL Top (80-85)">EL Top (80-85)</MenuItem>
  
                  {/* NBA */}
                  <MenuItem value="NBA Rotación (85-90)">NBA Rotación (85-90)</MenuItem>
                  <MenuItem value="NBA All-Star (95-100)">NBA All-Star (95-100)</MenuItem>
  
                  {/* College */}
                  <MenuItem value="College Nivel Bajo">College Nivel Bajo</MenuItem>
                  <MenuItem value="College Nivel Medio">College Nivel Medio</MenuItem>
                  <MenuItem value="College Top">College Top</MenuItem>
                </Select>
              </FormControl>
            </Grid>
  
            {/* Flag Behaviour / Injury Prone */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Flag Behaviour</InputLabel>
                <Select
                  name="comportamiento"
                  value={editablePlayer.comportamiento || ''}
                  onChange={handleChange}
                  label="Flag Behaviour"
                  endAdornment={
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditablePlayer((prev) => ({
                          ...prev,
                          comportamiento: ''
                        }));
                      }}
                      sx={{
                        visibility: editablePlayer.comportamiento ? 'visible' : 'hidden',
                        position: 'absolute',
                        right: 32
                      }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  }
                >
                  <MenuItem value="Green">Green</MenuItem>
                  <MenuItem value="Orange">Orange</MenuItem>
                  <MenuItem value="Red">Red</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Injury Prone</InputLabel>
                <Select
                  name="injury"
                  value={editablePlayer.injury || ''}
                  onChange={handleChange}
                  label="Injury Prone"
                  endAdornment={
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditablePlayer((prev) => ({
                          ...prev,
                          injury: ''
                        }));
                      }}
                      sx={{
                        visibility: editablePlayer.injury ? 'visible' : 'hidden',
                        position: 'absolute',
                        right: 32
                      }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  }
                >
                  <MenuItem value="Green">Green</MenuItem>
                  <MenuItem value="Orange">Orange</MenuItem>
                  <MenuItem value="Red">Red</MenuItem>
                </Select>
              </FormControl>
            </Grid>
  
            {/* Comparación con un Jugador Pro */}
            <Grid item xs={12}>
              <TextField
                label="Comparación con un Jugador Pro"
                name="comparacionPro"
                value={editablePlayer.comparacionPro || ''}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
        ) : (
          // READ
          <Box sx={{ mt: 1 }}>
            {!!player.poste && (
              <Typography sx={{ display: 'flex' }}>
                <strong style={{ minWidth: 'auto' }}>Posición:&nbsp;</strong>{' '}
                {player.poste}
              </Typography>
            )}
            {!!player.mano && (
              <Typography sx={{ display: 'flex' }}>
                <strong style={{ minWidth: 'auto' }}>Mano Dominante:&nbsp;</strong>{' '}
                {player.mano}
              </Typography>
            )}
            {!!player.shootingRange && (
              <Typography sx={{ display: 'flex' }}>
                <strong style={{ minWidth: 'auto' }}>Shooting Range:&nbsp;</strong>{' '}
                {player.shootingRange}
              </Typography>
            )}
  
            {(!!player.rolOfensivo || !!player.rolDefensivo) && (
              <Box sx={{ display: 'flex', gap: 4 }}>
                {!!player.rolOfensivo && (
                  <Typography sx={{ display: 'flex', flex: 1 }}>
                    <strong style={{ minWidth: 'auto' }}>
                      Perfil Ofensivo:&nbsp;
                    </strong>{' '}
                    {player.rolOfensivo}
                  </Typography>
                )}
                {!!player.rolDefensivo && (
                  <Typography sx={{ display: 'flex', flex: 1 }}>
                    <strong style={{ minWidth: 'auto' }}>
                      Perfil Defensivo:&nbsp;
                    </strong>{' '}
                    {player.rolDefensivo}
                  </Typography>
                )}
              </Box>
            )}
  
            {(!!player.jeJiType || !!player.jeJiAttributes?.length) && (
              <Box sx={{ display: 'flex', gap: 4 }}>
                {!!player.jeJiType && (
                  <Typography sx={{ display: 'flex', flex: 1 }}>
                    <strong style={{ minWidth: 'auto' }}>
                      Skills JE/JI:&nbsp;
                    </strong>{' '}
                    {player.jeJiType}
                  </Typography>
                )}
                {!!player.jeJiAttributes?.length && (
                  <Typography sx={{ display: 'flex', flex: 1 }}>
                    <strong style={{ minWidth: 'auto' }}>
                      Skills ({player.jeJiType}):&nbsp;
                    </strong>{' '}
                    {player.jeJiAttributes.join(', ')}
                  </Typography>
                )}
              </Box>
            )}
  
            {(!!player.nivel || !!player.proyeccion) && (
              <Box sx={{ display: 'flex', gap: 4 }}>
                {!!player.nivel && (
                  <Typography sx={{ display: 'flex', flex: 1 }}>
                    <strong style={{ minWidth: 'auto' }}>
                      Current VBC Rating:&nbsp;
                    </strong>{' '}
                    {player.nivel}
                  </Typography>
                )}
                {!!player.proyeccion && (
                  <Typography sx={{ display: 'flex', flex: 1 }}>
                    <strong style={{ minWidth: 'auto' }}>
                      Proyección VBC Rating:&nbsp;
                    </strong>{' '}
                    {player.proyeccion}
                  </Typography>
                )}
              </Box>
            )}
  
            {(!!player.comportamiento || !!player.injury) && (
              <Box sx={{ display: 'flex', gap: 4 }}>
                {!!player.comportamiento && (
                  <Typography sx={{ display: 'flex', flex: 1 }}>
                    <strong style={{ minWidth: 'auto' }}>
                      Flag Behaviour:&nbsp;
                    </strong>{' '}
                    {player.comportamiento}
                  </Typography>
                )}
                {!!player.injury && (
                  <Typography sx={{ display: 'flex', flex: 1 }}>
                    <strong style={{ minWidth: 'auto' }}>
                      Injury Prone:&nbsp;
                    </strong>{' '}
                    {player.injury}
                  </Typography>
                )}
              </Box>
            )}
  
            {!!player.comparacionPro && (
              <Typography sx={{ display: 'flex' }}>
                <strong style={{ minWidth: 'auto' }}>
                  Comparación con un Jugador Pro:&nbsp;
                </strong>{' '}
                {player.comparacionPro}
              </Typography>
            )}
          </Box>
        )}
      </Paper>
    );
  };
  

  /**
   * 3. SITUACIÓN ACTUAL
   *    (On retire Licencia d'ici, puisqu'on l'a déplacé en Información Básica)
   *    Selección Nacional
   *    Equipo Actual
   *    Competición Europea, Liga Principal, Liga Secundaria
   */
  
  const renderSituacionActual = () => {
    // MOBILE
    if (isMobile) {
      return (
        <MobileSection title="3. Situación Actual">
          {isEditing ? (
            <Grid container spacing={2}>
              {/* Selección Nacional */}
              <Grid item xs={12}>
                <SelectSelectionNational
                  value={editablePlayer.seleccionNacional || ''}
                  onChange={(e) =>
                    setEditablePlayer((prev) => ({
                      ...prev,
                      seleccionNacional: e.target.value,
                    }))
                  }
                />
              </Grid>
  
              {/* Equipo Actual */}
              <Grid item xs={12}>
                <Autocomplete
                  freeSolo
                  options={teams}
                  inputValue={editablePlayer.equipo || ''}
                  onInputChange={(event, newInputValue) => {
                    setEditablePlayer((prev) => ({ ...prev, equipo: newInputValue }));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Equipo Actual" fullWidth />
                  )}
                />
              </Grid>
  
              {/* Competición Europea, Liga Principal, Liga Secundaria */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Competición Europea</InputLabel>
                  <Select
                    name="competicionEuropa"
                    value={editablePlayer.competicionEuropa || ''}
                    onChange={handleChange}
                    label="Competición Europa"
                    endAdornment={
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditablePlayer((prev) => ({
                            ...prev,
                            competicionEuropa: '',
                          }));
                        }}
                        sx={{
                          visibility: editablePlayer.competicionEuropa
                            ? 'visible'
                            : 'hidden',
                          position: 'absolute',
                          right: 32,
                        }}
                        size="small"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    }
                  >
                    <MenuItem value="EuroLeague">EuroLeague</MenuItem>
                    <MenuItem value="EuroCup">EuroCup</MenuItem>
                    <MenuItem value="FIBA BCL">FIBA BCL</MenuItem>
                    <MenuItem value="FIBA EuropeCup">FIBA EuropeCup</MenuItem>
                    <MenuItem value="Lat-Est">Lat-Est</MenuItem>
                    <MenuItem value="ENBL">ENBL</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
  
              <Grid item xs={12}>
                <Autocomplete
                  freeSolo
                  options={leagues1}
                  inputValue={editablePlayer.liga1 || ''}
                  onInputChange={(event, newInputValue) => {
                    setEditablePlayer((prev) => ({ ...prev, liga1: newInputValue }));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Liga Principal" fullWidth />
                  )}
                />
              </Grid>
  
              <Grid item xs={12}>
                <Autocomplete
                  freeSolo
                  options={leagues2}
                  inputValue={editablePlayer.liga2 || ''}
                  onInputChange={(event, newInputValue) => {
                    setEditablePlayer((prev) => ({ ...prev, liga2: newInputValue }));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Liga Secundaria" fullWidth />
                  )}
                />
              </Grid>
            </Grid>
          ) : (
            // READ
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {!!player.seleccionNacional && (
                <Typography>
                  <strong>Selección Nacional:</strong> {player.seleccionNacional}
                </Typography>
              )}
              {!!player.equipo && (
                <Typography>
                  <strong>Equipo Actual:</strong> {player.equipo}
                </Typography>
              )}
              {(!!player.competicionEuropa || !!player.liga1 || !!player.liga2) && (
                <Box>
                  {!!player.competicionEuropa && (
                    <Typography>
                      <strong>Competición Europea:</strong>{' '}
                      {player.competicionEuropa}
                    </Typography>
                  )}
                  {!!player.liga1 && (
                    <Typography>
                      <strong>Liga Principal:</strong> {player.liga1}
                    </Typography>
                  )}
                  {!!player.liga2 && (
                    <Typography>
                      <strong>Liga Secundaria:</strong> {player.liga2}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          )}
        </MobileSection>
      );
    }
  
    // DESKTOP
    return (
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          3. Situación Actual
        </Typography>
        {isEditing ? (
          <Grid container spacing={2}>
            {/* Selección Nacional */}
            <Grid item xs={12} md={4}>
              <SelectSelectionNational
                value={editablePlayer.seleccionNacional || ''}
                onChange={(e) =>
                  setEditablePlayer((prev) => ({
                    ...prev,
                    seleccionNacional: e.target.value,
                  }))
                }
              />
            </Grid>
  
            {/* Equipo Actual */}
            <Grid item xs={12} md={4}>
              <Autocomplete
                freeSolo
                options={teams}
                inputValue={editablePlayer.equipo || ''}
                onInputChange={(event, newInputValue) => {
                  setEditablePlayer((prev) => ({ ...prev, equipo: newInputValue }));
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Equipo Actual" fullWidth />
                )}
              />
            </Grid>
  
            {/* Competición Europea */}
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Competición Europea</InputLabel>
                <Select
                  name="competicionEuropa"
                  value={editablePlayer.competicionEuropa || ''}
                  onChange={handleChange}
                  label="Competición Europea"
                  endAdornment={
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditablePlayer((prev) => ({
                          ...prev,
                          competicionEuropa: '',
                        }));
                      }}
                      sx={{
                        visibility: editablePlayer.competicionEuropa
                          ? 'visible'
                          : 'hidden',
                        position: 'absolute',
                        right: 32,
                      }}
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  }
                >
                  <MenuItem value="EuroLeague">EuroLeague</MenuItem>
                  <MenuItem value="EuroCup">EuroCup</MenuItem>
                  <MenuItem value="FIBA BCL">FIBA BCL</MenuItem>
                  <MenuItem value="FIBA EuropeCup">FIBA EuropeCup</MenuItem>
                  <MenuItem value="Lat-Est">Lat-Est</MenuItem>
                  <MenuItem value="ENBL">ENBL</MenuItem>
                </Select>
              </FormControl>
            </Grid>
  
            {/* Liga Principal / Liga Secundaria */}
            <Grid item xs={12} md={4}>
              <Autocomplete
                freeSolo
                options={leagues1}
                inputValue={editablePlayer.liga1 || ''}
                onInputChange={(event, newInputValue) => {
                  setEditablePlayer((prev) => ({ ...prev, liga1: newInputValue }));
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Liga Principal" fullWidth />
                )}
              />
            </Grid>
  
            <Grid item xs={12} md={4}>
              <Autocomplete
                freeSolo
                options={leagues2}
                inputValue={editablePlayer.liga2 || ''}
                onInputChange={(event, newInputValue) => {
                  setEditablePlayer((prev) => ({ ...prev, liga2: newInputValue }));
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Liga Secundaria" fullWidth />
                )}
              />
            </Grid>
          </Grid>
        ) : (
          // READ
          <Box sx={{ mt: 1 }}>
            {!!player.seleccionNacional && (
              <Typography sx={{ display: 'flex' }}>
                <strong style={{ minWidth: 'auto' }}>
                  Selección Nacional:&nbsp;
                </strong>{' '}
                {player.seleccionNacional}
              </Typography>
            )}
            {!!player.equipo && (
              <Typography sx={{ display: 'flex' }}>
                <strong style={{ minWidth: 'auto' }}>
                  Equipo Actual:&nbsp;
                </strong>{' '}
                {player.equipo}
              </Typography>
            )}
            {(!!player.competicionEuropa || !!player.liga1 || !!player.liga2) && (
              <Box sx={{ display: 'flex', gap: 4 }}>
                {!!player.competicionEuropa && (
                  <Typography sx={{ display: 'flex' }}>
                    <strong style={{ minWidth: 'auto' }}>
                      Competición Europea:&nbsp;
                    </strong>{' '}
                    {player.competicionEuropa}
                  </Typography>
                )}
                {!!player.liga1 && (
                  <Typography sx={{ display: 'flex' }}>
                    <strong style={{ minWidth: 'auto' }}>
                      Liga Principal:&nbsp;
                    </strong>{' '}
                    {player.liga1}
                  </Typography>
                )}
                {!!player.liga2 && (
                  <Typography sx={{ display: 'flex' }}>
                    <strong style={{ minWidth: 'auto' }}>
                      Liga Secundaria:&nbsp;
                    </strong>{' '}
                    {player.liga2}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        )}
      </Paper>
    );
  };
  

  /**
   * 4. EXPERiencia PROFESIONAL
   *    Año del Draft, Posición en el Draft, Equipo Pre-Draft
   *    PIT, Eurocamp
   *    Experiencia NBA, Experiencia ACB, Experiencia Europa
   */
  const renderExperienciaProfesional = () => {
    // MOBILE
    if (isMobile) {
      return (
        <MobileSection title="4. Experiencia Profesional">
          {isEditing ? (
            <Grid container spacing={2}>
              {/* Año del Draft, Posición Draft, Equipo Pre-Draft */}
              <Grid item xs={6}>
                <TextField
                  label="Año del Draft"
                  name="añoDraft"
                  type="number"
                  value={editablePlayer.añoDraft || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
  <FormControl fullWidth>
    <InputLabel>Posición en el Draft</InputLabel>
    <Select
      name="posicionDraft"
      value={editablePlayer.posicionDraft || ''}
      onChange={handleChange}
      label="Posición en el Draft"
      endAdornment={
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setEditablePlayer((prev) => ({ ...prev, posicionDraft: '' }));
          }}
          sx={{ 
            visibility: editablePlayer.posicionDraft ? 'visible' : 'hidden',
            position: 'absolute',
            right: 32
          }}
          size="small"
        >
          <Clear fontSize="small" />
        </IconButton>
      }
    >
      {/* 1st Round */}
      {[...Array(30)].map((_, index) => (
        <MenuItem key={index + 1} value={`1st Round, #${index + 1}`}>
          1st Round, #{index + 1}
        </MenuItem>
      ))}
      {/* 2nd Round */}
      {[...Array(30)].map((_, index) => (
        <MenuItem key={index + 31} value={`2nd Round, #${index + 31}`}>
          2nd Round, #{index + 31}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>
              <Grid item xs={12}>
                <Autocomplete
                  freeSolo
                  options={teams}
                  inputValue={editablePlayer.equipoPreDraft || ''}
                  onInputChange={(event, newInputValue) => {
                    setEditablePlayer((prev) => ({
                      ...prev,
                      equipoPreDraft: newInputValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Pre-Draft Team" fullWidth />
                  )}
                />
              </Grid>
  
              {/* PIT, Eurocamp, SL Vegas (sur la même ligne) */}
              <Grid item xs={4}>
                <TextField
                  label="PIT"
                  name="pit"
                  type="number"
                  value={editablePlayer.pit || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Eurocamp"
                  name="eurocamp"
                  type="number"
                  value={editablePlayer.eurocamp || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="SL Vegas"
                  name="slvegas" // nom en base: slvegas
                  type="number"
                  value={editablePlayer.slvegas || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
  
              {/* 5 Selects sur la même ligne : NBA, ACB, Europa, Fuera, NCAA */}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {/* Experiencia NBA */}
                  <Grid item xs={12} sm={6} md={2.4}>
                    <FormControl fullWidth>
                      <InputLabel>Experiencia NBA</InputLabel>
                      <Select
                        name="nbaExperience"
                        value={editablePlayer.nbaExperience || ''}
                        onChange={handleChange}
                        label="Experiencia NBA"
                        endAdornment={
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditablePlayer((prev) => ({
                                ...prev,
                                nbaExperience: '',
                              }));
                            }}
                            sx={{
                              visibility: editablePlayer.nbaExperience
                                ? 'visible'
                                : 'hidden',
                              position: 'absolute',
                              right: 32,
                            }}
                            size="small"
                          >
                            <Clear fontSize="small" />
                          </IconButton>
                        }
                      >
                        <MenuItem value="SI">SI</MenuItem>
                        <MenuItem value="NO">NO</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
  
                  {/* Experiencia ACB */}
                  <Grid item xs={12} sm={6} md={2.4}>
                    <FormControl fullWidth>
                      <InputLabel>Experiencia ACB</InputLabel>
                      <Select
                        name="experienciaAcb"
                        value={editablePlayer.experienciaAcb || ''}
                        onChange={handleChange}
                        label="Experiencia ACB"
                        endAdornment={
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditablePlayer((prev) => ({
                                ...prev,
                                experienciaAcb: '',
                              }));
                            }}
                            sx={{
                              visibility: editablePlayer.experienciaAcb
                                ? 'visible'
                                : 'hidden',
                              position: 'absolute',
                              right: 32,
                            }}
                            size="small"
                          >
                            <Clear fontSize="small" />
                          </IconButton>
                        }
                      >
                        <MenuItem value="SI">SI</MenuItem>
                        <MenuItem value="NO">NO</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
  
                  {/* Experiencia Europa */}
                  <Grid item xs={12} sm={6} md={2.4}>
                    <FormControl fullWidth>
                      <InputLabel>Experiencia Europa</InputLabel>
                      <Select
                        name="experiencaEuropa"
                        value={editablePlayer.experiencaEuropa || ''}
                        onChange={handleChange}
                        label="Experiencia Europa"
                        endAdornment={
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditablePlayer((prev) => ({
                                ...prev,
                                experiencaEuropa: '',
                              }));
                            }}
                            sx={{
                              visibility: editablePlayer.experiencaEuropa
                                ? 'visible'
                                : 'hidden',
                              position: 'absolute',
                              right: 32,
                            }}
                            size="small"
                          >
                            <Clear fontSize="small" />
                          </IconButton>
                        }
                      >
                        <MenuItem value="SI">SI</MenuItem>
                        <MenuItem value="NO">NO</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
  
                  {/* Experiencia Fuera de su País (experienca_fuera) */}
                  <Grid item xs={12} sm={6} md={2.4}>
                    <FormControl fullWidth>
                      <InputLabel>Experiencia Fuera de su País</InputLabel>
                      <Select
                        name="experienca_fuera"
                        value={editablePlayer.experienca_fuera || ''}
                        onChange={handleChange}
                        label="Experiencia Fuera de su País"
                        endAdornment={
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditablePlayer((prev) => ({
                                ...prev,
                                experienca_fuera: '',
                              }));
                            }}
                            sx={{
                              visibility: editablePlayer.experienca_fuera
                                ? 'visible'
                                : 'hidden',
                              position: 'absolute',
                              right: 32,
                            }}
                            size="small"
                          >
                            <Clear fontSize="small" />
                          </IconButton>
                        }
                      >
                        <MenuItem value="SI">SI</MenuItem>
                        <MenuItem value="NO">NO</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
  
                  {/* Experiencia NCAA (experienca_ncaa) */}
                  <Grid item xs={12} sm={6} md={2.4}>
                    <FormControl fullWidth>
                      <InputLabel>Experiencia NCAA</InputLabel>
                      <Select
                        name="experienca_ncaa"
                        value={editablePlayer.experienca_ncaa || ''}
                        onChange={handleChange}
                        label="Experiencia NCAA"
                        endAdornment={
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditablePlayer((prev) => ({
                                ...prev,
                                experienca_ncaa: '',
                              }));
                            }}
                            sx={{
                              visibility: editablePlayer.experienca_ncaa
                                ? 'visible'
                                : 'hidden',
                              position: 'absolute',
                              right: 32,
                            }}
                            size="small"
                          >
                            <Clear fontSize="small" />
                          </IconButton>
                        }
                      >
                        <MenuItem value="SI">SI</MenuItem>
                        <MenuItem value="NO">NO</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            // READ
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {(!!player.añoDraft ||
                !!player.posicionDraft ||
                !!player.equipoPreDraft) && (
                <Box>
                  {!!player.añoDraft && (
                    <Typography>
                      <strong>Año del Draft:</strong> {player.añoDraft}
                    </Typography>
                  )}
                  {!!player.posicionDraft && (
                    <Typography>
                      <strong>Posición en el Draft:</strong> {player.posicionDraft}
                    </Typography>
                  )}
                  {!!player.equipoPreDraft && (
                    <Typography>
                      <strong>Pre-Draft Team:</strong> {player.equipoPreDraft}
                    </Typography>
                  )}
                </Box>
              )}
              {(!!player.pit || !!player.eurocamp || !!player.slvegas) && (
                <Box>
                  {!!player.pit && (
                    <Typography>
                      <strong>PIT:</strong> {player.pit}
                    </Typography>
                  )}
                  {!!player.eurocamp && (
                    <Typography>
                      <strong>Eurocamp:</strong> {player.eurocamp}
                    </Typography>
                  )}
                  {!!player.slvegas && (
                    <Typography>
                      <strong>SL Vegas:</strong> {player.slvegas}
                    </Typography>
                  )}
                </Box>
              )}
  
              {/* On affiche la 5-ligne : NBA, ACB, Europa, Fuera, NCAA */}
              {(!!player.nbaExperience ||
                !!player.experienciaAcb ||
                !!player.experiencaEuropa ||
                !!player.experienca_fuera ||
                !!player.experienca_ncaa) && (
                <Box>
                  {!!player.nbaExperience && (
                    <Typography>
                      <strong>Experiencia NBA:</strong> {player.nbaExperience}
                    </Typography>
                  )}
                  {!!player.experienciaAcb && (
                    <Typography>
                      <strong>Experiencia ACB:</strong> {player.experienciaAcb}
                    </Typography>
                  )}
                  {!!player.experiencaEuropa && (
                    <Typography>
                      <strong>Experiencia Europa:</strong>{' '}
                      {player.experiencaEuropa}
                    </Typography>
                  )}
                  {!!player.experienca_fuera && (
                    <Typography>
                      <strong>Experiencia Fuera de su País:</strong>{' '}
                      {player.experienca_fuera}
                    </Typography>
                  )}
                  {!!player.experienca_ncaa && (
                    <Typography>
                      <strong>Experiencia NCAA:</strong> {player.experienca_ncaa}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          )}
        </MobileSection>
      );
    }
  
    // DESKTOP
    return (
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          4. Experiencia Profesional
        </Typography>
        {isEditing ? (
          <Grid container spacing={2}>
            {/* Año del Draft, Posición, Equipo Pre-Draft */}
            <Grid item xs={12} md={4}>
              <TextField
                label="Año del Draft"
                name="añoDraft"
                type="number"
                value={editablePlayer.añoDraft || ''}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
  <FormControl fullWidth>
    <InputLabel>Posición en el Draft</InputLabel>
    <Select
      name="posicionDraft"
      value={editablePlayer.posicionDraft || ''}
      onChange={handleChange}
      label="Posición en el Draft"
      endAdornment={
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setEditablePlayer((prev) => ({ ...prev, posicionDraft: '' }));
          }}
          sx={{ 
            visibility: editablePlayer.posicionDraft ? 'visible' : 'hidden',
            position: 'absolute',
            right: 32
          }}
          size="small"
        >
          <Clear fontSize="small" />
        </IconButton>
      }
    >
      {/* 1st Round */}
      {[...Array(30)].map((_, index) => (
        <MenuItem key={index + 1} value={`1st Round, #${index + 1}`}>
          1st Round, #{index + 1}
        </MenuItem>
      ))}
      {/* 2nd Round */}
      {[...Array(30)].map((_, index) => (
        <MenuItem key={index + 31} value={`2nd Round, #${index + 31}`}>
          2nd Round, #{index + 31}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                freeSolo
                options={teams}
                inputValue={editablePlayer.equipoPreDraft || ''}
                onInputChange={(event, newInputValue) => {
                  setEditablePlayer((prev) => ({
                    ...prev,
                    equipoPreDraft: newInputValue,
                  }));
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Pre-Draft Team" fullWidth />
                )}
              />
            </Grid>
  
            {/* PIT, Eurocamp, SL Vegas (sur la même ligne) */}
            <Grid item xs={12} md={4}>
              <TextField
                label="PIT"
                name="pit"
                type="number"
                value={editablePlayer.pit || ''}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Eurocamp"
                name="eurocamp"
                type="number"
                value={editablePlayer.eurocamp || ''}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="SL Vegas"
                name="slvegas" // nom en base: slvegas
                type="number"
                value={editablePlayer.slvegas || ''}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
  
            {/* 5 Selects sur la même ligne : NBA, ACB, Europa, Fuera, NCAA */}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {/* Experiencia NBA */}
                <Grid item xs={12} sm={6} md={2.4}>
                  <FormControl fullWidth>
                    <InputLabel>Experiencia NBA</InputLabel>
                    <Select
                      name="nbaExperience"
                      value={editablePlayer.nbaExperience || ''}
                      onChange={handleChange}
                      label="Experiencia NBA"
                      endAdornment={
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditablePlayer((prev) => ({
                              ...prev,
                              nbaExperience: '',
                            }));
                          }}
                          sx={{
                            visibility: editablePlayer.nbaExperience
                              ? 'visible'
                              : 'hidden',
                            position: 'absolute',
                            right: 32,
                          }}
                          size="small"
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      }
                    >
                      <MenuItem value="SI">SI</MenuItem>
                      <MenuItem value="NO">NO</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
  
                {/* Experiencia ACB */}
                <Grid item xs={12} sm={6} md={2.4}>
                  <FormControl fullWidth>
                    <InputLabel>Experiencia ACB</InputLabel>
                    <Select
                      name="experienciaAcb"
                      value={editablePlayer.experienciaAcb || ''}
                      onChange={handleChange}
                      label="Experiencia ACB"
                      endAdornment={
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditablePlayer((prev) => ({
                              ...prev,
                              experienciaAcb: '',
                            }));
                          }}
                          sx={{
                            visibility: editablePlayer.experienciaAcb
                              ? 'visible'
                              : 'hidden',
                            position: 'absolute',
                            right: 32,
                          }}
                          size="small"
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      }
                    >
                      <MenuItem value="SI">SI</MenuItem>
                      <MenuItem value="NO">NO</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
  
                {/* Experiencia Europa */}
                <Grid item xs={12} sm={6} md={2.4}>
                  <FormControl fullWidth>
                    <InputLabel>Experiencia Europa</InputLabel>
                    <Select
                      name="experiencaEuropa"
                      value={editablePlayer.experiencaEuropa || ''}
                      onChange={handleChange}
                      label="Experiencia Europa"
                      endAdornment={
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditablePlayer((prev) => ({
                              ...prev,
                              experiencaEuropa: '',
                            }));
                          }}
                          sx={{
                            visibility: editablePlayer.experiencaEuropa
                              ? 'visible'
                              : 'hidden',
                            position: 'absolute',
                            right: 32,
                          }}
                          size="small"
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      }
                    >
                      <MenuItem value="SI">SI</MenuItem>
                      <MenuItem value="NO">NO</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
  
                {/* Experiencia Fuera de su País (experienca_fuera) */}
                <Grid item xs={12} sm={6} md={2.4}>
                  <FormControl fullWidth>
                    <InputLabel>Experiencia Fuera de su País</InputLabel>
                    <Select
                      name="experienca_fuera"
                      value={editablePlayer.experienca_fuera || ''}
                      onChange={handleChange}
                      label="Experiencia Fuera de su País"
                      endAdornment={
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditablePlayer((prev) => ({
                              ...prev,
                              experienca_fuera: '',
                            }));
                          }}
                          sx={{
                            visibility: editablePlayer.experienca_fuera
                              ? 'visible'
                              : 'hidden',
                            position: 'absolute',
                            right: 32,
                          }}
                          size="small"
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      }
                    >
                      <MenuItem value="SI">SI</MenuItem>
                      <MenuItem value="NO">NO</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
  
                {/* Experiencia NCAA (experienca_ncaa) */}
                <Grid item xs={12} sm={6} md={2.4}>
                  <FormControl fullWidth>
                    <InputLabel>Experiencia NCAA</InputLabel>
                    <Select
                      name="experienca_ncaa"
                      value={editablePlayer.experienca_ncaa || ''}
                      onChange={handleChange}
                      label="Experiencia NCAA"
                      endAdornment={
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditablePlayer((prev) => ({
                              ...prev,
                              experienca_ncaa: '',
                            }));
                          }}
                          sx={{
                            visibility: editablePlayer.experienca_ncaa
                              ? 'visible'
                              : 'hidden',
                            position: 'absolute',
                            right: 32,
                          }}
                          size="small"
                        >
                          <Clear fontSize="small" />
                        </IconButton>
                      }
                    >
                      <MenuItem value="SI">SI</MenuItem>
                      <MenuItem value="NO">NO</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            </Grid>
          ) : (
            // READ
            <Box sx={{ mt: 1 }}>
              {(!!player.añoDraft ||
                !!player.posicionDraft ||
                !!player.equipoPreDraft) && (
                <Box sx={{ display: 'flex', gap: 4 }}>
                  {!!player.añoDraft && (
                    <Typography sx={{ display: 'flex', flex: 1 }}>
                      <strong style={{ minWidth: 'auto' }}>
                        Año del Draft:&nbsp;
                      </strong>{' '}
                      {player.añoDraft}
                    </Typography>
                  )}
                  {!!player.posicionDraft && (
                    <Typography sx={{ display: 'flex', flex: 1 }}>
                      <strong style={{ minWidth: 'auto' }}>
                        Posición en el Draft:&nbsp;
                      </strong>{' '}
                      {player.posicionDraft}
                    </Typography>
                  )}
                  {!!player.equipoPreDraft && (
                    <Typography sx={{ display: 'flex', flex: 1 }}>
                      <strong style={{ minWidth: 'auto' }}>
                      Pre-Draft Team:&nbsp;
                      </strong>{' '}
                      {player.equipoPreDraft}
                    </Typography>
                  )}
                </Box>
              )}
  
              {(!!player.pit || !!player.eurocamp || !!player.slvegas) && (
                <Box sx={{ display: 'flex', gap: 4 }}>
                  {!!player.pit && (
                    <Box sx={{ display: 'flex', flex: 1 }}>
                      <Typography>
                        <strong style={{ minWidth: 'auto' }}>
                          PIT:&nbsp;
                        </strong>{' '}
                        {player.pit}
                      </Typography>
                    </Box>
                  )}
                  {!!player.eurocamp && (
                    <Box sx={{ display: 'flex', flex: 1 }}>
                      <Typography>
                        <strong style={{ minWidth: 'auto' }}>
                          Eurocamp:&nbsp;
                        </strong>{' '}
                        {player.eurocamp}
                      </Typography>
                    </Box>
                  )}
                  {!!player.slvegas && (
                    <Box sx={{ display: 'flex', flex: 1 }}>
                      <Typography>
                        <strong style={{ minWidth: 'auto' }}>
                          SL Vegas:&nbsp;
                        </strong>{' '}
                        {player.slvegas}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
  
              {(!!player.nbaExperience ||
                !!player.experienciaAcb ||
                !!player.experiencaEuropa ||
                !!player.experienca_fuera ||
                !!player.experienca_ncaa) && (
                <Box sx={{ display: 'flex', gap: 4 }}>
                  {!!player.nbaExperience && (
                    <Typography sx={{ display: 'flex', flex: 1 }}>
                      <strong style={{ minWidth: 'auto' }}>
                        Experiencia NBA:&nbsp;
                      </strong>{' '}
                      {player.nbaExperience}
                    </Typography>
                  )}
                  {!!player.experienciaAcb && (
                    <Typography sx={{ display: 'flex', flex: 1 }}>
                      <strong style={{ minWidth: 'auto' }}>
                        Experiencia ACB:&nbsp;
                      </strong>{' '}
                      {player.experienciaAcb}
                    </Typography>
                  )}
                  {!!player.experiencaEuropa && (
                    <Typography sx={{ display: 'flex', flex: 1 }}>
                      <strong style={{ minWidth: 'auto' }}>
                        Experiencia Europa:&nbsp;
                      </strong>{' '}
                      {player.experiencaEuropa}
                    </Typography>
                  )}
                  {!!player.experienca_fuera && (
                    <Typography sx={{ display: 'flex', flex: 1 }}>
                      <strong style={{ minWidth: 'auto' }}>
                        Experiencia Fuera de su País:&nbsp;
                      </strong>{' '}
                      {player.experienca_fuera}
                    </Typography>
                  )}
                  {!!player.experienca_ncaa && (
                    <Typography sx={{ display: 'flex', flex: 1 }}>
                      <strong style={{ minWidth: 'auto' }}>
                        Experiencia NCAA:&nbsp;
                      </strong>{' '}
                      {player.experienca_ncaa}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          )}
      </Paper>
    );
  };
  

  /**
   * 5. INFORMACIÓN CONTRACTUAL
   *    Agencia, Rango Salarial, Contrato
   */
 

const renderInformacionContractual = () => {
  // MOBILE
  if (isMobile) {
    return (
      <MobileSection title="5. Información Contractual">
        {isEditing ? (
          <Grid container spacing={2}>
            {/* Agencia */}
            <Grid item xs={12}>
              <Autocomplete
                freeSolo
                options={agencies}
                inputValue={editablePlayer.agencia || ""}
                onInputChange={(event, newInputValue) => {
                  setEditablePlayer((prev) => ({
                    ...prev,
                    agencia: newInputValue
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Agencia"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                          {editablePlayer.agencia && (
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setEditablePlayer((prev) => ({
                                  ...prev,
                                  agencia: ""
                                }));
                              }}
                              size="small"
                            >
                              <Clear fontSize="small" />
                            </IconButton>
                          )}
                        </>
                      )
                    }}
                  />
                )}
              />
            </Grid>

            {/* Rango Salarial (rangoPrecios) */}
            <Grid item xs={12}>
              <Autocomplete
                freeSolo
                options={priceRanges}
                inputValue={editablePlayer.rangoPrecios || ""}
                onInputChange={(event, newInputValue) => {
                  setEditablePlayer((prev) => ({
                    ...prev,
                    rangoPrecios: newInputValue
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Rango Salarial"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                          {editablePlayer.rangoPrecios && (
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setEditablePlayer((prev) => ({
                                  ...prev,
                                  rangoPrecios: ""
                                }));
                              }}
                              size="small"
                            >
                              <Clear fontSize="small" />
                            </IconButton>
                          )}
                        </>
                      )
                    }}
                  />
                )}
              />
            </Grid>

            {/* Contrato */}
            <Grid item xs={12}>
              <Autocomplete
                freeSolo
                options={contratos}
                inputValue={editablePlayer.contrato || ""}
                onInputChange={(event, newInputValue) => {
                  setEditablePlayer((prev) => ({
                    ...prev,
                    contrato: newInputValue
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Contrato"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                          {editablePlayer.contrato && (
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setEditablePlayer((prev) => ({
                                  ...prev,
                                  contrato: ""
                                }));
                              }}
                              size="small"
                            >
                              <Clear fontSize="small" />
                            </IconButton>
                          )}
                        </>
                      )
                    }}
                  />
                )}
              />
            </Grid>

            {/* Buyout */}
            <Grid item xs={12}>
              <Autocomplete
                freeSolo
                options={buyoutOptions}
                inputValue={editablePlayer.buyout || ""}
                onInputChange={(event, newInputValue) => {
                  setEditablePlayer((prev) => ({
                    ...prev,
                    buyout: newInputValue
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Buyout"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                          {editablePlayer.buyout && (
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setEditablePlayer((prev) => ({
                                  ...prev,
                                  buyout: ""
                                }));
                              }}
                              size="small"
                            >
                              <Clear fontSize="small" />
                            </IconButton>
                          )}
                        </>
                      )
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        ) : (
          // READ
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {!!player.agencia && (
              <Typography>
                <strong>Agencia:</strong> {player.agencia}
              </Typography>
            )}
            {!!player.rangoPrecios && (
              <Typography>
                <strong>Rango Salarial:</strong> {player.rangoPrecios}
              </Typography>
            )}
            {!!player.contrato && (
              <Typography>
                <strong>Contrato:</strong> {player.contrato}
              </Typography>
            )}
            {!!player.buyout && (
              <Typography>
                <strong>Buyout:</strong> {player.buyout}
              </Typography>
            )}
          </Box>
        )}
      </MobileSection>
    );
  }

  // DESKTOP
  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        5. Información Contractual
      </Typography>
      {isEditing ? (
        <Grid container spacing={2}>
          {/* Agencia */}
          <Grid item xs={12} md={3}>
            <Autocomplete
              freeSolo
              options={agencies}
              inputValue={editablePlayer.agencia || ""}
              onInputChange={(event, newInputValue) => {
                setEditablePlayer((prev) => ({ ...prev, agencia: newInputValue }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Agencia"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.endAdornment}
                        {editablePlayer.agencia && (
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditablePlayer((prev) => ({
                                ...prev,
                                agencia: ""
                              }));
                            }}
                            size="small"
                          >
                            <Clear fontSize="small" />
                          </IconButton>
                        )}
                      </>
                    )
                  }}
                />
              )}
            />
          </Grid>

          {/* Rango Salarial */}
          <Grid item xs={12} md={3}>
            <Autocomplete
              freeSolo
              options={priceRanges}
              inputValue={editablePlayer.rangoPrecios || ""}
              onInputChange={(event, newInputValue) => {
                setEditablePlayer((prev) => ({
                  ...prev,
                  rangoPrecios: newInputValue
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Rango Salarial"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.endAdornment}
                        {editablePlayer.rangoPrecios && (
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditablePlayer((prev) => ({
                                ...prev,
                                rangoPrecios: ""
                              }));
                            }}
                            size="small"
                          >
                            <Clear fontSize="small" />
                          </IconButton>
                        )}
                      </>
                    )
                  }}
                />
              )}
            />
          </Grid>

          {/* Contrato */}
          <Grid item xs={12} md={3}>
            <Autocomplete
              freeSolo
              options={contratos}
              inputValue={editablePlayer.contrato || ""}
              onInputChange={(event, newInputValue) => {
                setEditablePlayer((prev) => ({
                  ...prev,
                  contrato: newInputValue
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Contrato"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.endAdornment}
                        {editablePlayer.contrato && (
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditablePlayer((prev) => ({
                                ...prev,
                                contrato: ""
                              }));
                            }}
                            size="small"
                          >
                            <Clear fontSize="small" />
                          </IconButton>
                        )}
                      </>
                    )
                  }}
                />
              )}
            />
          </Grid>

          {/* Buyout */}
          <Grid item xs={12} md={3}>
            <Autocomplete
              freeSolo
              options={buyoutOptions}
              inputValue={editablePlayer.buyout || ""}
              onInputChange={(event, newInputValue) => {
                setEditablePlayer((prev) => ({
                  ...prev,
                  buyout: newInputValue
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Buyout"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.endAdornment}
                        {editablePlayer.buyout && (
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditablePlayer((prev) => ({
                                ...prev,
                                buyout: ""
                              }));
                            }}
                            size="small"
                          >
                            <Clear fontSize="small" />
                          </IconButton>
                        )}
                      </>
                    )
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ mt: 1 }}>
          {(!!player.agencia ||
            !!player.rangoPrecios ||
            !!player.contrato ||
            !!player.buyout) && (
            <Box sx={{ display: "flex", gap: 4 }}>
              {!!player.agencia && (
                <Typography sx={{ display: "flex" }}>
                  <strong style={{ minWidth: "auto" }}>Agencia:&nbsp;</strong>{" "}
                  {player.agencia}
                </Typography>
              )}
              {!!player.rangoPrecios && (
                <Typography sx={{ display: "flex" }}>
                  <strong style={{ minWidth: "auto" }}>
                    Rango Salarial:&nbsp;
                  </strong>{" "}
                  {player.rangoPrecios}
                </Typography>
              )}
              {!!player.contrato && (
                <Typography sx={{ display: "flex" }}>
                  <strong style={{ minWidth: "auto" }}>Contrato:&nbsp;</strong>{" "}
                  {player.contrato}
                </Typography>
              )}
              {!!player.buyout && (
                <Typography sx={{ display: "flex" }}>
                  <strong style={{ minWidth: "auto" }}>Buyout:&nbsp;</strong>{" "}
                  {player.buyout}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      )}
    </Paper>
  );
};


  /**
   * 6. INFORMACIÓN ADICIONAL
   *    URL Estadística
   */
  const renderInformacionAdicional = () => {
    // MOBILE
    if (isMobile) {
      return (
        <MobileSection title="6. Información Adicional">
          {isEditing ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="URL Estadística"
                  name="url"
                  value={editablePlayer.url || ''}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          ) : (
            // READ
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {!!player.url && (
                <Typography>
                  <strong>URL Estadística:</strong> {player.url}
                </Typography>
              )}
            </Box>
          )}
        </MobileSection>
      );
    }

    // DESKTOP
    return (
      <Paper sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>6. Información Adicional</Typography>
        {isEditing ? (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="URL Estadística"
                name="url"
                value={editablePlayer.url || ''}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
        ) : (
          <Box sx={{ mt: 1 }}>
            {!!player.url && (
              <Typography>
                <strong>URL Estadística:</strong> {player.url}
              </Typography>
            )}
          </Box>
        )}
      </Paper>
    );
  };

  // ----------------------------------------------

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error || !player) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error || 'Error al cargar el jugador'}
      </Alert>
    );
  }

  // ---------- MOBILE LAYOUT ----------
  if (isMobile) {
    // Tabs
    const sections = [
      {
        id: 'info',
        name: 'Info',
        icon: <EditIcon />,
        available: true
      },
      {
        id: 'tecnica',
        name: 'Ficha Técnica',
        icon: <EditIcon />,
        available: hasTechnicaData
      },
      {
        id: 'informe', 
        name: 'Informe',
        icon: <EditIcon />,
        available: hasInformeData && userRole !== 'entrenador'
      },
      {
        id: 'intel',
        name: 'Intel', 
        icon: <EditIcon />,
        available: hasIntelData && userRole !== 'entrenador'
      },
      {
        id: 'pdp',
        name: 'PDP',
        icon: <EditIcon />,
        available: hasPDPData && userRole !== 'entrenador'
      }
    ].filter(s => s.available);

    const getCurrentSection = () => {
      return sections[activeTab]?.id || 'info';
    };

    const currentSection = getCurrentSection();

    return (
      <Box sx={{ pb: 8, minHeight: '100vh', backgroundColor: '#f5f5f5' }}>
        <MobileProfileHeader
          player={player}
          avatarUrl={selectedImagePreview || `${mediaUrl}/photos/${player.id}.png?${avatarTimestamp}`}
          isEditing={isEditing}
          onEditPhoto={() => setOpenPhotoDialog(true)}
          onBack={() => navigate(-1)}
        />

        <Box sx={{ px: 2, py: 3 }}>
          {(() => {
            if (currentSection !== 'info') {
              // Fiche Technique / Informe / Intel / PDP
              switch (currentSection) {
                case 'tecnica':
                  return (
                    <TechnicaTab
                      playerId={player.id}
                      dateNaissance={player.dateNaissance}
                      nom={player.nom}
                      onDataLoaded={setHasTechnicaData}
                    />
                  );
                case 'informe':
                  return (
                    <InformeTab
                      playerId={player.id}
                      onDataLoaded={setHasInformeData}
                    />
                  );
                case 'intel':
                  return (
                    <IntelTab
                      playerId={player.id}
                      onDataLoaded={setHasIntelData}
                    />
                  );
                case 'pdp':
                  return (
                    <PDPTab
                      playerId={player.id}
                      onDataLoaded={setHasPDPData}
                    />
                  );
                default:
                  return null;
              }
            } else {
              // Info => nos 6 secciones reorganizadas
              return (
                <>
                  {renderInformacionBasica()}
                  {renderCaracteristicasJugador()}
                  {renderSituacionActual()}
                  {renderExperienciaProfesional()}
                  {renderInformacionContractual()}
                  {renderInformacionAdicional()}
                </>
              );
            }
          })()}
        </Box>

        <PhotoEditDialog
          open={openPhotoDialog}
          onClose={() => setOpenPhotoDialog(false)}
          onCapture={handlePhotoCapture}
          onSelect={handlePhotoSelect}
        />

        <MobileDrawerMenu
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          sections={sections}
          activeSection={activeTab}
          onSectionChange={setActiveTab}
        />

        {/* Barre d'onglets en bas (mobile) */}
        {sections.length > 1 && (
          <Box sx={{ position: 'fixed', bottom: 10, left: 0, right: 0, zIndex: theme.zIndex.appBar }}>
            <Paper elevation={3}>
              <Tabs
                value={activeTab}
                onChange={(e, newValue) => setActiveTab(newValue)}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                sx={{ backgroundColor: 'background.paper' }}
              >
                {sections.map((section) => (
                  <Tab key={section.id} label={section.name} />
                ))}
              </Tabs>
            </Paper>
          </Box>
        )}

        {/* Bouton "Modificar"/"Guardar" si tab info */}
        {currentSection === 'info' && (
          <AppBar 
            position="fixed" 
            color="primary" 
            sx={{ 
              top: 'auto', 
              bottom: 58,
              backgroundColor: 'transparent',
              boxShadow: 'none'
            }}
          >
            <Toolbar>
              <Box sx={{ flexGrow: 1 }}>
                <Button 
                  variant="contained"
                  onClick={() => {
                    if (isEditing) {
                      handleUpdate();
                    } else {
                      setIsEditing(true);
                    }
                  }}
                  fullWidth
                >
                  {isEditing ? 'Guardar' : 'Modificar'}
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
        )}
      </Box>
    );
  }

  // ----------- DESKTOP LAYOUT -----------
  const sections = [
    {
      id: 'info',
      name: 'Info',
      icon: <EditIcon />,
      available: true
    },
    {
      id: 'tecnica',
      name: 'Ficha Técnica',
      icon: <EditIcon />,
      available: hasTechnicaData
    },
    {
      id: 'informe', 
      name: 'Informe',
      icon: <EditIcon />,
      available: hasInformeData && userRole !== 'entrenador'
    },
    {
      id: 'intel',
      name: 'Intel',
      icon: <EditIcon />,
      available: hasIntelData && userRole !== 'entrenador'
    },
    {
      id: 'pdp',
      name: 'PDP',
      icon: <EditIcon />,
      available: hasPDPData && userRole !== 'entrenador'
    }
  ].filter(s => s.available);

  const getCurrentSection = () => {
    return sections[activeTab]?.id || 'info';
  };
  const currentSection = getCurrentSection();

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      {/* Colonne de gauche */}
      <Box sx={{ width: 300, pr: 2 }}>
        <Paper sx={{ p: 3, mb: 2, textAlign: 'center' }}>
          <Avatar
            src={selectedImagePreview || `${mediaUrl}/photos/${player.id}.png?${avatarTimestamp}`}
            alt={player.nom}
            sx={{ width: 200, height: 200, mx: 'auto', mb: 2 }}
            onClick={() => isEditing && setOpenPhotoDialog(true)}
          />
          <Typography variant="h6" gutterBottom>
            {player.nom}
          </Typography>
          <Box sx={{ mb: 2 }}>
            <Chip
              label={player.type === 'cantera' ? "Cantera" : "Pro"}
              sx={{
                backgroundColor: player.type === 'cantera' ? '#FF8C00' : '#007FFF',
                color: '#fff'
              }}
            />
          </Box>
          {player?.url && (
            <Button
              variant="outlined"
              startIcon={<OpenInNew />}
              href={player.url}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ mb: 2 }}
              fullWidth
            >
              Ficha Estadística 
            </Button>
          )}

{player?.tags && player.tags.length > 0 && (
  <Box 
    sx={{ 
      display: 'flex', 
      flexWrap: 'wrap', 
      gap: 0.5,
      justifyContent: 'center',
      mb: 2
    }}
  >
    {player.tags.map((tag) => (
      <Tooltip
        key={tag.id}
        title={tag.source === 'big_board' ? 'Ranking' : 'Seguimiento'}
        placement="top"
        arrow
      >
        <Chip
          label={tag.libelle}
          sx={{
            // 2 couleurs distinctes
            backgroundColor:
              tag.source === 'big_board'
                ? '#1565c0'        // bleu pour big_board
                : '#c62828',       // rouge pour seguimiento
            color: '#fff'
          }}
        />
      </Tooltip>
    ))}
  </Box>
)}

          {currentSection === 'info' && (
            <Button
              variant="contained"
              onClick={() => {
                if (isEditing) {
                  handleUpdate();
                } else {
                  setIsEditing(true);
                }
              }}
              fullWidth
            >
              {isEditing ? 'Guardar' : 'Modificar'}
            </Button>
          )}
        </Paper>

        {sections.length > 1 && (
          <Paper sx={{ p: 1 }}>
            <Tabs
              value={activeTab}
              onChange={(e, newValue) => setActiveTab(newValue)}
              orientation="vertical"
              variant="scrollable"
            >
              {sections.map((section) => (
                <Tab key={section.id} label={section.name} />
              ))}
            </Tabs>
          </Paper>
        )}
      </Box>

      {/* Contenu central */}
      <Box sx={{ flex: 1, p: 2 }}>
        {(() => {
          if (currentSection !== 'info') {
            switch (currentSection) {
              case 'tecnica':
                return (
                  <TechnicaTab
                    playerId={player.id}
                    dateNaissance={player.dateNaissance}
                    nom={player.nom}
                    onDataLoaded={setHasTechnicaData}
                  />
                );
              case 'informe':
                return (
                  <InformeTab
                    playerId={player.id}
                    onDataLoaded={setHasInformeData}
                  />
                );
              case 'intel':
                return (
                  <IntelTab
                    playerId={player.id}
                    onDataLoaded={setHasIntelData}
                  />
                );
              case 'pdp':
                return (
                  <PDPTab
                    playerId={player.id}
                    onDataLoaded={setHasPDPData}
                  />
                );
              default:
                return null;
            }
          } else {
            // Info => 6 sections
            return (
              <>
                {renderInformacionBasica()}
                {renderCaracteristicasJugador()}
                {renderSituacionActual()}
                {renderExperienciaProfesional()}
                {renderInformacionContractual()}
                {renderInformacionAdicional()}
              </>
            );
          }
        })()}
      </Box>

      <PhotoEditDialog
        open={openPhotoDialog}
        onClose={() => setOpenPhotoDialog(false)}
        onCapture={handlePhotoCapture}
        onSelect={handlePhotoSelect}
      />
    </Box>
  );
};

export default PlayerProfile;
