import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  IconButton,
  Collapse,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const getAvatarUrl = (userId) => {
  return `${process.env.REACT_APP_MEDIA_URL}/users/${userId}.png?${new Date().getTime()}`;
};

const IntelTab = ({ playerId, onDataLoaded }) => {
  const [intelData, setIntelData] = useState([]);
  const [openRows, setOpenRows] = useState({});

  // États pour l'édition
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [currentIntel, setCurrentIntel] = useState(null);

  // Champs éditables : fecha, fuente, detalles
  const [editedFecha, setEditedFecha] = useState('');
  const [editedFuente, setEditedFuente] = useState('');
  const [editedDetalles, setEditedDetalles] = useState('');

  // États pour la suppression
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [intelToDelete, setIntelToDelete] = useState(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;

  // Charger la liste des Intel
  useEffect(() => {
    const fetchIntelData = async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/intel`, {
          params: {
            playerId,
            section
          }
        });

        setIntelData(response.data);

        if (onDataLoaded) {
          onDataLoaded(response.data.length > 0);
        }

        // Déployer le premier Intel par défaut
        if (response.data.length > 0) {
          setOpenRows({ [response.data[0].id]: true });
        }
      } catch (error) {
        console.error('Error al recuperar los datos de Intel:', error);
      }
    };

    fetchIntelData();
  }, [playerId, backendUrl, section, onDataLoaded]);

  // Toggle pour collapse
  const toggleRow = (rowId) => {
    setOpenRows((prev) => ({ ...prev, [rowId]: !prev[rowId] }));
  };

  // Ouvrir la boîte de dialogue pour éditer un Intel
  const handleEditClick = (intel) => {
    setCurrentIntel(intel);

    // On convertit la fecha en format YYYY-MM-DD pour l'input type="date"  
    const dateStr = intel.fecha 
      ? new Date(intel.fecha).toISOString().split('T')[0] 
      : '';
    setEditedFecha(dateStr);

    setEditedFuente(intel.fuente || '');
    setEditedDetalles(intel.detalles || '');
    setOpenEditDialog(true);
  };

  // Sauvegarder les modifications (fecha, fuente, detalles)
  const handleEditSave = async () => {
    if (!currentIntel) return;

    // Nettoyage optionnel du HTML pour éviter la duplication de <br>
    const transformedDetalles = editedDetalles
      .replace(/<p><br><\/p>/g, '<br>')
      .replace(/<p>/g, '')
      .replace(/<\/p>/g, '<br>')
      .replace(/<br><br>/g, '<br>');

    const updateData = {
      fecha: editedFecha,
      fuente: editedFuente,
      detalles: transformedDetalles
    };

    try {
      await axios.put(
        `${backendUrl}/api/intel/${currentIntel.id}`,
        updateData,
        { params: { section } }
      );

      // Mise à jour locale
      setIntelData((prevData) =>
        prevData.map((item) =>
          item.id === currentIntel.id
            ? {
                ...item,
                fecha: editedFecha,
                fuente: editedFuente,
                detalles: transformedDetalles
              }
            : item
        )
      );

      setOpenEditDialog(false);
    } catch (error) {
      console.error('Error al actualizar el Intel:', error);
      alert('Error al actualizar el Intel.');
    }
  };

  // Gérer la fermeture de la fenêtre d'édition
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setCurrentIntel(null);
  };

  // Ouvrir la boîte de dialogue pour supprimer un Intel
  const handleDeleteClick = (intel) => {
    setIntelToDelete(intel);
    setOpenDeleteDialog(true);
  };

  // Confirmer la suppression
  const confirmDelete = async () => {
    if (!intelToDelete) return;

    try {
      await axios.delete(`${backendUrl}/api/intel/${intelToDelete.id}`, {
        params: { section }
      });

      setIntelData((prevData) =>
        prevData.filter((item) => item.id !== intelToDelete.id)
      );

      setOpenDeleteDialog(false);
    } catch (error) {
      console.error('Error al eliminar el Intel:', error);
      alert('Error al eliminar el Intel.');
    }
  };

  // Annuler la suppression
  const cancelDelete = () => {
    setOpenDeleteDialog(false);
    setIntelToDelete(null);
  };

  // Affichage si aucun Intel
  if (!intelData.length) {
    return (
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography variant="h6">No hay datos disponibles</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        p: 2,
        [theme.breakpoints.down('sm')]: {
          p: 1
        }
      }}
    >
      {intelData.map((row) => (
        <Card
          key={row.id}
          sx={{
            mb: 2,
            '&:last-child': { mb: 0 }
          }}
        >
          <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
            {/* En-tête : avatar + infos principales + boutons */}
            <Box
              onClick={() => toggleRow(row.id)}
              sx={{
                p: 2,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  alignItems: 'flex-start'
                }
              }}
            >
              {/* Avatar + infos */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexGrow: 1,
                  minWidth: 0,
                  [theme.breakpoints.down('sm')]: {
                    mb: 1
                  }
                }}
              >
                <Avatar
                  src={getAvatarUrl(row.usuario_id)}
                  alt={row.usuario_nombre}
                  sx={{ mr: 2 }}
                />
                <Box sx={{ minWidth: 0 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'baseline',
                      flexWrap: 'wrap',
                      gap: 1,
                      mb: 0.5
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'normal',
                        mr: 1
                      }}
                    >
                      {row.usuario_nombre}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ flexShrink: 0 }}
                    >
                      {new Date(row.fecha).toLocaleDateString()}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'normal'
                    }}
                  >
                    Fuente: {row.fuente}
                  </Typography>
                </Box>
              </Box>

              {/* Boutons (Editar + Supprimer + Collapse) */}
              <Box>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditClick(row);
                  }}
                  size="small"
                  sx={{ mr: isSmallScreen ? 0 : 1 }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>

                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteClick(row);
                  }}
                  size="small"
                  sx={{ color: 'red' }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>

                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleRow(row.id);
                  }}
                  size="small"
                >
                  {openRows[row.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Box>
            </Box>

            {/* Contenu détaillé (collapse) */}
            <Collapse in={openRows[row.id]}>
              <Box
                sx={{
                  p: 2,
                  bgcolor: '#FFECB3',
                  borderTop: 1,
                  borderColor: 'divider'
                }}
              >
                <Typography
                  variant="body2"
                  component="div"
                  dangerouslySetInnerHTML={{ __html: row.detalles }}
                />
              </Box>
            </Collapse>
          </CardContent>
        </Card>
      ))}

      {/* Dialog pour éditer (fecha, fuente, detalles) */}
      <Dialog
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Modificar el Intel</DialogTitle>
        <DialogContent>
          {currentIntel && (
            <Box sx={{ mt: 2 }}>
              {/* Fecha */}
              <Typography variant="subtitle2" gutterBottom>
                <strong>Fecha:</strong>
              </Typography>
              <TextField
                type="date"
                fullWidth
                value={editedFecha}
                onChange={(e) => setEditedFecha(e.target.value)}
                sx={{ mb: 2 }}
              />

              {/* Fuente */}
              <Typography variant="subtitle2" gutterBottom>
                <strong>Fuente:</strong>
              </Typography>
              <TextField
                fullWidth
                value={editedFuente}
                onChange={(e) => setEditedFuente(e.target.value)}
                placeholder="Modificar la fuente..."
                sx={{ mb: 2 }}
              />

              {/* Detalles */}
              <Typography variant="subtitle2" gutterBottom>
                <strong>Detalles:</strong>
              </Typography>
              <ReactQuill
                theme="snow"
                value={editedDetalles}
                onChange={setEditedDetalles}
                placeholder="Modificar los detalles..."
                style={{ minHeight: '150px' }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleEditSave} color="primary" variant="contained">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog de confirmation de suppression */}
      <Dialog
        open={openDeleteDialog}
        onClose={cancelDelete}
        aria-labelledby="delete-confirmation-dialog"
      >
        <DialogTitle id="delete-confirmation-dialog">Eliminar Intel</DialogTitle>
        <DialogContent>
          <Typography>¿Está seguro de que desea eliminar este Intel?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="secondary">
            Cancelar
          </Button>
          <Button onClick={confirmDelete} color="primary" variant="contained">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default IntelTab;
