import React, { useState } from 'react';
import { 
  Box, Button, Typography, Grid,
  useTheme, useMediaQuery, Avatar
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';

const FichaTecnificacion = ({ playerId, onFichaAdded }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const section = process.env.REACT_APP_SECTION;
  
  const [isEditing, setIsEditing] = useState(false);

  // IMPORTANT : utiliser les mêmes noms que dans la BDD : 'objectifs', 'observations'
  const [objectifs, setObjectifs] = useState('');
  const [implementar, setImplementar] = useState('');
  const [observations, setObservations] = useState('');
  const [routinas, setRoutinas] = useState('');
  const [numeroSesiones, setNumeroSesiones] = useState('');

  // Récupérer les informations de l'utilisateur du localStorage 
  const userInfo = JSON.parse(localStorage.getItem('user'));
  const currentDate = new Date().toLocaleDateString();

  const handleNewFicha = () => {
    setIsEditing(true);
    setObjectifs('');
    setImplementar('');
    setObservations('');
    setRoutinas('');
    setNumeroSesiones('');
  };

  const handleCancel = () => {
    setIsEditing(false);
    setObjectifs('');
    setImplementar('');
    setObservations('');
    setRoutinas('');
    setNumeroSesiones('');
  };

  const handleSaveFicha = async () => {
    // Au moins un champ requis
    if (
      !objectifs && 
      !implementar && 
      !observations &&
      !routinas &&
      !numeroSesiones
    ) {
      return;
    }

    try {
      await axios.post(`${backendUrl}/api/tecnification/fiches`, {
        tecnification_player_id: playerId,
        user_id: userInfo.id,
        objectifs,       // dans la BDD: objectifs
        implementar,
        observations,    // dans la BDD: observations
        routinas,
        numero_sesiones: numeroSesiones,
        section
      });
      
      // Réinitialiser
      setIsEditing(false);
      setObjectifs('');
      setImplementar('');
      setObservations('');
      setRoutinas('');
      setNumeroSesiones('');
      
      // Rafraîchir la liste des fiches (propagée au parent)
      if (onFichaAdded) {
        onFichaAdded();
      }
    } catch (error) {
      console.error("Error al crear la ficha:", error);
    }
  };

  const editorModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['link'],  // Option pour ajouter des liens
      ['clean']
    ],
  };
  
  const editorFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet',
    'link'
  ];

  return (
    <Box sx={{ mt: 2 }}>
      <Box 
        sx={{ 
          mb: 2, 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center' 
        }}
      >
        <Typography variant="h6">
          {/* Titre éventuel si besoin */}
        </Typography>
        {!isEditing && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleNewFicha}
          >
            Nueva ficha
          </Button>
        )}
      </Box>

      {isEditing && (
        <>
          {/* En-tête de la fiche avec les infos utilisateur */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 2, 
            mb: 3,
            p: 2,
            bgcolor: 'grey.100',
            borderRadius: 1
          }}>
            <Avatar
              src={`${mediaUrl}/users/${userInfo.id}.png`}
              alt={`${userInfo.prenom} ${userInfo.nom}`}
              sx={{ width: 48, height: 48 }}
            />
            <Box>
              <Typography variant="subtitle1">
                {`${userInfo.prenom} ${userInfo.nom}`}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {currentDate}
              </Typography>
            </Box>
          </Box>

          <Grid container spacing={2}>
            {/* OBJECTIFS (affiché comme "Objetivos" pour l'utilisateur) */}
            <Grid item xs={12} md={4}>
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  Objetivos
                </Typography>
                <Box sx={{ 
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 1,
                  overflow: 'hidden',
                  '& .ql-toolbar': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderBottom: '1px solid',
                    borderColor: 'divider'
                  },
                  '& .ql-container': {
                    border: 'none',
                    minHeight: isMobile ? '200px' : '200px'
                  },
                  '& .ql-editor': {
                    minHeight: isMobile ? '200px' : '200px'
                  }
                }}>
                  <ReactQuill
                    value={objectifs}
                    onChange={setObjectifs}
                    modules={editorModules}
                    formats={editorFormats}
                  />
                </Box>
              </Box>
            </Grid>
            
            {/* IMPLEMENTAR */}
            <Grid item xs={12} md={4}>
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  Implementar
                </Typography>
                <Box sx={{ 
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 1,
                  overflow: 'hidden',
                  '& .ql-toolbar': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderBottom: '1px solid',
                    borderColor: 'divider'
                  },
                  '& .ql-container': {
                    border: 'none',
                    minHeight: isMobile ? '200px' : '200px'
                  },
                  '& .ql-editor': {
                    minHeight: isMobile ? '200px' : '200px'
                  }
                }}>
                  <ReactQuill
                    value={implementar}
                    onChange={setImplementar}
                    modules={editorModules}
                    formats={editorFormats}
                  />
                </Box>
              </Box>
            </Grid>

            {/* OBSERVATIONS (affiché comme "Observaciones" pour l'utilisateur) */}
            <Grid item xs={12} md={4}>
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  Observaciones
                </Typography>
                <Box sx={{ 
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 1,
                  overflow: 'hidden',
                  '& .ql-toolbar': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderBottom: '1px solid',
                    borderColor: 'divider'
                  },
                  '& .ql-container': {
                    border: 'none',
                    minHeight: isMobile ? '200px' : '200px'
                  },
                  '& .ql-editor': {
                    minHeight: isMobile ? '200px' : '200px'
                  }
                }}>
                  <ReactQuill
                    value={observations}
                    onChange={setObservations}
                    modules={editorModules}
                    formats={editorFormats}
                  />
                </Box>
              </Box>
            </Grid>

            {/* ROUTINAS */}
            <Grid item xs={12} md={6}>
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  Rutinas
                </Typography>
                <Box sx={{ 
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 1,
                  overflow: 'hidden',
                  '& .ql-toolbar': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderBottom: '1px solid',
                    borderColor: 'divider'
                  },
                  '& .ql-container': {
                    border: 'none',
                    minHeight: isMobile ? '150px' : '150px'
                  },
                  '& .ql-editor': {
                    minHeight: isMobile ? '150px' : '150px'
                  }
                }}>
                  <ReactQuill
                    value={routinas}
                    onChange={setRoutinas}
                    modules={editorModules}
                    formats={editorFormats}
                  />
                </Box>
              </Box>
            </Grid>

            {/* NÚMERO DE SESIONES */}
            <Grid item xs={12} md={6}>
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  Número de Sesiones
                </Typography>
                <Box sx={{
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 1,
                  p: 1,
                  backgroundColor: 'white'
                }}>
                  <input
                    type="text"
                    value={numeroSesiones}
                    onChange={(e) => setNumeroSesiones(e.target.value)}
                    style={{
                      width: '100%',
                      border: 'none',
                      outline: 'none',
                      backgroundColor: 'transparent'
                    }}
                    placeholder="Ej. 10 sesiones..."
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ 
            mt: 2, 
            display: 'flex', 
            justifyContent: 'flex-end',
            gap: 2
          }}>
            <Button
              variant="outlined"
              color="error"
              startIcon={<CancelIcon />}
              onClick={handleCancel}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSaveFicha}
              disabled={
                !objectifs && 
                !implementar && 
                !observations &&
                !routinas &&
                !numeroSesiones
              }
            >
              Guardar ficha
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default FichaTecnificacion;
