import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, IconButton } from '@mui/material';
import { Clear } from '@mui/icons-material';

// Tableau des nationalités
const nationalities = [
  "Afgano (AFG)",
  "Alemán (DEU)",
  "Andorrano (AND)",
  "Angoleño (AGO)",
  "Antiguano (ATG)",
  "Argelino (DZA)",
  "Argentino (ARG)",
  "Armenio (ARM)",
  "Australiano (AUS)",
  "Austriaco (AUT)",
  "Bahameño (BHS)",
  "Bahreiní (BHR)",
  "Bangladesí (BGD)",
  "Barbadense (BRB)",
  "Belga (BEL)",
  "Beliceño (BLZ)",
  "Beninés (BEN)",
  "Bielorruso (BLR)",
  "Birmano (MMR)",
  "Boliviano (BOL)",
  "Bosnio (BIH)",
  "Botsuano (BWA)",
  "Brasileño (BRA)",
  "Bruneano (BRN)",
  "Búlgaro (BGR)",
  "Burkinés (BFA)",
  "Burundés (BDI)",
  "Butanés (BTN)",
  "Caboverdiano (CPV)",
  "Camerunés (CMR)",
  "Canadiense (CAN)",
  "Catarí (QAT)",
  "Chadiano (TCD)",
  "Checo (CZE)",
  "Chileno (CHL)",
  "Chino (CHN)",
  "Chipriota (CYP)",
  "Colombiano (COL)",
  "Comorense (COM)",
  "Congoleño (COG)",
  "Costarricense (CRI)",
  "Croata (HRV)",
  "Cubano (CUB)",
  "Danés (DNK)",
  "Dominicano (DOM)",
  "Ecuatoriano (ECU)",
  "Egipcio (EGY)",
  "Emiratí (ARE)",
  "Eritreo (ERI)",
  "Eslovaco (SVK)",
  "Esloveno (SVN)",
  "Español (ESP)",
  "Estadounidense (USA)",
  "Estonio (EST)",
  "Etíope (ETH)",
  "Filipino (PHL)",
  "Finlandés (FIN)",
  "Fiyiano (FJI)",
  "Francés (FRA)",
  "Gabonés (GAB)",
  "Gambiano (GMB)",
  "Georgiano (GEO)",
  "Ghanés (GHA)",
  "Granadino (GRD)",
  "Griego (GRC)",
  "Guatemalteco (GTM)",
  "Guineano (GIN)",
  "Guyanés (GUY)",
  "Haitiano (HTI)",
  "Holandés (NLD)",
  "Hondureño (HND)",
  "Húngaro (HUN)",
  "Indio (IND)",
  "Inglés (ENG)",
  "Indonesio (IDN)",
  "Iraní (IRN)",
  "Iraquí (IRQ)",
  "Irlandés (IRL)",
  "Islandés (ISL)",
  "Israelí (ISR)",
  "Italiano (ITA)",
  "Jamaicano (JAM)",
  "Japonés (JPN)",
  "Jordano (JOR)",
  "Kazajo (KAZ)",
  "Keniano (KEN)",
  "Kirguís (KGZ)",
  "Kiribatiano (KIR)",
  "Kuwaití (KWT)",
  "Laosiano (LAO)",
  "Lesotense (LSO)",
  "Letón (LVA)",
  "Libanés (LBN)",
  "Liberiano (LBR)",
  "Libio (LBY)",
  "Liechtensteiniano (LIE)",
  "Lituano (LTU)",
  "Luxemburgués (LUX)",
  "Macedonio (MKD)",
  "Malasio (MYS)",
  "Malauí (MWI)",
  "Maliense (MLI)",
  "Maltés (MLT)",
  "Marfileño (CIV)",
  "Marroquí (MAR)",
  "Mauriciano (MUS)",
  "Mauritano (MRT)",
  "Mexicano (MEX)",
  "Micronesio (FSM)",
  "Moldavo (MDA)",
  "Monegasco (MCO)",
  "Mongol (MNG)",
  "Montenegrino (MNE)",
  "Mozambiqueño (MOZ)",
  "Namibio (NAM)",
  "Neozelandés (NZL)",
  "Nepalí (NPL)",
  "Nicaragüense (NIC)",
  "Nigerino (NER)",
  "Nigeriano (NGA)",
  "Norcoreano (PRK)",
  "Noruego (NOR)",
  "Omaní (OMN)",
  "Pakistaní (PAK)",
  "Palauano (PLW)",
  "Panameño (PAN)",
  "Papú (PNG)",
  "Paraguayo (PRY)",
  "Peruano (PER)",
  "Polaco (POL)",
  "Portugués (PRT)",
  "Puertorriqueño (PRI)",
  "Qatarí (QAT)",
  "Reunionés (REU)",
  "Rumano (ROU)",
  "Ruso (RUS)",
  "Ruandés (RWA)",
  "Samoano (WSM)",
  "Sancristobaleño (KNA)",
  "Sanmarinense (SMR)",
  "Sanvicentino (VCT)",
  "Santotomense (STP)",
  "Saudí (SAU)",
  "Senegalés (SEN)",
  "Serbio (SRB)",
  "Seychelense (SYC)",
  "Sierraleonés (SLE)",
  "Singapurense (SGP)",
  "Sirio (SYR)",
  "Somalí (SOM)",
  "Srilanqués (LKA)",
  "Suazi (SWZ)",
  "Sudafricano (ZAF)",
  "Sudanés (SDN)",
  "Sudcoreano (KOR)",
  "Sueco (SWE)",
  "Suizo (CHE)",
  "Surinamés (SUR)",
  "Sursudanés (SSD)",
  "Tailandés (THA)",
  "Tanzano (TZA)",
  "Tayiko (TJK)",
  "Timorense (TLS)",
  "Togolés (TGO)",
  "Tongano (TON)",
  "Trinitense (TTO)",
  "Tunecino (TUN)",
  "Turcomano (TKM)",
  "Turco (TUR)",
  "Tuvaluano (TUV)",
  "Ucraniano (UKR)",
  "Ugandés (UGA)",
  "Uruguayo (URY)",
  "Uzbeko (UZB)",
  "Vanuatuense (VUT)",
  "Venezolano (VEN)",
  "Vietnamita (VNM)",
  "Yemení (YEM)",
  "Yibutiano (DJI)",
  "Zambiano (ZMB)",
  "Zimbabuense (ZWE)"
];

function NationalitiesSelect({ value, onChange }) {
  // On crée une copie triée
  const sortedNationalities = [...nationalities].sort((a, b) => 
    a.localeCompare(b, "es")
  );

  // Fonction pour gérer le clic sur le bouton Clear
  const handleClearClick = (e) => {
    e.stopPropagation(); // Empêche l'ouverture du Select
    onChange({ target: { value: '' } }); // Simule un événement onChange avec une valeur vide
  };

  return (
    <FormControl fullWidth>
      <InputLabel>Nacionalidad</InputLabel>
      <Select
        value={value || ""}
        onChange={onChange}
        label="Nacionalidad"
        displayEmpty
        endAdornment={
          <IconButton
            onClick={handleClearClick}
            sx={{ 
              visibility: value ? 'visible' : 'hidden',
              position: 'absolute',
              right: 32
            }}
            size="small"
          >
            <Clear fontSize="small" />
          </IconButton>
        }
      >
        <MenuItem value="">
         
        </MenuItem>
        {sortedNationalities.map((nationality) => {
          const label = nationality.charAt(0).toUpperCase() + nationality.slice(1);
          return (
            <MenuItem key={nationality} value={nationality}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default NationalitiesSelect;