import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Select, MenuItem, Chip, IconButton, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { ArrowBack, Edit, Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';

const Grupos = () => {
  const [groupName, setGroupName] = useState('');
  const [groupColor, setGroupColor] = useState('');
  const [groups, setGroups] = useState([]);
  const [editGroup, setEditGroup] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const navigate = useNavigate();

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const section = process.env.REACT_APP_SECTION;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Fonction pour trier les groupes par ordre alphabétique
  const sortGroups = (groupsToSort) => {
    return [...groupsToSort].sort((a, b) => 
      a.libelle.localeCompare(b.libelle, 'es', { sensitivity: 'base' })
    );
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/groups`, { params: { section } });
      setGroups(sortGroups(response.data)); // Tri des groupes après récupération
    } catch (error) {
      console.error("Erreur lors de la récupération des groupes:", error);
    }
  };

  const handleAddGroup = async () => {
    if (groupName && groupColor) {
      try {
        const response = await axios.post(`${backendUrl}/api/groups`, {
          libelle: groupName,
          code_couleur: groupColor,
          section
        });
        setGroups(sortGroups([...groups, response.data])); // Tri après ajout
        setGroupName('');
        setGroupColor('');
      } catch (error) {
        console.error("Erreur lors de l'ajout du groupe:", error);
      }
    }
  };

  const handleEditGroup = (group) => {
    setEditGroup(group);
    setGroupName(group.libelle);
    setGroupColor(group.code_couleur);
  };

  const handleSaveEdit = async () => {
    try {
      await axios.put(`${backendUrl}/api/groups/${editGroup.id}`, {
        libelle: groupName,
        code_couleur: groupColor,
        section
      });
      const updatedGroups = groups.map((g) => 
        g.id === editGroup.id ? { ...g, libelle: groupName, code_couleur: groupColor } : g
      );
      setGroups(sortGroups(updatedGroups)); // Tri après modification
      setGroupName('');
      setGroupColor('');
      setEditGroup(null);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du groupe:", error);
    }
  };

  const handleDeleteGroup = async (group) => {
    try {
      await axios.delete(`${backendUrl}/api/groups/${group.id}`, {
        params: { section }
      });
      const updatedGroups = groups.filter((g) => g.id !== group.id);
      setGroups(sortGroups(updatedGroups)); // Tri après suppression
      setConfirmDelete(null);
    } catch (error) {
      console.error("Erreur lors de la suppression du groupe:", error);
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Grupos
      </Typography>

      <Grid container spacing={2} direction={isSmallScreen ? 'column' : 'row'} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Nombre del Grupo"
            variant="outlined"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            value={groupColor}
            onChange={(e) => setGroupColor(e.target.value)}
            displayEmpty
            variant="outlined"
            fullWidth
          >
            <MenuItem value="" disabled>Seleccionar Color</MenuItem>
            <MenuItem value="#ffcc80">Naranja</MenuItem>
            <MenuItem value="#90caf9">Azul</MenuItem>
            <MenuItem value="#a5d6a7">Verde</MenuItem>
            <MenuItem value="#f48fb1">Rosa</MenuItem>
            <MenuItem value="#ffab91">Naranja Claro</MenuItem>
            <MenuItem value="#ce93d8">Morado</MenuItem>
            <MenuItem value="#81c784">Verde Claro</MenuItem>
            <MenuItem value="#64b5f6">Azul Cielo</MenuItem>
            <MenuItem value="#ba68c8">Morado</MenuItem>
            <MenuItem value="#4fc3f7">Azul Claro</MenuItem>
            <MenuItem value="#e57373">Rojo Claro</MenuItem>
            <MenuItem value="#fff176">Amarillo</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            onClick={editGroup ? handleSaveEdit : handleAddGroup}
            sx={{ backgroundColor: '#ff9800', color: 'white', height: '100%', width: '150px' }}
          >
            {editGroup ? 'Guardar Cambios' : 'Añadir Grupo'}
          </Button>
        </Grid>
      </Grid>

      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Grupos Existentes:
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {groups.map((group) => (
            <Box key={group.id} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Chip
                label={group.libelle}
                sx={{ backgroundColor: group.code_couleur, color: 'white' }}
              />
              <IconButton onClick={() => handleEditGroup(group)} sx={{ color: '#ff9800' }}>
                <Edit />
              </IconButton>
              <IconButton onClick={() => setConfirmDelete(group)} sx={{ color: 'red' }}>
                <Delete />
              </IconButton>
            </Box>
          ))}
        </Box>
      </Box>

      <Dialog open={!!confirmDelete} onClose={() => setConfirmDelete(null)}>
        <DialogContent>
          <Typography variant="h6">
            ¿Estás seguro de querer eliminar el grupo "{confirmDelete?.libelle}"?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(null)} sx={{ color: 'grey' }}>
            Cancelar
          </Button>
          <Button onClick={() => handleDeleteGroup(confirmDelete)} sx={{ color: 'red' }}>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Grupos;